import { FIELD_KEYS } from './../constants';
import { Request } from './../services';
import { prepareDbDoc } from './common';

export const getDefaultFields = (selectedItem, docsConfig) => {
  if (!selectedItem) return;

  const tabs = docsConfig?.[selectedItem?.type]?.Tabs
    ? docsConfig[selectedItem.type].Tabs.map(({ Name }) => Name)
    : Object.keys(FIELD_KEYS)
  ;
  const composeValue = (objField) => {
    if (typeof objField.value !== 'undefined') return objField.value;

    return ({
      Date: new Date()
    })[objField.displayController];
  };

  return tabs.reduce((defaultFieldsType, fieldType) => {
    const defaultFields = (selectedItem[fieldType] || [])
      .reduce((defaultField, objField) => ({ ...defaultField, [objField.field]: composeValue(objField) }), {});

    return { ...defaultFieldsType, [fieldType]: defaultFields }
  }, { nodeId: selectedItem.nodeId, notGrouped: selectedItem.notGrouped });
};

export const changeLockDocument = ({ selectedItem, parentId, docsConfig }) => {
  const dbDoc = prepareDbDoc({
    selectedItem,
    selectedFieldItems: {},
    parentId,
    docType: selectedItem.type,
    Tabs: docsConfig?.[selectedItem.type]?.Tabs
  });

  return Request.updateDoc(selectedItem.type, selectedItem.nodeId, { LockInfo: dbDoc?.LockInfo });
};

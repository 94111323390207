import React from 'react';
import { CardContent as MuiCardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tableBlockStyles } from './../../../assets/styles';
import { RenderTree } from './../';

const { classes, createStyles } = tableBlockStyles;
const CardContent = styled(MuiCardContent)(createStyles);

const LeftNavBar = (props) => {
  const { children, handleToggle, handleSelect, expanded, selected, items } = props;

  return (
    <>
      { children }
      <RenderTree
        expanded={expanded}
        selected={selected}
        handleToggle={handleToggle}
        handleSelect={handleSelect}
        items={items}
      />
    </>
  );
};

export default LeftNavBar;

import React, { useEffect, useState, useCallback } from 'react';
import { Typography } from '@mui/material';

import { formatDate } from './../../../utils/date'
import { DevExtremeChart } from './../';

const defaultOptions = {
  "title": "",
  "yAxisTitle": "Value",
  "argumentField": "date",
  "type": "spline",
  "barPadding": 0,
  "borderProperties": {
    "visible": false
  },
  "exportProperties": {
    "enabled": true
  },
  "tooltipProperties": {
    "enabled": true
  },
  "legendProperties": {
    "visible": true
  }
};

const prepareValueField = (date = '') => date.replace(/T|Z/g,'-');
const prepareData = (prevData, valueField) => Object.keys(prevData || {}).reduce((nextData, year) => {
  const yearData = prevData[year].map((val, index) => {
    const date = new Date(year, index, '01').toLocaleString('US', { year: 'numeric', month: 'short' });

    return {
      date,
      [valueField]: val
    }
  });

  return [...nextData, ...yearData]
}, []);

export const Logs = (props) => {
  const { label, value = [] } = props;

  if (!value.length) return (<></>);

  const [historyType] = label.split(' ');
  const currentDate = (new Date()).toISOString();

  const { data: currentData = {} } = [...value].pop() || {};
  const series = [
    ...value.map(({ Date: date }, index) => ({
      name: `Values on ${formatDate(date)}`,
      valueField: prepareValueField(date),
      // color: value.lengh - 1 === index ? '#4e8c7a' : undefined
    })),
    {
      name: `Values on ${formatDate(currentDate)}`,
      valueField: prepareValueField(currentDate),
      color: '#358370'
    }
  ];

  const dataSource = value.reduce((nextDataSource, { Date: date, prevData }) => {
    const valueField = prepareValueField(date);
    const prevDataObj = prevData || {};
    const data = prepareData(prevDataObj, valueField)
      .map(item => {
        const existsItem = nextDataSource.find(({ date }) => date === item.date) || {};

        return {
          ...existsItem,
          ...item
        }
      });
    const updatedDates = data.map(({ date }) => date);
    const filteredNextDataSource = nextDataSource.filter(({ date }) => !updatedDates.includes(date));

    return [...filteredNextDataSource, ...data];
  },  prepareData(currentData, historyType))
  .sort(({ date: dateA }, { date: dateB }) =>
    new Date(dateA).getTime() > new Date(dateB).getTime() ? 1 : -1
  );

  return (
    <>
      <Typography mt={1} className="font-weight-bold">{ label }</Typography>
      <div className='p-2'>
        <DevExtremeChart {...{...defaultOptions, series, dataSource }} />
      </div>
    </>
  )
};

export default Logs;

const STORAGE_KEY = 'pathfinder';

export const loadStateData = () => {
  try {
    const serializedState = localStorage.getItem(STORAGE_KEY);
    if (serializedState === null) {
      return {};
    }
    return JSON.parse(serializedState);
  } catch (e) {

    return {};
  }
};

export const saveStateData = (data) => {
  const currentData = { ...loadStateData(), ...data };
  try {
    const serializedData = JSON.stringify(currentData);
    localStorage.setItem(STORAGE_KEY, serializedData);
  } catch (e) {
    throw e;
  }
};

export const getDataByKey = (key) => {
  const storeData = loadStateData();

  return (storeData || {})[key];
};

export default {
  loadStateData,
  saveStateData,
  getDataByKey
}

import React from 'react';

import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];

const WysiwygTheme = createTheme({
  overrides: {
    MUIRichTextEditor: {
      root: {
      },
      container: {
        border: '1px solid gray',
      },
      editor: {
        background: '#fff !important',
        color: 'rgba(0, 0, 0, 0.87)',
        padding: "20px",
        // height: "200px",
        overflow: "auto",
      },
      toolbar: {
        borderBottom: "1px solid gray",
      },
      placeHolder: {
        background: '#fff !important',
        padding: "35px 20px 20px 20px",
        margin: 0
      },
      anchorLink: {
      }
    }
  }
});

const Wysiwyg = (props) => {
  const {
    label = '',
    value,
    access = true,
    plainText = false,
    handleChange,
    height = '400px'
  } = props;

  return (
    <div className="widget-container">
      <HtmlEditor
        className="dx-htmleditor-cst"
        height={height}
        value={value}
        onValueChange={(value) => handleChange({ target: { value } })}
      >
        <MediaResizing enabled={true} />
        <Toolbar multiline={false}>
          <Item name="undo" />
          <Item name="redo" />
          <Item name="separator" />
          <Item
            name="size"
            acceptedValues={sizeValues}
          />
          <Item
            name="font"
            acceptedValues={fontValues}
          />
          <Item name="separator" />
          <Item name="bold" />
          <Item name="italic" />
          <Item name="strike" />
          <Item name="underline" />
          <Item name="separator" />
          <Item name="alignLeft" />
          <Item name="alignCenter" />
          <Item name="alignRight" />
          <Item name="alignJustify" />
          <Item name="separator" />
          <Item name="orderedList" />
          <Item name="bulletList" />
          <Item name="separator" />
          <Item
            name="header"
            acceptedValues={headerValues}
          />
          <Item name="separator" />
          <Item name="color" />
          <Item name="background" />
          <Item name="separator" />
          <Item name="link" />
          <Item name="image" />
          <Item name="separator" />
          <Item name="clear" />
          <Item name="codeBlock" />
          <Item name="blockquote" />
          <Item name="separator" />
          <Item name="insertTable" />
          <Item name="deleteTable" />
          <Item name="insertRowAbove" />
          <Item name="insertRowBelow" />
          <Item name="deleteRow" />
          <Item name="insertColumnLeft" />
          <Item name="insertColumnRight" />
          <Item name="deleteColumn" />
        </Toolbar>
      </HtmlEditor>
    </div>
  );
};

export default Wysiwyg;

import {
  ERRORS,
  AUTH_STATUS_NEWPASSWORD,
  AUTH_STATUS_MFA,
  SECONDS_SESSION_TIMEOUT,
  SECONDS_LEFT_TO_REFRESH_TOKEN
} from './../../constants';
import { loadStateData, getDataByKey, saveStateData } from './../Storage';
import Request from './../Request';

export const getToken = () => getDataByKey('accessToken');


export default {
  getDataByKey,
  isAuthenticated: () => Boolean(getToken()),
  getToken,
  getRefreshToken: () => getDataByKey('refreshToken'),
  getOrganization: () => getDataByKey('organization'),
  getUser: () => getDataByKey('user'),
  updateUser: (user) => {
    saveStateData({ user: {...getDataByKey('user'), ...user } });
  },
  resetActivity: () => saveStateData({ activity: Date.now() }),
  resetSession: () => saveStateData({ logoutSession: Date.now() }),
  getInactiveSeconds: () => {
    const activity = getDataByKey('activity') || Date.now();

    return String(Date.now()).substr(0, 10) - String(activity).substr(0, 10);
  },
  getSessionActive: () => {
    const logoutSession = getDataByKey('logoutSession') || Date.now();

    return String(Date.now()).substr(0, 10) - String(logoutSession).substr(0, 10);
  },
  getSessionTime: () => {
    const AuthExpiration = getDataByKey('authExpiration');

    return AuthExpiration ? AuthExpiration * SECONDS_LEFT_TO_REFRESH_TOKEN : SECONDS_SESSION_TIMEOUT;
  },
  getSessionCountdown() {
    return this.getSessionTime() - SECONDS_LEFT_TO_REFRESH_TOKEN;
  },
  getTokenLifeTime() {
    return this.getSessionTime() - this.getInactiveSeconds();
  },
  relogin (resetActivity = false) {
    if (resetActivity) this.resetActivity();

    return Request.relogin()
      .catch(error => {
        console.log('relogin error: ', error);
        this.logout();
      })
      .then(({ token: accessToken }) => {
        saveStateData({ logoutSession: Date.now(), token: accessToken, accessToken });
      });
  },
  login: (data) => Request.login(data)
    .then(async (loginData) => {
      const { email } = data;
      const { token: accessToken, status } = loginData;

      if (!accessToken && ![AUTH_STATUS_NEWPASSWORD, AUTH_STATUS_MFA].includes(status)) throw new Error(ERRORS.NOT_AUTHENTICATED);

      const authData = { ...loginData, accessToken, email, activity: Date.now(), logoutSession: Date.now() };
      saveStateData(authData);

      if (status === AUTH_STATUS_NEWPASSWORD) return { ...authData, status };

      saveStateData(authData);

      if (accessToken) {
        Request.getSystemFieldsConfiguration().then(data => {
          saveStateData({ currency: data?.clientSystemFields?.Fields?.Currency });
        }).catch(err => console.log('[Auth service login]', err));
      }

      return authData;
    }),
  logout: () => {
    localStorage.clear();
    window.location.reload();
  }
};

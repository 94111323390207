import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { connect, useSelector } from 'react-redux';
import { Box, Typography, useTheme } from '@mui/material';

import { Sidebar, Header, HeaderTopMenu, Footer } from '../../layout-components';
import { Relogin } from './../../controls';
import { checkAccess } from '../../../utils/common';

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground
  } = props;
  const theme = useTheme();

  const { pageButtonsProps, pageTitle } = useSelector(state => state.ThemeOptions);

  const isModify =  useMemo(
    () =>
      checkAccess({
        path: pageButtonsProps?.selectedDoc?.path,
        methods: pageButtonsProps?.selectedDoc?.methods
      }),
    [
      pageButtonsProps?.selectedDoc?.path,
      pageButtonsProps?.selectedDoc?.methods
    ]
  );
  const isShowTopNavPanel = useMemo(
    () => (
      (pageButtonsProps?.selectedDoc && isModify) ||
      (pageButtonsProps?.childTypes || []).length ||
      (pageButtonsProps?.isShowEditBtns && isModify)
    ),
    [
      pageButtonsProps?.selectedDoc,
      isModify,
      pageButtonsProps?.childTypes,
      pageButtonsProps?.isShowEditBtns
    ]
  );

  return (
    <>
      {/*<Header />*/}
      <div
        className={clsx('app-wrapper', contentBackground, {
          'header-drawer-open': headerDrawerToggle,
          'app-sidebar-collapsed': sidebarToggle,
          'app-sidebar-mobile-open': sidebarToggleMobile,
          'app-sidebar-fixed': sidebarFixed,
          'app-header-fixed': headerFixed,
          'app-footer-fixed': footerFixed,
          'search-wrapper-open': headerSearchHover
        })}>
        <HeaderTopMenu
          pageTitle={pageTitle}
        />
        <div className="app-main">
          <Relogin />
          {/*<Sidebar />*/}
          <div
            className={clsx('app-content', { 'top-content': !isShowTopNavPanel })}
          >
            {/*<Typography variant='h5' className="font-weight-bold pl-4 ml-4 mr-4 nowrap">*/}
            {/*  {pageTitle}*/}
            {/*</Typography>*/}
            <div className="app-content--inner" style={{ padding: '0px' }}>
              <div className="app-content--inner__wrapper">{children}</div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground
});

export default connect(mapStateToProps)(LeftSidebar);

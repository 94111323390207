import React from 'react';
import { useSelector } from 'react-redux';
import {
  Table as MuiTable,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import {
  MONTH_ABBR_SHORT,
  TITLES,
  THEME_SIZES,
  GDOC_TYPE,
  SDOC_TYPE,
  IDOC_TYPE,
  BDOC_TYPE,
  UOM_FORMATS, CONTROL_DOC_CDOC, YEAR_MONTHS_COUNT, MDOC_TYPE, RDOC_TYPE
} from '../../../constants';
import {
  getTotalByGridValues,
  round,
  uomFormat,
  isObject,
  prepareDevExtremeData,
  getTotalByMethod
} from '../../../utils/common';
import { createStyles as createTableResponsiveStyles } from '../../../assets/styles/tableResponsive';
import { createStyles } from '../../pages/DocumentTree/styles';
import { Chart as ApexChart, DevExtremeChart } from './../';

const Table = styled(MuiTable, {
  shouldForwardProp: (prop) => prop !== '$columns' && prop !== '$breakpoint'
})(createTableResponsiveStyles);
const Accordion = styled(MuiAccordion)(createStyles);
const AccordionDetails = styled(MuiAccordionDetails)(createStyles);



const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
}));

const prepareChartData = (data = {}) => Object.keys(data).reduce((result, year) => [...result, ...data[year]], []);

const composeGroupData = ({ docType, selectedFieldItems, groupData }) => {
  const { getGroupData = () => groupData } = [
    {
      condition: docType === BDOC_TYPE,
      getGroupData: () => ({ [selectedFieldItems?.Fields?.bDocUOM || UOM_FORMATS.CURRENCY]: groupData })
    },
    {
      condition: docType === CONTROL_DOC_CDOC,
      getGroupData: () => ({ [selectedFieldItems?.UnitOfMeasure || UOM_FORMATS.CURRENCY]: groupData })
    }
  ].find(({ condition }) => condition) || {};

  return getGroupData();
};

const prepareGraphData = ({ gridData, uom }) => {
  const { categories, data } = Object.keys(gridData).reduce((result, year) => {
    const data = Array.isArray(gridData[year]) ? gridData[year] : [];

    return {
      categories: [
        ...result.categories,
        ...data.map((_, index) => new Date(year, index, '01').toLocaleString('US', { year: 'numeric', month: 'short'  }))
      ],
      data: [
        ...result.data,
        ...data
      ],
    }
  }, { categories: [], data: [] });

  return { categories, series: [{ name: uom, data }] }
};

const defaultDevextremeChartOptions = {
  title: '',
  yAxisTitle: '',
  argumentField: 'date',
  type: 'line',
  color: '#358370',
  barPadding: 0,
  series: [],
  dataSource: [],
  borderProperties: {
    visible: false
  },
  exportProperties: {
    enabled: true
  },
  tooltipProperties: {
    enabled: true,
  },
  legendProperties: {
    visible: false,
  }
};


const composeChartData = ({
  selectedFieldItems,
  docType,
  field,
  label,
  gridData,
  ma,
  uom
}) => {
  const {
    compose = () => {
      const { series, categories } = prepareGraphData({ gridData, uom });
      const nextData = ({
        ...defaultDevextremeChartOptions,
        yAxisTitle: uom,
        type: 'bar',
        ...prepareDevExtremeData(series, categories)
      });

      return nextData;
    }
  } = [
    {
      condition: [GDOC_TYPE, SDOC_TYPE, IDOC_TYPE].includes(docType),
      compose: () => {
        const { series, categories } = prepareGraphData({ gridData, uom });
        const nextData = ({
          ...defaultDevextremeChartOptions,
          yAxisTitle: uom,
          type: 'line',
          ...prepareDevExtremeData(series, categories)
        });

        return nextData;
      }
    },
    {
      condition: field === `${CONTROL_DOC_CDOC}NetFlows`,
      compose: () =>  {

        // const { categories, series } = prepareGraphData({ gridData, uom: label });
        // const gridValues = prepareChartData(selectedFieldItems[`${CONTROL_DOC_CDOC}GridValues`]);
        // const monthlyGrid = prepareChartData(selectedFieldItems[`${CONTROL_DOC_CDOC}MonthlyGrid`]);

        const gridValues = selectedFieldItems[`${CONTROL_DOC_CDOC}GridValues`] || {}; // cDoc
        const measuresMonthlyGrid = selectedFieldItems[`${CONTROL_DOC_CDOC}MeasuresMonthlyGrid`] || {}; // mDocs Transf
        const monthlyGrid = selectedFieldItems[`${CONTROL_DOC_CDOC}MonthlyGrid`] || {}; // rDocs

        const dates = [
          ...new Set([
            ...Object.keys(gridValues),
            ...Object.keys(measuresMonthlyGrid),
            ...Object.keys(monthlyGrid)
          ])
        ];

        const argumentField = 'date';
        const firstColumn = 'firstColumn';
        const secondColumn = 'secondColumn';

        const series = [
          {
            valueField: `${firstColumn}${CONTROL_DOC_CDOC}`,
            name: 'Grid Values',
            stack: firstColumn,
            color: '#4472c4'
          },
          {
            valueField: `${firstColumn}${MDOC_TYPE}`,
            name: 'Transfered Measures Monthly Grid',
            stack: firstColumn,
            color: '#f4b183'
          },
          {
            valueField: `${secondColumn}${RDOC_TYPE}`,
            name: 'Monthly Grid',
            stack: secondColumn,
            color: '#a9d18e'
          }
        ];
        const dataSource = dates.reduce((nextDataSource, year) => {
          const nextYearDataSource = Array(YEAR_MONTHS_COUNT)
            .fill(null)
            .map((_, index) => ({
              [argumentField]: new Date(year, index, '01').toLocaleString('US', { year: 'numeric', month: 'short' }),
              [`${firstColumn}${CONTROL_DOC_CDOC}`]: gridValues?.[year]?.[index] || 0,
              [`${firstColumn}${MDOC_TYPE}`]: measuresMonthlyGrid?.[year]?.[index] || 0,
              [`${secondColumn}${RDOC_TYPE}`]: monthlyGrid?.[year]?.[index] || 0
            }));

          return [...nextDataSource, ...nextYearDataSource];
        }, []);


        return {
          title: '',
          argumentField,
          yAxisTitle: uom,
          series,
          dataSource,
          // exportProperties: {
          //   enabled: false
          // }
        };
      }
    }
  ].find(({ condition }) => condition) || {};

  return compose();
};

const RenderGridData = props => {
  const { field, isModal, label, uom, ma, docType, gridData, selectedFieldItems, kDocAggregationMethod } = props;
  const transientProps = {
    $columns: [TITLES.YEAR, ...MONTH_ABBR_SHORT, TITLES.TOTAL],
    $breakpoint: isModal ? THEME_SIZES.md : THEME_SIZES.lg
  };

  const Chart = {
    [`${CONTROL_DOC_CDOC}NetFlows`]: DevExtremeChart
  }[field] || ApexChart;

  return (
    <>
      <Table className={`grid-group table table-hover text-nowrap mt-1`} {...transientProps}>
        <thead>
          <tr>
            <th className="text-center">{ TITLES.YEAR }</th>
            {
              MONTH_ABBR_SHORT.map((monthName, subIndex) =>
                (<th key={`${subIndex}-th`} className="text-center">{monthName}</th>))
            }
            <th className="text-center">{ TITLES.TOTAL }</th>
          </tr>
        </thead>
        {
          Object.keys(gridData).map(
            (year, index) => {
              const data = Array.isArray(gridData[year])
                ? gridData[year]
                : []
              ;
              return (
                <React.Fragment key={`grid-details-${index}`}>
                  <tbody>
                  <tr>
                    <td className="text-center">
                      {year}
                    </td>
                    {
                      data.map((val, subIndex) => (
                        <td key={`${index}-${subIndex}-td`} className="text-center">
                          { uomFormat(uom, val) }
                        </td>
                      ))
                    }
                    <td className="text-center">
                      {uomFormat(uom, getTotalByMethod(data, kDocAggregationMethod))}
                    </td>
                  </tr>
                  </tbody>
                </React.Fragment>
              );
            }
          )
        }
      </Table>
      <div className="font-weight-bold font-size-sm text-sm-right pr-3">
        Total: { uomFormat(uom, getTotalByGridValues(gridData, kDocAggregationMethod)) }
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon/>}
          aria-label="Expand"
        >
          <div className="font-weight-bold font-size-md text-lg-left w-100">
            Chart {uom}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <DevExtremeChart
            {...composeChartData({ selectedFieldItems, docType, label, field, gridData, ma, uom })}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const GridGroup = (props) => {
  const { docType, label, ma, groupData = {}, isModal = false, field } = props;
  const pageProps = useSelector(state => state.ThemeOptions.pageProps);
  const objClientLabel = (pageProps?.Scenarios || []).find(objVal => field.includes(Object.entries(objVal).shift()?.shift())) || {};
  const clientLabel = Object.entries(objClientLabel).shift()?.pop() || label;

  return (
    <div className="m-2">
      <div className="card-header-title font-weight-bold font-size-lg">
        { ma ? `${clientLabel} - ${ma}` : clientLabel }
      </div>
      {
        Object.keys(groupData).map((uom, index) => (
          <React.Fragment key={uom}>
            <div className="font-weight-bold font-size-sm text-sm-left mt-2">{uom}</div>
            <RenderGridData
              { ...props }
              isModal={isModal}
              label={clientLabel}
              ma={ma}
              uom={uom}
              docType={docType}
              gridData={groupData[uom]}
            />
          </React.Fragment>
        ))
      }
    </div>
  );
};

export default (props) => {
  const { groupData = {}, docType, selectedFieldItems, kDocAggregationMethod } = props;

  const nextGroupData = composeGroupData({ docType, selectedFieldItems, groupData });
  const [L1] = Object.keys(nextGroupData);
  const [L2] = Object.keys(nextGroupData[L1] || {});
  const [L3] = Object.keys((nextGroupData[L1]?.[L2] || {}));

  const {
    renderGroupData = () => (<></>)
  } = [
    {
      condition: Array.isArray(nextGroupData?.[L1]?.[L2]?.[L3]),
      renderGroupData: () => (
        <>
          {
            Object.keys(nextGroupData)
              .map(ma => (
                <GridGroup
                  { ...props }
                  key={ma}
                  ma={ma}
                  groupData={nextGroupData[ma]}
                />
              ))
          }
        </>
      )
    },
    {
      condition: Array.isArray(nextGroupData?.[L1]?.[L2]),
      renderGroupData: () => (
        <GridGroup
          { ...props }
          groupData={nextGroupData}
        />
      )
    },
    {
      condition: Array.isArray(nextGroupData?.[L1]),
      renderGroupData: () => (
        <RenderGridData
          { ...props }
          gridData={nextGroupData}
          kDocAggregationMethod={kDocAggregationMethod}
        />
      )
    }
  ].find(({ condition }) => condition) || {};

  return renderGroupData();
}

import React, { useState, useCallback, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Grid,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  TextField,
  IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AUTH_STATUS_NEWPASSWORD, AUTH_STATUS_MFA } from './../../../constants';
import { AuthService } from './../../../services';

export default () => {
  const history = useHistory();

  const [checked, setChecked] = useState(true);
  const [redirect, setRedirect] = useState('');
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = AuthService.isAuthenticated();

  const handleSelectChange = useCallback((type) => ({
    [type]: (value) => {
      setFields(prevFields => ({ ...prevFields, [type]: value.target.value }));
    }
  }[type]), []);

  const handleChange = useCallback((event) => {
    setChecked(event.target.checked);
  }, []);

  const handleSubmit = useCallback(() => {
    setErrors([]);
    setLoading(true);

    AuthService.login(fields)
      .then(authData => {
        console.log(':authData ', authData)


        if (authData.status === AUTH_STATUS_NEWPASSWORD) {
          history.push({
            pathname: '/reset-password',
            authData
          });

          return;
        }

        if (authData.status === AUTH_STATUS_MFA) {
          history.push({
            pathname: '/verification-code',
            authData: { ...authData, fields },
          });

          return;
        }

        if (authData.accessToken) {
          setRedirect('/');
        };

        const message = {
          redirect: window.self.location.href,
          date: Date.now(),
        };
        console.log('message: ', message)
        window.parent.postMessage(message, '*');
      })
      .catch((error) => {
        setErrors(error.errors || ['The User email and/or password combination are incorrect.']);
      })
      .finally(() => setLoading(false));
  },[JSON.stringify(fields)]);

  useEffect(() => {
    if (isAuthenticated) setRedirect('/');
  }, [isAuthenticated]);

  if (redirect) return (<Redirect to={redirect}/>);

  return <>
    <div className="app-wrapper min-vh-100 bg-white">
      <div className="hero-wrapper w-100 bg-composed-wrapper bg-white-10 min-vh-100">
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div className="bg-composed-wrapper--content p-3 p-md-5">
            <Container>

            </Container>
          </div>
        </div>
        <div className="hero-footer w-100 pb-4">
          <Container>
            <div className="py-3 d-block d-lg-flex font-size-xs justify-content-between">
              <div className="text-center d-block mb-3 mb-md-0">
                Copyright &copy; 2023
              </div>
              <List
                component="div"
                className="nav-transparent text-nowrap d-flex justify-content-center">
                <a
                  className="mr-2"
                  href="/privacy-policy"
                >
                  Privacy Policy
                </a>

                <a
                  button
                  href="/terms"
                >
                  Terms of Service
                </a>
              </List>
            </div>
          </Container>
        </div>
      </div>
    </div>
  </>;
}

import { useState, useEffect } from 'react';
import {
  FIELD_KEYS,
  STATUS_CLOSED,
  STATUS_OPEN,
  COLOR_STATUS_APPROACHING,
  COLOR_STATUS_PAST_DEAD_LINE, TYPE_APPROVVERS, STATUS_APPROVED, STATUS_PENDING, TYPE_SPONSORS, TYPE_LEADS
} from './../constants';
import { getFieldParams } from "../utils/common";
import { formatDate } from "../utils/date";

const useDocChipsProgress = (doc, docsConfig) => {
  const [progress, setProgress] = useState([]);

  useEffect(() => {
    if (!doc?.[FIELD_KEYS.Overview]) {
      setProgress([]);

      return;
    }

    const {
      Items: gates = [],
    } = getFieldParams(
      docsConfig[doc.type],
      FIELD_KEYS.Overview,
      `${doc.type}OpenGateRequests`
    );

    const gateRequests = doc[FIELD_KEYS.Overview]
      .find(({ field }) => field === `${doc.type}OpenGateRequests`)?.value || [];
    const firstGate = [...gateRequests].sort(
      ({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
        (new Date(createdAtA)).getTime() - (new Date(createdAtB)).getTime()
    ).shift();
    // console.log('[firstGate] L34 firstGate', firstGate);

    const schedule = doc[FIELD_KEYS.Approvers]
      .find(({ field }) => field === `${doc.type}DueDatesLimits`)?.value || {};
    const reviewers = doc[FIELD_KEYS.Approvers]
      .find(({ field }) => field === `${doc.type}Reviewers`)?.value || [];
    // console.log('[schedule] L31 schedule ', schedule);
    // console.log('[reviewers] L31 reviewers ', reviewers);
    const isPastDeadOrApproachingLine = (openGate, past = true) => {
      // const

      const totalSum = Object.keys(schedule).reduce((typeSum, typeApprover) => {
        const sum = Object.keys(schedule[typeApprover]).reduce(
          (daysSum, gate) => {
            return gate < openGate.gate ? (daysSum + schedule[typeApprover][gate]) : daysSum;
          }, 0);

          return (typeSum + sum);
      }, 0);

      // console.log('[isPastDeadLine] L55 totalSum ', totalSum);
      if (!doc[FIELD_KEYS.Approvers]
        .find(({ field }) => field === `${doc.type}DueDatesLimits`)?.value
      )
        return false;

      const isApproversApproved = !reviewers.some(({ gate, type, status }) =>
        gate === openGate.gate && type === TYPE_APPROVVERS && status === STATUS_PENDING)
      const isSponsorsApproved = !reviewers.some(({ gate, type, status }) =>
        gate === openGate.gate && type === TYPE_SPONSORS && status === STATUS_PENDING)
      const isLeadsApproved = reviewers.some(({ gate, type, status }) =>
        gate === openGate.gate && type === TYPE_LEADS && status === STATUS_PENDING)

      const approversDays = totalSum + (schedule?.[TYPE_APPROVVERS]?.[openGate.gate] || 0)
      const sponsorsDays = totalSum + (schedule?.[TYPE_APPROVVERS]?.[openGate.gate] || 0)
      const leadsDays = totalSum + (schedule?.[TYPE_APPROVVERS]?.[openGate.gate] || 0)

      const {
        composeResult = () => false
      } = [
        {
          condition: !isApproversApproved && past,
          composeResult: () => {
            const startDate = new Date(firstGate.createdAt);
            startDate.setDate(startDate.getDate() + approversDays);

            return Date.now() > startDate.getTime();
          }
        },
        {
          condition: !isApproversApproved && !past,
          composeResult: () => {
            const startDate = new Date(firstGate.createdAt);
            startDate.setDate(startDate.getDate() + approversDays);
            // console.log('[composeResult] L87 openGate ', openGate);
            // console.log('[composeResult] L87 startDate ', startDate);
            // console.log('[composeResult] L87 days ', Math.ceil((new Date() - startDate)/ (1000 * 60 * 60 * 24)));


            return Date.now() <= startDate.getTime() &&
              Math.ceil((startDate.getTime() - Date.now())/ (1000 * 60 * 60 * 24)) < 3;
          }
        },
        {
          condition: !isSponsorsApproved && past,
          composeResult: () => {
            const startDate = new Date(firstGate.createdAt);
            startDate.setDate(startDate.getDate() + sponsorsDays);

            return Date.now() > startDate.getTime();
          }
        },
        {
          condition: !isSponsorsApproved && !past,
          composeResult: () => {
            const startDate = new Date(firstGate.createdAt);
            startDate.setDate(startDate.getDate() + sponsorsDays);

            return Date.now() <= startDate.getTime() &&
              Math.ceil((startDate.getTime() - Date.now())/ (1000 * 60 * 60 * 24)) < 3;
          }
        },
        {
          condition: !isLeadsApproved && past,
          composeResult: () => {
            const startDate = new Date(firstGate.createdAt);
            startDate.setDate(startDate.getDate() + leadsDays);

            return Date.now() > startDate.getTime();
          }
        },
        {
          condition: !isLeadsApproved && !past,
          composeResult: () => {
            const startDate = new Date(firstGate.createdAt);
            startDate.setDate(startDate.getDate() + leadsDays);

            return Date.now() <= startDate.getTime() &&
              Math.ceil((startDate.getTime() - Date.now())/ (1000 * 60 * 60 * 24)) < 3;
          }
        }
      ].find(({ condition }) => condition) || {}

      return composeResult();
    }

    console.log('[gates] L142 gates ', gates);
    const gatesStatuses = gates.map(({ Value, Label, [`${doc.type}TootlTip`]: tooltip }, i) => {
      const gateRequest = gateRequests.find(({ gate }) => gate === Value);
      // const

      const {
        status = gateRequest?.status
      } = [
        {
          condition: !i,
          status: STATUS_CLOSED
        },
        {
          condition: !!i && gateRequest?.status === STATUS_OPEN && isPastDeadOrApproachingLine(gateRequest),
          status: COLOR_STATUS_PAST_DEAD_LINE
        },
        {
          condition: !!i && gateRequest?.status === STATUS_OPEN && isPastDeadOrApproachingLine(gateRequest, false),
          status: COLOR_STATUS_APPROACHING
        }
      ].find(({ condition }) => condition) || {};

      return { label: Label, gate: Value, status, tooltip }
    })
    setProgress(gatesStatuses);
  }, [doc, docsConfig])

  return progress;
};
export default useDocChipsProgress;

import { DEFAULT_FORMAT_DATE } from './../constants';

export const getFirstDayOfMonth = (date = new Date()) => (new Date(date.getFullYear(), date.getMonth(), '01'))

export const formatDate = (
  date,
  options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric', second: 'numeric' },
  locales = 'en-US'
) => new Date(date).toLocaleString(locales, options);

const parseLastTwoDigits = n => (n / 100).toFixed(2).split('.')[1]
export const dbFormatDate = (date) => {
  const fullyear = date.getFullYear();
  const [month, day, hours, minutes, seconds] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ].map(parseLastTwoDigits);
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return `${fullyear}-${month}-${day}T${formattedTime}`;
};

export const getDate = (date, DateFormat = DEFAULT_FORMAT_DATE) => {
  const { getFormat = () => formatDate(date) } = [
    {
      condition: DateFormat.split('/').length === 2,
      getFormat: () => getFirstDayOfMonth(date)
    }
  ].find(({ condition }) => condition) || {};

  return getFormat();
};

export const getDifferenceDays = (inputDate1, inputDate2) => {
  const date1 = new Date(inputDate1);
  const date2 = new Date(inputDate2);
  const diffTime = date1 - date2;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog as MuiDialog, Grid, IconButton, styled } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { dialogStyles } from './../../../assets/styles';
import { TITLES, SECONDS_LEFT_TO_REFRESH_TOKEN } from './../../../constants';
import { AuthService } from './../../../services';
import { createDispatches } from './../../../reducers/functions';

const { createStyles, classes } = dialogStyles;
const Dialog = styled(MuiDialog)(createStyles);

const Relogin = () => {
  const [ seconds, setSeconds ] = useState();
  const { setRefreshModal } = createDispatches(useDispatch());
  const isShowRefreshModal = useSelector(state => state.ThemeOptions.isShowRefreshModal);

  const onClose = useCallback(() => {
    setRefreshModal(false);
  }, []);
  const logout = useCallback(() => {
    AuthService.logout();
  }, []);
  const relogin = useCallback(() => {
    AuthService.relogin(true)
      .catch(console.log)
      .then(() => {
        setRefreshModal(false);
      });
  }, []);

  useEffect(() => {
    if (!isShowRefreshModal) return;

    const intervalId = setInterval(() => {
      const leftSeconds = AuthService.getTokenLifeTime();
      if (seconds !== leftSeconds && leftSeconds >= 0 && leftSeconds <= SECONDS_LEFT_TO_REFRESH_TOKEN) setSeconds(leftSeconds);
    }, 200);

    return () => clearInterval(intervalId);
  }, [isShowRefreshModal, seconds]);

  return (
    <Dialog
      open={isShowRefreshModal}
      // onClose={onClose}
      classes={{ paper: 'shadow-lg' }}
    >
      {/*<div className="position-relative mb-2">*/}
      {/*  <IconButton*/}
      {/*    aria-label="close"*/}
      {/*    className={`${classes.closeButton}`}*/}
      {/*    onClick={onClose}*/}
      {/*    size="large">*/}
      {/*    <CloseIcon />*/}
      {/*  </IconButton>*/}
      {/*</div>*/}
      <div className="text-center p-4">
        <h1 className="font-weight-bold mt-4">{ seconds }</h1>
        <p className="mb-0 mt-2 font-size-lg">{ TITLES.RELOGIN_MESSAGE }</p>

        <div className="pt-4">
          <Button
            variant="outlined"
            className="mx-1"
            onClick={logout}
          >
            <span className="btn-wrapper--label">{ TITLES.NO }</span>
          </Button>
          <Button
            onClick={relogin}
            variant='contained'
            className="mx-1"
          >
            <span className="btn-wrapper--label">{ TITLES.CONTINUE }</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Relogin;

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useHistory } from 'react-router';

import 'devextreme/dist/css/dx.light.css';
import 'devextreme/data/odata/store';
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Selection,
  LoadPanel,
  Paging,
  Pager
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';

const getOrderDay = (rowData) => {
  return (new Date(rowData.OrderDate)).getDay();
};

const DevExtrimDataGrid = props => {
  const {
    loading = true,
    keyExpr = 'id',
    getColumns = () => [],
    dataSource,
    showFilterRow = true,
    showPanelFilter = true,
    showHeaderFilter = false,
    showBorders = true,
    allowColumnResizing = true,
    allowColumnReordering = true,
    hoverStateEnabled = true,
    showRowLines = false,
    showColumnLines = true,
    rowAlternationEnabled = true,
    pageSize = 25,
    onPageSizeChange = () => {},
    pager = { showPageSizeSelector: false, allowedPageSizes: 'auto' },
    onSelection = row => {
      console.log('row: ', row)
    },
    customStoreOptions
  } = props;

  const history = useHistory();
  const dataGrid = useRef(null);
  const columns = getColumns(dataGrid.current, { history });
  const onSelectionRow = useCallback((e) => {
    e.component.byKey(e.currentSelectedRowKeys[0]).done(row => {
      onSelection(row);
    });
  });
  const store = customStoreOptions && (new CustomStore(customStoreOptions));

  return (
    <div>
      <DataGrid
        id="gridContainer"
        noDataText={loading ? '' : 'No Data'}
        ref={dataGrid}
        dataSource={store || dataSource}
        remoteOperations={!!store}
        keyExpr={keyExpr}
        showBorders={showBorders}
        showRowLines={showRowLines}
        showColumnLines={showColumnLines}
        allowColumnReordering={allowColumnReordering}
        allowColumnResizing={allowColumnResizing}
        onSelectionChanged={onSelectionRow}
        hoverStateEnabled={hoverStateEnabled}
        rowAlternationEnabled={rowAlternationEnabled}
        // paging={{ pageSize }}
        // pager={pager}
      >
        <Paging defaultPageSize={pageSize} onPageSizeChange={onPageSizeChange} />
        <Pager
          showPageSizeSelector={pager.showPageSizeSelector}
          allowedPageSizes={pager.allowedPageSizes}
        />
        <LoadPanel
          position={{ of: '.dx-datagrid-rowsview' }}
          shading={false}
          showPane={false}
          visible={loading}
          // enabled={loading}
        />
        <FilterRow
          visible={showFilterRow}
          // applyFilter={this.state.currentFilter}
        />
        <HeaderFilter visible={showHeaderFilter}/>
        <SearchPanel
          visible={showPanelFilter}
          width={240}
          placeholder="Search..."
        />
        {/*<Selection mode="single" />*/}
        {
          (store || dataSource) && columns.map(({ filterParams, ...columnsParams }) => (
            <Column key={columnsParams.dataField || columnsParams.caption} { ...columnsParams } >
              {
                filterParams && (
                  <HeaderFilter { ...filterParams } />
                )
              }
            </Column>
          ))
        }
      </DataGrid>
    </div>
  );
};

export default DevExtrimDataGrid;

import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { getFieldParams } from './../../../utils/common';

export const Help = (props) => {
  const {
    item,
    fieldsType,
    docsConfig,
    docType
  } = props;
  const { TextHeader, TextHelp } = useMemo(
    () => getFieldParams(docsConfig?.[docType], fieldsType, item?.field),
    [docsConfig, docType, fieldsType, item?.field]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="h5" component="div" gutterBottom>
        { TextHeader }
      </Typography>
      <Typography variant="body1" gutterBottom dangerouslySetInnerHTML={{ __html: TextHelp }} />
      <div className="divider my-3" />
    </Box>
  )
};

export default Help;

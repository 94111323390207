import React from 'react';
import Chart from 'react-apexcharts';
import { styled } from '@mui/material/styles';
import { uomFormat } from './../../../utils/common';
import { classes, createStyles } from './styles';

const Root = styled('div')(createStyles);

const getDefaultOptions = ({ label, ma, uom  }) => ({
  height: 300,
  tooltip: {
    enabled: true,
    x: {
      format: 'MMM \'yy',
    }
  },
  chart: {
    type: 'bar',
    toolbar: {
      show: true,
      export: {
        csv: {
          filename: ma ? `${label} - ${ma}` : label,
          headerCategory: 'Date',
        },
        svg: {
          filename: ma ? `${label} - ${ma}` : label,
        },
        png: {
          filename: ma ? `${label} - ${ma}` : label,
        }
      }
    }
  },
  plotOptions: {
    bar: {
      colors: {
        ranges: [
          {
            from: -100000000,
            to: 0,
            color: '#f83245'
          },
          {
            from: 0,
            to: 100000000,
            color: '#4191ff'
          }
        ]
      }
    }
  },
  dataLabels: {
    enabled: false,
  },
  yaxis: {
    title: {
      text: "Hours",
      style: {
        fontSize: '20px',
        color: '#3b3e66'
      }
    },
    labels: {
      formatter: value => uomFormat(uom, value),
      style: {
        fontSize: '16px',
        color: '#3b3e66'
      }
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: true,
      rotate: -90,
      style: {
        fontSize: '16px',
        color: '#3b3e66'
      }
    }
  }
});

export default (props = {}) => {
  const { label, ma, uom } = props;
  const defaultOptions = getDefaultOptions({ label, ma, uom });
  const {
    height = defaultOptions.height,
    tooltip = defaultOptions.tooltip,
    chart = {},
    plotOptions = defaultOptions.plotOptions,
    dataLabels = defaultOptions.dataLabels,
    yaxis = defaultOptions.yaxis,
    title = {},
    xaxis = defaultOptions.xaxis,
    toolbar = {},
    categories = [],
    labels = [],
    series = [
      { data: [] }
    ],
    colors,
    ...chartProps
  } = props;

  const options = {
    tooltip,
    chart: {
      ...defaultOptions.chart,
      ...chart,
      toolbar: {
        ...defaultOptions.chart.toolbar,
        ...toolbar
      }
    },
    colors,
    labels,
    plotOptions,
    dataLabels,
    yaxis: {
      ...defaultOptions.yaxis,
      ...yaxis,
      title: {
        ...yaxis.title,
        ...title
      }
    },
    xaxis: {
      categories,
      ...xaxis
    },
    ...chartProps
  };
  console.log('options: ', options)


  return (
    <Root className={classes.root}>
      <Chart
        height={height}
        options={options}
        series={series}
        type={chart.type}
      />
    </Root>
  );
}

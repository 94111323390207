import React, { useCallback, useRef, useState } from 'react';
import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, useTheme } from '@mui/material';
import { Dehaze as DehazeIcon } from '@mui/icons-material';

const Menu = (props) => {
  const { classes = 'w-100', styles = {}, title, menuItems = [], Icon = DehazeIcon  } = props;
  const theme = useTheme();

  const [openActions, setOpenActions] = useState(false);
  const anchorRef = useRef(null);

  const handleToggleActions = useCallback(() => {
    setOpenActions(!openActions);
  }, [openActions]);


  return (
    <div className={classes} style={{
      // background: theme.palette.headerBlockBackground,
      position: 'relative', top: '-4px', height: '48px',
      ...styles
    }}
    >
      {
        title &&
          <span className="float-left font-weight-bold font-size-lg pl-5 pt-2">
            { title }
          </span>
      }
      <span className="text-right">
        <IconButton
          ref={anchorRef}
          aria-controls={openActions ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggleActions}
        >
          <Icon style={{ opacity: Number(!!menuItems.length) }}/>
        </IconButton>
        <Popper open={openActions} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement={'bottom-end'} style={{ zIndex: 10}} modifiers={[{ name: 'offset', options: { offset: -10 } }]}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => { setOpenActions(false) }}>
                  <MenuList autoFocusItem={openActions} id="menu-list-grow" >
                    {
                      menuItems.map(({ onClick, title }) => (
                        <MenuItem
                          key={title}
                          onClick={onClick}
                        >
                          {title}
                        </MenuItem>
                      ))
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </span>
    </div>
  );
};

export default Menu

import React, { useCallback, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { Request } from './../../../services';
import { DISPLAY_CONTROLLERS, PASSWORD_TYPES, TITLES } from './../../../constants';
import { dialogStyles } from './../../../assets/styles';
import { BorderLinearProgress, RenderField } from './../';
import { validatePassword } from '../../../utils/common';

const { classes: dialogClasses, createStyles: createDialogStyles } = dialogStyles;
const Dialog = styled(MuiDialog)(createDialogStyles);

const PasswordChange = props => {
  const {
    className,
    item,
    selectedItem,
    selectedFieldItems,
    handleChange,
    handleFieldsCancel = () => {},
    handleFieldsSave = () => {},
    value
  } = props;
  const location = useLocation();

  const [resettingPass, setResetting] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const isError = !value?.[PASSWORD_TYPES.password] ||
    value?.[PASSWORD_TYPES.confirdPassword] !== value?.[PASSWORD_TYPES.password] ||
    !validatePassword(value?.[PASSWORD_TYPES.password]);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleRessitingPass = useCallback(() => {
    setResetting(true);

    Request.resetPassword(selectedFieldItems.UserId)
      .catch(err => console.log('[resetPassword] err', err))
      .finally(() => setResetting(false));
  }, [selectedFieldItems?.UserId]);

  if (selectedItem?.isNew) return (<></>);

  const {
    render = () => (
      <>
        <BorderLinearProgress loading={resettingPass} height={40} width={40} />
        {
          !resettingPass && (
            <Button className="text-primary mr-2 b-1" onClick={handleRessitingPass}>
              { TITLES.RESSET_PASSWORD }
            </Button>
          )
        }
      </>
    )
  } = [
    {
      condition: location.pathname === '/profile',
      render: () => (
        <div className={className}>
          <Button className="text-primary mr-2 b-1" onClick={toggleModal}>
            { TITLES.CHANGE_PASSWORD }
          </Button>
          <Dialog
            key="grid-data-dialog"
            scroll="body"
            // fullWidth={true}
            // maxWidth={false}
            open={openModal}
            onClose={toggleModal}
            classes={{
              paper: 'modal-content border-0 bg-white rounded-lg p-3 p-xl-0'
            }}
          >
            <DialogTitle className={dialogClasses.root}>
              <Typography id="form-dialog-title" className="text-lg-center">
                { TITLES.CHANGE_PASSWORD }
              </Typography>
              <IconButton
                aria-label="close"
                className={dialogClasses.closeButton}
                onClick={toggleModal}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <div className={'mt-4'}>
                <RenderField
                  item={{
                    ...item,
                    displayController: DISPLAY_CONTROLLERS.Password,
                  }}
                  selectedFieldItems={selectedFieldItems}
                  userHandleChange={handleChange}
                />
              </div>
            </DialogContent>
            <DialogActions className="p-4 w-100">
              <Button
                variant='outlined'
                onClick={() => {
                  handleFieldsCancel();
                  toggleModal();
                }}
              >
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button
                variant='contained'
                disabled={isError}
                onClick={() => {
                  handleFieldsSave();
                  toggleModal();
                }}
                className=" m-2"
              >
                <span className="btn-wrapper--label">Save</span>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )
    }
  ].find(({ condition }) => condition) || {};

  return render();
};

export default PasswordChange;

import { useEffect, useRef } from 'react';

const useDebounce = (callback, delay = 800) => {
  const latestCallback = useRef();
  const latestTimeout = useRef();

  useEffect(() => {
    latestCallback.current = callback;
  }, [callback]);

  return (...args) => {
    if (latestTimeout.current) {
      clearTimeout(latestTimeout.current);
    }

    latestTimeout.current = setTimeout(() => latestCallback.current(...args), delay);
  };
};

export default useDebounce;

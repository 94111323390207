import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PercentTwoTone } from '@mui/icons-material';
import { Grid, InputAdornment } from '@mui/material';

import { GridDetails, RenderField } from './../';
import { DISPLAY_CONTROLLERS } from '../../../constants';

const GridScenario = (props = {}) => {
  const {
    setSelectedFieldItems,
    selectedFieldItems,
    handleChange,
    handleGridChange,
    fieldsType,
    docType,
    item,
    gridValues,
    filteredAcces,
    access,
  } = props;
  const isPositive = item.field.includes('ScPos');
  const percentField = isPositive ? `${docType}PercentScPos` : `${docType}PercentScNgv`;

  const pageProps = useSelector(state => state.ThemeOptions.pageProps);
  const [percent, setPercent] = useState(selectedFieldItems?.notGrouped?.[percentField]);

  const objClientLabel = (pageProps?.Scenarios || []).find(objVal => item.field.includes(Object.entries(objVal).shift()?.shift())) || {};
  const label = Object.entries(objClientLabel).shift()?.pop() || item.label

  console.log('selectedFieldItems: ', selectedFieldItems);

  useEffect(() => {
    if (!percent) return;

    const xDocGridValues = selectedFieldItems?.[fieldsType]?.[`${docType}GridValues`] || {};
    const nextGridValues = Object.keys(xDocGridValues).reduce((output, year) => {
      const nextVals = xDocGridValues[year].map(
        val => Number((val/100*percent).toFixed(2))
      );

      return { ...output, [year]:  nextVals };
    }, {});

    setSelectedFieldItems({
      ...selectedFieldItems,
      [fieldsType]: {
        ...selectedFieldItems[fieldsType],
        [item.field]: nextGridValues,
      },
      notGrouped: {
        ...selectedFieldItems.notGrouped,
        [percentField]: percent
      }
    });
  }, [percent]);

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item>
          <div className="w-210px font-weight-bold font-size-lg ml-3 mb-1 mr-2 white-space-nowrap">
            { label }
          </div>
        </Grid>
        <Grid item>
          <RenderField
            item={{
              displayController: DISPLAY_CONTROLLERS.NumericBox,
              label: '',
              field: 'percent'
            }}
            styles={{
              width: '100px'
            }}
            InputProps={{
              min: 0,
              endAdornment: (
                <InputAdornment position="end" >
                  <PercentTwoTone />
                </InputAdornment>
              )
            }}
            selectedFieldItems={{ percent }}
            userHandleChange={e => setPercent(e.target.value)}
            access={access}
          />
        </Grid>
      </Grid>
      {
        percent && (
          <GridDetails
            selectedFieldItems={selectedFieldItems}
            handleChange={handleChange}
            handleGridChange={handleGridChange}
            fieldsType={fieldsType}
            docType={docType}
            fieldName={item.field}
            gridValues={gridValues}
            editable={filteredAcces}
            disableTitle={true}
          />
        )
      }
    </>
  )
};

export default GridScenario;

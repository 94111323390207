const validateInputRE = /^(\d*(?:[,.](?=\d|$)\d*)*)?(?:(?<=[0-9]|^)([kKmMbB]))?$/;

const prepareCurrency = value => value.replace(/\$\s?|(,*)/g, '');

const currencyFormatter = value => `${String(value)
  .replace(/(\d*)(\.\d*)?([kKmMbB])?$/gm, (_, p1 = '', p2 = '', p3 = '') =>
    `${p1.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}${p2}${p3}`
  )}`;

export {
  validateInputRE,
  prepareCurrency,
  currencyFormatter
};

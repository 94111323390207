import 'devexpress-gantt/dist/dx-gantt.min.css';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from "@mui/material";

import { DevExtremeChart } from './../';

import {
  COMPONENT_TYPE_GANTT,
  TDOC_TYPE
} from './../../../constants';
import {
  getParentType,
  findItemsByDocType,
  prepareDbDoc,
  prepareTree,
  findItem
} from './../../../utils/common';

const defaultDevextremeChartOptions = {
  title: 'GantChart',
  height: 500,
  componentType: COMPONENT_TYPE_GANTT,
  barPadding: 2,
  borderProperties: {
    visible: false
  },
  exportProperties: {
    enabled: true
  },
  dependencies:[],
  managementAreas:[],
  resourceAssignments:[]
};

const Gant = (props) => {
  const { selectedItem, docsConfig, docs } = props;
  const theme = useTheme();

  const mapFields = useCallback(({ doc, docType, tDocItems, docsConfig }) => {
    const tDocs = tDocItems
      .map(item =>
        prepareDbDoc({
          selectedItem: item,
          selectedFieldItems: {},
          parentId: undefined,
          docType: item.type,
          Tabs: docsConfig?.[item.type]?.Tabs
        })
      )
      .sort(
        ({ [`${TDOC_TYPE}StartDate`]: StartDateA }, { [`${TDOC_TYPE}StartDate`]: StartDateB }) =>
          new Date(StartDateA).getTime() > new Date(StartDateB).getTime() ? 1 : -1
      );

    const compledCount = tDocs.filter(({ [`${TDOC_TYPE}Completed`]: completed }) => completed).length;
    const progress = Math.round(compledCount * 100 / tDocs.length);
    const startDate = new Date([...tDocs].shift()[`${TDOC_TYPE}StartDate`]);
    const endDate = new Date([...tDocs].pop()[`${TDOC_TYPE}EndDate`]);

    return {
      id: doc[`${docType}Id`],
      title: `${doc[`${docType}Name`]}`,
      start: !isNaN(startDate.getTime()) ? startDate : undefined,
      end: !isNaN(endDate.getTime()) ? endDate : undefined,
      parentId: doc[`${docType}Id`] !== selectedItem.nodeId ? doc[`${getParentType(docType)}Id`] : undefined,
      progress,
      color: theme.palette.primary.main,
    }
  }, [theme]);

  const prepareDocsForGant = useCallback((items, docsConfig) => {
    const gantDocs = [];

    const populate = (items) => {
      for (let i = 0; i < items.length; i++) {
        const tdocs = findItemsByDocType([items[i]], TDOC_TYPE);
        if (!!tdocs.length) {
          const dbDoc = prepareDbDoc({
            selectedItem: items[i],
            selectedFieldItems: {},
            parentId: items[i]?.parent?.nodeId,
            docType: items[i].type,
            Tabs: docsConfig?.[items[i].type]?.Tabs
          });
          const gantDoc = mapFields({ doc: dbDoc, docType: items[i].type, tDocItems: tdocs, docsConfig });

          gantDocs.push(gantDoc);
        }

        if (items[i].children) populate(items[i].children);
      }
    };
    populate(items);

    return gantDocs;
  }, []);

  if (!selectedItem) return (<></>);

  const baseTree = prepareTree({ docsConfig, docs });
  const baseSelectedItem = findItem(baseTree, selectedItem.nodeId);
  const tree = (baseSelectedItem ? [baseSelectedItem] : [])
    .filter(({ children = [] }) => !!findItemsByDocType(children, TDOC_TYPE).length);

  const sortStartDate = ({ start: startA = '' }, { start: startB = '' }) =>
    Number(startA) > Number(startB) ? 1 : -1;
  const gantDocs = prepareDocsForGant(tree, docsConfig).sort(sortStartDate);

  const alltDocs = findItemsByDocType(tree, TDOC_TYPE)
    .map(item =>
      prepareDbDoc({
        selectedItem: item,
        selectedFieldItems: {},
        parentId: undefined,
        docType: item.type,
        Tabs: docsConfig?.[item.type]?.Tabs
      })
    );

  const { managementAreas, managementAreasAssignments } = alltDocs.reduce((result, doc) => {
    if (!doc.ManagementArea) return result;

    return {
      managementAreas: [...result.managementAreas, { id: doc[`${TDOC_TYPE}Id`], text: doc.ManagementArea, }],
      managementAreasAssignments: [
        ...result.managementAreasAssignments, { id: doc[`${TDOC_TYPE}Id`], taskId: doc[`${TDOC_TYPE}Id`], resourceId: doc[`${TDOC_TYPE}Id`] }
      ]
    }
  }, { managementAreas: [], managementAreasAssignments: [] });

  return (
    <>
      <div className='p-2'>
        <DevExtremeChart
          {
            ...{
              ...defaultDevextremeChartOptions,
              docs: gantDocs,
              managementAreasAssignments,
              managementAreas
            }
          }
        />
      </div>

    </>
  )
};

export default Gant;

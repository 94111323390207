import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

const LinearProgress = styled(CircularProgress)(({ margin, height, width, styles }) => ({
  margin: `${margin}px !important`,
  height: `${height}px !important`,
  width: `${width}px !important`,
}));

const BorderLinearProgress = ({ loading = false, margin = 10, height = 100, width = 100 }) =>
  loading
    ? (<LinearProgress
          className="progress-xs"
          color="primary"
          margin={margin}
          height={height}
          width={width}
      />)
    : (<></>)
  ;

export default BorderLinearProgress;

const prefix = 'StyledTree';

const classes = {
  root: `${prefix}-root`,
  actionBtn: `${prefix}-actionBtn`,
  label: `${prefix}-label`
};

const createStyles = () => ({
  flexGrow: 1,
  [`&. ${classes.actionBtn}`]: {
    opacity: '0',
    backgroundColor: '#ffffff',
    padding: '4px',
    position: 'relative',
    margin: '0 2px',
    zIndex: 100
  },
  [`&. ${classes.label}`]: {
    '& .MuiTreeItem-label, & .MuiTreeItem-label:hover': {
      backgroundColor: 'transparent !important'
    }
  }
});

export default { createStyles, classes };
export { createStyles, classes };

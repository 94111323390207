
const prefix = 'StyledTableBlock';

const classes = {
  rootElement: `${prefix}-rootElement`,
  navCellElement: `${prefix}-navCellElement`,
  cellElement: `${prefix}-cellElement`
};

const createStyles = ({ theme }) => ({
  [`&.${classes.rootElement}`]: {
    backgroundColor: 'inherit',
    minHeight: '150px',
    borderRadius: 0,
    padding: '0px !important',
    width: '100%',
    display: 'table',
    tableLayout: 'fixed',
    // transition: theme.transitions.create("all", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  [`& .${classes.navCellElement}`]: {
    padding: 0,
    display: 'table-cell',
    // width: '300px',
    whiteSpace: 'nowrap',
    // background: `linear-gradient(to right, #fcfcfb, ${theme.palette.contextButtonsBackground})`,
    // background: `linear-gradient(to right, #fcfcfb, rgba(68, 114, 196))`,
    // borderRight: `1.5px solid ${theme.palette.headerBlockBackground}`,
    height: '100%',
    // cursor: "ew-resize",
  },
  [`& .${classes.cellElement}`]: {
    // padding: '0px !important',
    display: 'table-cell'
  }
});

export default { createStyles, classes };
export { createStyles, classes };

import React from 'react';
import { Box, Button, Dialog as MuiDialog, Grid, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { TITLES } from './../../../constants';
import { dialogStyles } from './../../../assets/styles';

const { createStyles, classes } = dialogStyles;
const Dialog = styled(MuiDialog)(createStyles);

const ModalConfirm = props  => {
  const {
    onClose = () => {},
    openModal,
    toggleModal,
    handleConfirm,
    cancelConfirm,
    resetConfirm,
    title,
    additionalFields = [],
    cancelTitle = TITLES.NO,
    confirmTitle = TITLES.YES,
    resetTitle
  } = props;

  return (
    <Dialog
      classes={{ paper: 'shadow-lg' }}
      open={openModal}
      onClose={() => {
        toggleModal();
        setTimeout(onClose, 0);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '25px 50px',
          gap: '38px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            fontSize: '24px',
            fontWeight: 700,
          }}
        >
          { title }
        </Box>
        <Box>
          {
            !!additionalFields.length &&
              <Grid container spacing={1}>
                {
                  additionalFields.map(Field => Field)
                }
              </Grid>
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            gap: '20px'
          }}
        >
          <Button
            variant='outlined'
            className="btn-reset"
            onClick={
              () => {
                toggleModal();
                setTimeout(onClose, 0);
              }
            }
          >
            { TITLES.CANCEL }
          </Button>
          {
            !!cancelConfirm && cancelTitle && (
              <Button
                variant='outlined'
                className="btn-reset"
                onClick={
                  () => {
                    toggleModal();
                    cancelConfirm();
                  }
                }
              >
                { cancelTitle }
              </Button>
            )
          }
          <Button
            variant='contained'
            className="btn-save"
            onClick={() => {
              handleConfirm();
              toggleModal();
            }}
          >
            { confirmTitle }
          </Button>
          {
            resetTitle && (
              <Button
                variant='outlined'
                className="btn-reset"
                onClick={() => {
                  resetConfirm();
                  toggleModal();
                }}
              >
                { resetTitle }
              </Button>
            )
          }
        </Box>
      </Box>
    </Dialog>
  );
};

export default ModalConfirm;

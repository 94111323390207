import { stringify } from 'querystring';
import { getAxios } from './../../utils/axios';

export const getUsers = (query = {}) =>
  getAxios().get(`/access-manager/users?${stringify({ ...query, 'CreatedAt[$ne]': Date.now() })}`);

export const createUser = (data) =>
  getAxios().post(`/access-manager/users`, data);

export const updateUser = (id, data) =>
  getAxios().put(`/access-manager/users/${id}`, data);

export const removeUser = (id) =>
  getAxios().delete(`/access-manager/users/${id}`);

export const resetPassword = (id) =>
  getAxios().put(`/access-manager/users/${id}/reset-password`);

export const getRoles = (query = {}) =>
  getAxios().get(`/access-manager/roles?${stringify(query)}`);

export const createRole = (data) =>
  getAxios().post(`/access-manager/roles`, data);

export const updateRole = (id, data) =>
  getAxios().put(`/access-manager/roles/${id}`, data);

export const removeRole = (id) =>
  getAxios().delete(`/access-manager/roles/${id}`);

export const getPolicies = (query = {}) =>
  getAxios().get(`/access-manager/policies?${stringify(query)}`);

export const createPolicy = (data) =>
  getAxios().post(`/access-manager/policies`, data);

export const updatePolicy = (id, data) =>
  getAxios().put(`/access-manager/policies/${id}`, data);

export const removePolicy = (id) =>
  getAxios().delete(`/access-manager/policies/${id}`);

export const getResources = () =>
  getAxios().get('/access-manager/resources');

export const createResource = (data) =>
  getAxios().post(`/access-manager/resources`, data);

export const updateResource = (id, data) =>
  getAxios().put(`/access-manager/resources/${id}`, data);

export const removeResource = (id) =>
  getAxios().delete(`/access-manager/resources/${id}`);

export const getGroups = (query = {}) =>
  getAxios().get(`/access-manager/groups?${stringify({ ...query, 'CreatedAt[$ne]': Date.now() })}`);

export const createGroup = (data) =>
  getAxios().post(`/access-manager/groups`, data);

export const updateGroup = (id, data) =>
  getAxios().put(`/access-manager/groups/${id}`, data);

export const removeGroup = (id) =>
  getAxios().delete(`/access-manager/groups/${id}`);

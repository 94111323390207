import { useState, useEffect } from 'react';
import { getPendingReviewers } from '../utils/common';

const usePendingReviewers = (reviewers, step) => {
  const [pendingReviewers, setPendingReviewers] = useState([]);

  useEffect(() => {
    const filteredReviewers = getPendingReviewers(reviewers, step);
    setPendingReviewers(filteredReviewers);
  }, [JSON.stringify(reviewers), step]);

  return pendingReviewers;
};

export default usePendingReviewers;

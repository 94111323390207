import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, SvgIcon } from '@mui/material';

import { ReactComponent as HistoryIcon } from '../../../assets/images/tabs/history.svg'
import { formatDate } from './../../../utils/date';
import { joinNonEmptyStrings } from './../../../utils/common';

export const ListMessages = (props) => {
  const { notifications = [], isEmpty, users = [] } = props;
  const sortNotifications = notifications
    .sort(
      ({ step: stepA, createdAt: createdAtA }, { step: stepB = 0, createdAt: createdAtB = 0 }) =>
        ((new Date(createdAtB)).getTime() >= (new Date(createdAtA)).getTime())
          ? 1 : -1
    );

  const findUser = (email) => {
    const { FirstName, LastName } = users.find(({ Email }) => email === Email) || { FirstName: '', LastName: '' };
    return joinNonEmptyStrings([FirstName, LastName]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '20px',
        alignSelf: 'stretch',
        padding: '25px 0px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '5px',
          alignItems: 'center',
        }}
      >
        {
          (!!sortNotifications.length || isEmpty) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                gap: '5px',
              }}
            >
              <Box>
                <SvgIcon
                  // htmlColor={'#cf2121'}
                  component={HistoryIcon}
                />
              </Box>
              <Box
                sx={{
                  fontWeight: 700
                }}
              >
               History
              </Box>
            </Box>
          )
        }
      </Box>
      <Box
        className="scroll-area-xl w-100"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <PerfectScrollbar className={'w-100'}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
            }}
          >
            {
              sortNotifications
                .map(({
                  id,
                  email,
                  action,
                  step,
                  message,
                  createdAt,
                  reviewerType
                  },
                  i
                ) => (
                  <Box
                    key={id || i}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '5px',
                      gap: '5px',
                      width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '13px',
                        fontWeight: 600
                      }}
                    >
                      { formatDate(createdAt) }
                    </Box>
                    <Box
                      sx={{
                        fontSize: '13px',
                        fontWeight: 400
                      }}
                    >
                      {reviewerType} {findUser(email)} {action} Gate {step}.
                    </Box>
                    {
                      !!message && (
                        <Box
                          sx={{
                            fontSize: '12px',
                            fontWeight: 300
                          }}
                        >
                          { message }
                        </Box>
                      )
                    }
                  </Box>
                ))
            }
          </Box>
        </PerfectScrollbar>
      </Box>
    </Box>
  )
};

export default ListMessages;

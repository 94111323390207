import React, { useState, useCallback, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Grid,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  TextField,
  IconButton,
  Typography,
  Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AUTH_STATUS_NEWPASSWORD, AUTH_STATUS_MFA } from './../../../constants';
import { AuthService } from './../../../services';
import { LoginLogo, LoginWrapper } from '../../controls';

export default () => {
  const history = useHistory();

  const [checked, setChecked] = useState(true);
  const [redirect, setRedirect] = useState('');
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = AuthService.isAuthenticated();

  const handleSelectChange = useCallback((type) => ({
    [type]: (value) => {
      setFields(prevFields => ({ ...prevFields, [type]: value.target.value }));
    }
  }[type]), []);

  const handleChange = useCallback((event) => {
    setChecked(event.target.checked);
  }, []);

  const handleSubmit = useCallback(() => {
    setErrors([]);
    setLoading(true);

    AuthService.login(fields)
      .then(authData => {
        console.log(':authData ', authData)


        if (authData.status === AUTH_STATUS_NEWPASSWORD) {
          history.push({
            pathname: '/reset-password',
            authData
          });

          return;
        }

        if (authData.status === AUTH_STATUS_MFA) {
          history.push({
            pathname: '/verification-code',
            authData: { ...authData, fields },
          });

          return;
        }

        if (authData.accessToken) {
          setRedirect('/');
        };

        const message = {
          redirect: window.self.location.href,
          date: Date.now(),
        };
        console.log('message: ', message)
        window.parent.postMessage(message, '*');
      })
      .catch((error) => {
        setErrors(error.errors || ['The User email and/or password combination are incorrect.']);
      })
      .finally(() => setLoading(false));
  },[JSON.stringify(fields)]);

  useEffect(() => {
    if (isAuthenticated) setRedirect('/');
  }, [isAuthenticated]);

  if (redirect) return (<Redirect to={redirect}/>);

  return (
    <LoginWrapper
      Bottom={<></>}
    >
      <a href='/login'>
        <LoginLogo/>
      </a>
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div className="bg-composed-wrapper--content p-3 p-md-5">
          <Container>
            <Typography variant="h4" gutterBottom className="text-center">
              We Value Your Privacy
            </Typography>
            <Typography variant="h5" gutterBottom>
              InnoMetrix Privacy Policy
            </Typography>
            <Typography variant="body2" gutterBottom>
              Effective date – The effective date of this InnoMetrix Privacy Policy is July 1, 2022.
            </Typography>
            <Typography variant="body2" gutterBottom>
              At InnoMetrix, we are committed to protecting your privacy.
              This Privacy Policy explains when and why we collect personal information about people who visit our website (innometrix.net), how we use it, the conditions under which we may disclose it to others and how we keep it secure. Please note that this Privacy Policy does not apply to information collected from you through other means, although such information may be covered by other InnoMetrix policies. This Privacy Policy is subject to occasional revision, and changes will be posted on this page. You are urged to return to check this Privacy Policy for the latest updates prior to submitting any information through our website. If you have comments, suggestions, questions, or concerns about this Privacy Policy, please contact us at <a className="color-green" href="mailto:privacy@innometrix.net">privacy@innometrix.net</a> or write us at 4112 53rd Ave East, Suite 20181, Bradenton, Florida 34204.
            </Typography>

            <Typography variant="h6" gutterBottom>
              What information do we collect?
            </Typography>
            <Typography variant="body2" gutterBottom>
              When accessing our website, you may be asked to provide contact information, email address, company, title, telephone number and/or other “Contact Information” to access certain content so that we can better provide information and services to you (your “Personal Information”). You may, however, visit our site anonymously but may not have access to all content.
            </Typography>
            <Typography variant="body2" gutterBottom>
              We also gather general information about the use of our website, such as which pages are visited most frequently, duration of visit, etc. Website tracking only uses anonymous information and statistics which do not identify individual visitors. This information helps us improve the services and information we offer on our website. We may use service providers to help us collect and analyze this information. If you choose to fill out a form on our website, we or our third-party collectors will collect your Personal Information. We may send you emails containing marketing information once you opt-in using the various sign-up or contact us forms throughout our website. We may also call you based on the nature of your opt-in or submission of a contact us form.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Information Collection and Use through the Services
            </Typography>
            <Typography variant="body2" gutterBottom>
              We require customers who register to use the InnoMetrix services offered on our website to give us contact information, such as their name, company name, address, phone number, and e-mail address, and financial qualification and billing information, such as billing name and address, and the number of users within the organization that will be using InnoMetrix products and services, and we may also ask for additional information such as title, department name, fax number, and additional company information, such as annual revenues, number of employees, or industry. It is the customer’s obligation, as the data controller, to inform its registered users about the purposes for which information about them is collected and may be used on the InnoMetrix services. Registered users can update or remove their account information at any time by contacting us at <a className="color-green" href="mailto:privacy@innometrix.com">privacy@innometrix.com</a>
            </Typography>
            <Typography variant="body2" gutterBottom>
              InnoMetrix uses the account information that we collect to set up the InnoMetrix products and services for individuals and their organizations. We may also use the account information to contact our registered users to further discuss customer interest in InnoMetrix and its products and the services that we provide, and to send information regarding InnoMetrix or its partners, such as newsletters, promotions and events. All financial and billing information that we collect through our website is used solely to check the qualifications of prospective customers and to bill for the InnoMetrix products and services. Except as we explicitly state at the time we request information, we do not disclose to third parties the information provided.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Registered users are required to provide an email address when registering for the InnoMetrix products and services, in order to receive a username and password. We may also email information regarding updates to the InnoMetrix products and services. Registered users of the InnoMetrix products and services will be using our website to host data and information (“Data”). InnoMetrix will not review, share, distribute, print, or reference any such Data except as provided in your Agreement with InnoMetrix, or as may be required by law. Individual records may at times be viewed or accessed for the purpose of resolving a problem, support issue, improving system performance, or suspected violation of your Agreement with InnoMetrix, or as may be required by law. Users are responsible for maintaining the confidentiality and security of their user registration and password.
            </Typography>
            <Typography variant="body2" gutterBottom>
              InnoMetrix collects and processes third party personal information under the direction of our customers. We have no ownership of this information or any direct relationship with individuals whose personal information may be processed as part of providing our services.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Upon request InnoMetrix will provide you with information about whether we hold, or process on behalf of a third party, any of your personal information. To request this information please contact us at <a className="color-green" href="mailto:privacy@innometrix.net">privacy@innometrix.net</a>.
            </Typography>
            <Typography variant="body2" gutterBottom>
              An individual who seeks access to their data, seeks to correct, amend or delete inaccurate data, or wishes to opt-out of communications from a InnoMetrix customer should direct his/her query to the InnoMetrix customer he/she interacts with directly (the data controller). If an InnoMetrix customer requests that we remove information on their behalf, we will respond to their request within 30 days.
            </Typography>
            <Typography variant="body2" gutterBottom>
              InnoMetrix may transfer personal information to other companies that help us provide our products and services. Transfers to subsequent third parties are covered by the provisions in this InnoMetrix Privacy Policy regarding notice and choice and the service agreements with our customers.
            </Typography>
            <Typography variant="body2" gutterBottom>
              InnoMetrix will retain Personal Information we process on behalf of our customers for as long as needed to provide services and as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
            </Typography>

            <Typography variant="h6" gutterBottom>
              What do we use your Personal Information for?
            </Typography>
            <Typography variant="body2" gutterBottom>
              Any of the Personal Information we collect from you may be used in one of the following ways:
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Typography variant="body2">
                1. To personalize your experience (your Personal Information helps us to better respond to your individual needs);
              </Typography>
              <Typography variant="body2">
                2. To improve customer service (your Personal Information helps us to more effectively respond to your customer service requests and support needs); and
              </Typography>
              <Typography variant="body2">
                3. To track activity and correspondence between InnoMetrix staff and you so we can make sure we are fulfilling your service needs.
              </Typography>
              <Typography variant="body2">
                4. To provide you with the marketing information on services or products you have requested and to respond to your questions. If you have indicated that you do not wish to be contacted, we will not send you marketing information, but may still respond to you if you submit a question, fill out a contact us form or request a demo. When you register with us or submit a form with your contact details, we will create a user account, so that when you come back and want to fill out a form, your information will be pre-populated for your convenience.
              </Typography>
            </Typography>
            <Typography variant="body2" gutterBottom>
              We review our retention periods for Personal Information on a regular basis. We will hold your Personal Information on our systems for as long as is necessary for the relevant activity.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Storage and Processing.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Your Personal Information collected through forms that you complete on our website may be stored and processed in the United States, Europe or any other country in which our third-party hosting company or its subsidiaries, affiliates or service providers maintain facilities. Our third-party hosting company may transfer information that we collect about you, including your Personal Information, to affiliated entities or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use that may differ from the U.S. law, please note that we will only transfer information, including your Personal Information, in compliance with the data protection laws of your country or jurisdiction
            </Typography>

            <Typography variant="h6" gutterBottom>
              Do we use Cookies?
            </Typography>
            <Typography variant="body2" gutterBottom>
              Yes. (Cookies are small files that a site or its service provider transfers to your computer’s hard drive (if you allow) through your Web browser that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information.) InnoMetrix utilizes the HubSpot marketing automation system for many of its website content areas and forms. HubSpot’s tracking code sets the following cookies when someone visits our site. These cookies fall into two general categories:
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Typography variant="body2">
                <svg height="10" width="10" className="mr-2 mb-1">
                  <circle cx="5" cy="5" r="1" stroke="#575859" stroke-width="3" fill="#575859" />
                </svg>
                To personalize your experience (your Personal Information helps us to better respond to your individual needs);
              </Typography>
              <Typography variant="body2">
                <svg height="10" width="10" className="mr-2 mb-1">
                  <circle cx="5" cy="5" r="1" stroke="#575859" stroke-width="3" fill="#575859" />
                </svg>
                Consent banner cookies: there are cookies included in the consent banner under GDPR.
              </Typography>
            </Typography>
            <Typography variant="body2">
              More information about HubSpot’s Privacy Policy can be found here.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Do we disclose any Personal Information to outside parties?
            </Typography>
            <Typography variant="body2" gutterBottom>
              <Typography variant="body2">
                1. Subsidiaries and Affiliates. We may share your Personal Information with our subsidiaries or affiliated companies.
              </Typography>
              <Typography variant="body2">
                2. Partners. We may share your Personal Information with our Partners. We typically use this information to register your interest in their solutions.
              </Typography>
              <Typography variant="body2">
                3. Service Providers. We may share your Personal Information with certain third-party companies that we hire to perform services on our behalf, such as, but not limited to, hosting or operating our website and related software applications used for managing contacts and, sending and managing email, analyzing data, and providing search results.
              </Typography>
              <Typography variant="body2">
                4. Business Transitions. We may share your Personal Information with a third party as part of a sale of some or all of our business and assets to any third party or as part of any business restructuring or reorganization, or if we’re under a duty to disclose or share your Personal Information in order to comply with any legal obligation or to enforce or apply our terms of use or to protect the rights, property or safety of our supporters and customers. However, we will take steps with the aim of ensuring that your privacy rights continue to be protected.
              </Typography>
              <Typography variant="body2">
                5. We may share non-personally identifiable information (such as anonymous usage data, referring/exit pages and URLs, number of clicks, etc.) with third parties (e.g., Google Analytics) to help us understand the usage patterns for InnoMetrix website visitation, our services and products.
              </Typography>
            </Typography>

            <Typography variant="h6" gutterBottom>
              Your Choices.
            </Typography>
            <Typography variant="body2" gutterBottom>
              You have a choice about whether or not you wish to receive information from us. If you do not want to receive direct marketing communications from us about our news, events, blog posts, products and services, then you can select to opt out on email communication or by sending an email to <a className="color-green" href="mailto:privacy@innometrix.net">privacy@innometrix.net</a> with a request for opting out. For EU citizens, we will not contact you for marketing purposes by email, phone or text message unless you have given your prior consent or provided Personal Information through a form submission. We will not contact you for marketing purposes by mail if you have indicated that you do not wish to be contacted. You can change your marketing preferences at any time by contacting us by email: <a className="color-green" href="mailto:privacy@innometrix.com">privacy@modelmetrix.com</a>.
            </Typography>

            <Typography variant="h6" gutterBottom>
              How We Protect Your Information.
            </Typography>
            <Typography variant="body2" gutterBottom>
              We use a secure and validated 3rd party provider to host your data.   After your visit to our website, your Personal Information is never stored on our servers local to InnoMetrix. InnoMetrix is concerned with protecting your privacy and data, but we cannot ensure or warrant the security of any information you transmit to InnoMetrix or guarantee that your information submitted on our website may not be accessed, disclosed or altered or destroyed by breach of any of our industry standard physical, technical or managerial safeguards. When you enter sensitive information (such as your Personal Information) on our registration or contact forms, your information is encrypted while in transit using secure socket layer (SSL) technology. No method of the transmission over the Internet or method of electronic storage is 100% secure. Therefore, we cannot guarantee absolute security. If you have questions about security on our site, you can contact us at <a className="color-green" href="mailto:privacy@innometrix.net">privacy@innometrix.net</a>.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Links.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Our website may include links to websites run by other organizations. We are not responsible for the privacy practices of other organizations’ websites, so you should read their privacy policies carefully.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Right of Access.
            </Typography>
            <Typography variant="body2" gutterBottom>
              You have the right to ask for a copy of the information we hold about you and to have any inaccuracies in your information corrected. If you wish to exercise these rights, please contact us at <a className="color-green" href="mailto:privacy@innometrix.net">privacy@innometrix.net</a>.
            </Typography>

            <Typography variant="h6" gutterBottom>
              California Online Privacy Protection Act Compliance.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.
            </Typography>
            <Typography variant="body2" gutterBottom>
              As part of the California Online Privacy Protection Act, all users of our website may make any changes to their information at any time by contacting us at <a className="color-green" href="mailto:privacy@innometrix.com">privacy@modelmetrix.com</a>.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Children’s Online Privacy Protection Act Compliance.
            </Typography>
            <Typography variant="body2" gutterBottom>
              We are in compliance with the requirements of COPPA (Children’s Online Privacy Protection Act). We do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older.
            </Typography>

            <Typography variant="h6" gutterBottom>
              Your Consent
            </Typography>
            <Typography variant="body2" gutterBottom>
              By using our website, you consent to this Privacy Policy as set forth herein.
            </Typography>

          </Container>
          </div>
        </div>
    </LoginWrapper>
  );
}

import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { TreeView as MuiTreeView } from '@mui/lab';
import { styled, useTheme } from '@mui/material/styles';

import { Link } from '@mui/material';
import { CloseSquare, MinusSquare, PlusSquare } from './../../icons';
import { createStyles } from './../../../assets/styles/tree';
import { DISPLAY_CONTROLLERS, ENDPOINTS, ICONS, METHOD_GET } from './../../../constants';
import { RenderField, RenderTreeItem } from './../';
import { useResizeWidth } from './../../../hooks';
import { checkAccess, getExpandIds, prepareTree } from './../../../utils/common';
import { AuthService, Storage } from './../../../services';
import { createDispatches } from './../../../reducers/functions';

const TreeView = styled(MuiTreeView)(createStyles);

const RenderTree = (props) => {
  const {
    classes = {},
    selected,
    handleToggle,
    handleSelect,
    setItems,
    items,
    styles = {},
    iconType = ICONS.arrow,
    children,
    disableDefaultEndIcon = true,
    disableResize,
    setExpanded,
    expanded,
    collapseExpanded = false,
    docsConfig,
    docs,
    ...otherProps
  } = props;

  const theme = useTheme();
  const {
    setDocTypes,
    setDocTreeOptions,
  } = createDispatches(useDispatch());
  const {
    docTypes,
    docTreeOptions,
  } = useSelector(state => state.ThemeOptions);

  const [treeWidth, handleMouseDown] = useResizeWidth();
  const [isCollapse, setIsCollapse] = useState(true);

  const { getIcons } = useMemo( () => [
    {
      condition: iconType === ICONS.arrow,
      getIcons: () => ({
        defaultCollapseIcon: (<ExpandMoreIcon />),
        defaultExpandIcon: (<ChevronRightIcon />),
        defaultEndIcon: !disableDefaultEndIcon && (
          <SvgIcon
            className={'defaultEndIcon'}
            component={RadioButtonUncheckedIcon}
            sx={{ fontSize: '10px !important', marginTop: '2px' }}
          />
        ),
      })
    },
    {
      condition: true,
      getIcons: () => ({
        defaultCollapseIcon: (<MinusSquare />),
        defaultExpandIcon: (<PlusSquare />),
        defaultEndIcon: (<CloseSquare />),
      })
    }
  ].find(({ condition }) => condition), [iconType]);

  const setResource = useCallback((docsConfig) => {

    return Object.keys(docsConfig)
      .filter(docType => checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_GET] }))
      .map(docType => ({
        id: docType,
        label: docsConfig[docType].DocumentType || docType
      }))
  }, []);

  const recalculateTree = useCallback(() => {
    let nextItems;

    if (docTypes.length) {
      const nextDocs = Object.keys(docs).reduce((result, docType) =>
          docTypes.includes(docType) ? { ...result, [docType]: docs[docType] } : result,
        {}
      );
      nextItems = prepareTree({ docsConfig, docs: nextDocs });
      setItems(nextItems);
    } else {
      nextItems = prepareTree({ docsConfig, docs });
      setItems(nextItems);
    }
    const expandIds = getExpandIds(nextItems, docTypes);
    const nextExpended = !isCollapse ? [] : expandIds;

    setExpanded(nextExpended);
    Storage.saveStateData({ expanded: nextExpended });
  }, [expanded, docTypes, docsConfig, docs]);

  const toggleExpandHandle = useCallback(() => {
    recalculateTree();
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  useEffect(() => {
    if (!docTreeOptions.recalculate) return;

    recalculateTree();
    setDocTreeOptions({
      ...docTreeOptions,
      recalculate: false
    });
  }, [docTreeOptions]);

  return (
    <>
      <div
        style={{
          width: treeWidth,
          padding: '0 10px 0 0',
          display: 'table-cell',
          whiteSpace: 'nowrap',
        }}
        className="scroll-bar"
      >
        <div style={{ position: 'fixed', width: treeWidth }}>
          <div style={{ height: '700px', 'overflow': 'auto', padding: '0px 5px' }}>
            {
              children
            }
            {
              collapseExpanded && (
                <>
                  <div className="float-right mt-1 mb-1">
                    <Link
                      className="border-0 btn-outline-none"
                      component="button"
                      onClick={toggleExpandHandle}
                      sx={{
                        color: theme.palette.grey.dark
                      }}
                    >
                      {
                        !isCollapse ? 'Collapse all' : 'Expand all'
                      }
                    </Link>
                  </div>
                  {/*<div className="mt-4 mb-1">*/}
                  {/*  <RenderField*/}
                  {/*    item={{*/}
                  {/*      label: 'Doc Types',*/}
                  {/*      field: `docTypes`,*/}
                  {/*      displayController: DISPLAY_CONTROLLERS.RecordSet,*/}
                  {/*      resources: docTypes,*/}
                  {/*      allResources: setResource(docsConfig)*/}
                  {/*    }}*/}
                  {/*    selectedFieldItems={{ docTypes  }}*/}
                  {/*    userHandleChange={*/}
                  {/*      (event) => {*/}
                  {/*        setDocTypes(event.target.value);*/}
                  {/*        setIsCollapse(true);*/}
                  {/*      }*/}
                  {/*    }*/}
                  {/*  />*/}
                  {/*</div>*/}
                </>
              )
            }
            <TreeView
              {...getIcons()}
              expanded={expanded}
              selected={selected}
              onNodeToggle={handleToggle}
              onNodeSelect={handleSelect}
              className={classes}
            >
              {
                items.map(item => <RenderTreeItem key={item.nodeId} styles={styles} { ...{ ...otherProps, setExpanded, expanded, item, docsConfig } } />)
              }
            </TreeView>
          </div>
        </div>
      </div>
      {
        !disableResize && (
          <div
            onMouseDown={handleMouseDown}
            style={{
              display: 'table-cell',
              width: '1px',
              cursor: "ew-resize",
              padding: '0 1px',
              backgroundColor: theme.palette.headerBlockBackground
            }}
          />
        )
      }
    </>
  )
};

export default RenderTree;

const classes = {
  textbox: 'StyledForm-textbox'
};

const createStyles = ({ theme, $isOverwrite, ...props }) => {

  return ({
    [`&.${classes.textbox}`]: {
      '& .MuiInput-formControl': {
        margin: '0px'
      },
      '& .MuiInputBase-fullWidth': {
        background: !$isOverwrite ? theme.palette.defaultInputBackground : theme.palette.changedInputBackground
      },
      '& .MuiInputLabel-outlined, & .MuiInputLabel-root': {
        transform: 'translate(12px, 10px) scale(1)'
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink, & .MuiInputLabel-root.MuiInputLabel-shrink': {
        pointerEvents: "auto",
        cursor: 'pointer',
        transform: 'translate(12px, -6px) scale(0.75)'
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: '0 !important'
      },
      '& legend': !props.label ? { width: 0 } : {}
    }
  });
};

export default { createStyles, classes };
export { createStyles, classes };

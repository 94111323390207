import React from 'react';
import { StyledTreeItem } from './../';

const RenderTreeItem = ({ item, props, docs, styles, controlDocsConfig, setExpanded, expanded, ...otherProps }) => {
  const { children, icon, ...itemProperties } = item;

  if (!item.children) return(
    <StyledTreeItem
      key={item.nodeId}
      item={item}
      { ...otherProps }
      { ...itemProperties }
      { ...props }
      setExpanded={setExpanded}
      expanded={expanded}
      controlDocsConfig={controlDocsConfig}
    />
  );

  return (
    <StyledTreeItem
      key={item.nodeId}
      item={item}
      docs={docs}
      controlDocsConfig={controlDocsConfig}
      styles={styles}
      setExpanded={setExpanded}
      expanded={expanded}
      { ...otherProps }
      { ...itemProperties }
      { ...props }
    >
      {
        children.map(item => RenderTreeItem({ ...otherProps, item, props, docs, controlDocsConfig, setExpanded, expanded }))
      }
    </StyledTreeItem>
  )
};

export default RenderTreeItem

import React, { useState } from 'react';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  Save as SaveIcon
} from '@mui/icons-material';

import { joinNonEmptyStrings } from './../../../utils/common';
import { formatDate } from './../../../utils/date';
import Wysiwyg from '../Wysiwyg';
import { Button, Grid } from '@mui/material';

const Note = props => {
  const {
    editable,
    value: {
      user,
      date,
      content,
    } = {},
    onChange = _ => _,
    onDelete = _ => _
  } = props;
  const [note, setNote] = useState(content);
  const [isEdit, setEdit] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div className='font-weight-bold'>
          <a href='#/' className='text-black'>
            {joinNonEmptyStrings([user.FirstName, user.LastName])}
          </a>
        </div>
      </Grid>
      <Grid item xs={8}>
          {
            isEdit ?
              <div className='pt-4'>
                <Wysiwyg
                  value={note}
                  handleChange={e => setNote(e.target.value)}
                  height={100}
                />
              </div>
              :
              <span className='d-flex pt-2 align-items-center'>
                <div
                  dangerouslySetInnerHTML={{__html: note}}
                />
              </span>
          }
      </Grid>
      <Grid item xs={4}>
        {
          editable &&
          <>
            {isEdit
              ?
              <>
                <Button
                  size='small'
                  className='mr-1 '
                  onClick={() => {
                    onChange({ e: { target: { value: note } } });
                    setEdit(!isEdit);
                  }}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
                <Button size='small' onClick={() => setEdit(false)} startIcon={<CloseIcon />}>
                  Close
                </Button>
              </>
              :
              <>
                <Button size='small' className='mr-1' onClick={() => setEdit(!isEdit)} startIcon={<EditIcon />}>
                  Edit
                </Button>
                <Button size='small' onClick={() => onDelete()} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              </>
            }
          </>
        }
      </Grid>
      <Grid item xs={12}>
        <div>{formatDate(date)}</div>
      </Grid>
    </Grid>
  )
};

export default Note;

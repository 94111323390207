const prefix = 'StyledDialog';

const classes = {
  root: `${prefix}-root`,
  closeButton: `${prefix}-closeButton`
};

const createStyles = ({ theme }) => ({
  [`&.${classes.root}`]: {
    margin: 0,
    padding: theme.spacing(2)
  },
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

export default { createStyles, classes };
export { createStyles, classes };

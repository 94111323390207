import util from "util";
import React from 'react';
import { useHistory } from 'react-router';
import { ExpandMore as ExpandMoreIcon, Replay as ReplayIcon } from '@mui/icons-material';
import {
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Divider,
  Button,
  useTheme,
  styled,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary
} from '@mui/material';

import {
  DISPLAY_CONTROLLERS,
  ENDPOINTS, ERROR_DOCUMENT_EDITED,
  FIELD_KEYS,
  IDOC_TYPE,
  KDOC_TYPE,
  MDOC_TYPE,
  METHOD_POST,
  METHOD_PUT, ROLE_DATAENTRY,
  ROLE_SME, ROLE_SPONSOR,
  CALCULATIONS_GROUPS, CALCULATIONS_GROUP_ESTIMATED, ROLE_IUSER, PRDOC_TYPE, GDOC_TYPE
} from '../../../constants';

import { RenderField, ModalTimeFields  } from './../';
import {
  checkAccess,
  filterUsersByRole,
  findItem,
  getItemsByCond,
  getTotalByGridValues,
  sortPosition,
  getFieldParams
} from './../../../utils/common';
import { createStyles } from '../../pages/DocumentTree/styles';

const Accordion = styled(MuiAccordion)(createStyles);
const AccordionDetails = styled(MuiAccordionDetails)(createStyles);
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  // '& .MuiAccordionSummary-content': {
  //   marginLeft: theme.spacing(1),
  // },
}));

const getGroupByFieldName = (FieldName = '') =>
  CALCULATIONS_GROUPS.find(calculationsGroupType => FieldName.includes(calculationsGroupType))

const RenderTabContent = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const {
    docsConfig,
    items,
    docs,
    selectedItem,
    fieldsType,
    setSelectedFieldItems,
    selectedFieldItems,
    setDisabled,
    disabled,
    controlDocs,
    validateAccessDocument,
    users = [],
    roles = [],
    handleFieldsSave,
    handleFieldsCancel,
    setActiveStep,
    activeStep,
    setPendingReviewers,
    pendingReviewers,
    toggleGridModal,
    authUser,
    refreshDocument,
    reloadDocument,
    modals = [],
    openGridModal,
    handleGridChange,
    kDocAggregationMethod,
  } = props;
  const docType = selectedItem?.type;

  const handleOpenControlDocs = (type, doc) => {
    history.push({
      pathname: '/control-docs',
      controlDoc: { type, doc }
    });
  };

  const { value: gates = [] } = selectedItem?.[FIELD_KEYS.Overview] || []
    .find(({ field }) => field === `${docType}OpenGateRequests`) || {};

  const { gate = 0 } = [...gates].pop() || {};

  const disableTimeFields = gate > 4 && fieldsType === FIELD_KEYS.TimeFields;

  return (
    <>
      {
        selectedItem?.notGrouped?.LockInfo?.Locked && authUser.UserId !== selectedItem?.notGrouped?.LockInfo?.UserId && (
          <div className="mb-4">
            <p
              className="text-danger text-left font-weight-bold"
              style={{
                height: '32px',
                verticalAlign: 'bottom',
                display: 'table-cell'
              }}
            >
              <span>
                {
                  util.format(
                    ERROR_DOCUMENT_EDITED,
                    `${selectedItem.notGrouped.LockInfo.FirstName} ${selectedItem.notGrouped.LockInfo.LastName}`
                  )
                }
              </span>
              {
                !refreshDocument && (
                  <IconButton
                    aria-label="replay"
                    title="refresh"
                    onClick={reloadDocument}
                    size="large"
                    style={{ padding: '0 12px' }}
                  >
                    <ReplayIcon />
                  </IconButton>
                )
              }
              {
                refreshDocument && (
                  <span style={{ padding: '0px 12px', position: 'relative', top: '6px' }}>
                    <CircularProgress size={24} />
                  </span>
                )
              }
            </p>
          </div>
        )
      }
      {
        ![
          FIELD_KEYS.ConsumedValue,
          FIELD_KEYS.Aggregations,
          FIELD_KEYS.Calculations,
          FIELD_KEYS.Approvers,
          FIELD_KEYS.Overview,
          FIELD_KEYS.RichTextFields,
          FIELD_KEYS.Notes,
          FIELD_KEYS.Audit,
          FIELD_KEYS.GantChart,
        ].includes(fieldsType) &&
          <>
            <Grid  direction='row' justifyContent='flex-start' container spacing={1} >
              {
                selectedItem[fieldsType]
                  .filter(item => [DISPLAY_CONTROLLERS.DocStatus, DISPLAY_CONTROLLERS.AbandonedReason].includes(item.displayController))
                  .sort(sortPosition)
                  .map(item => {
                    return  <Grid item xl={3} lg={6} md={12} key={item.field || item.label} >
                      <RenderField
                        item={{
                          ...item
                        }}
                        fieldsType={fieldsType}
                        selectedFieldItems={selectedFieldItems}
                        setSelectedFieldItems={setSelectedFieldItems}
                        toggleGridModal={
                          !!getTotalByGridValues((selectedFieldItems?.[FIELD_KEYS.TimeFields]?.[`${docType}GridValues`]) || {}) &&
                          toggleGridModal
                        }
                        docType={docType}
                        access={
                          !disabled && checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_POST, METHOD_PUT] })
                        }
                        roles={roles}
                        validateAccessDocument={validateAccessDocument}
                      />
                    </Grid>
                  })
              }
            </Grid>
            <Grid container spacing={1}>
              {
                selectedItem[fieldsType]
                  .filter(
                    item => ![DISPLAY_CONTROLLERS.DocStatus, DISPLAY_CONTROLLERS.AbandonedReason].includes(item.displayController) && (
                      item.RequiredFields
                        ? item.RequiredFields.every(fieldName => !!selectedFieldItems?.[fieldsType]?.[fieldName])
                        : true
                    )
                  )
                  .filter(item => !modals.some(modal => modal.Fields.includes(item.field)))
                  .sort(sortPosition)
                  .map(item => {
                      const { Items = [] } = getFieldParams(docsConfig[docType], fieldsType, item.field);

                      const { getSource = () => Items } = [
                        {
                          condition: docType === IDOC_TYPE && item.field === `${docType}SponsorId` ||
                            (item.field || '').includes('Sme') ||
                            (item.field || '').includes('DataEntry') ||
                            (item.field || '').includes('iDocDependencyId') ||
                            (item.field || '').includes('Owner'),
                          getSource: () => {
                            const prepareUsers = (user) => ({
                              ...user,
                              Value: user.UserId,
                              Label: `${user.FirstName} ${user.LastName} (${user.Email})`,
                            });

                            const source = [
                              {
                                condition: (item.field || '').includes('Sme'),
                                getData: () => filterUsersByRole({ users: Items.map(prepareUsers), roles, roleName: ROLE_SME })
                              },
                              {
                                condition: (item.field || '').includes('DataEntry'),
                                getData: () => filterUsersByRole({ users: Items.map(prepareUsers), roles, roleName: ROLE_DATAENTRY })
                              },
                              {
                                condition: (item.field || '').includes('SponsorId'),
                                getData: () =>
                                  filterUsersByRole({ users: Items.map(prepareUsers), roles, roleName: ROLE_SPONSOR })
                                  // Items.map(prepareUsers).filter(user => (user.Roles || []).includes('RL_01F8WAYYHGW97STPTN9YP6PHRS'))
                              },
                              {
                                condition: (item.field || '').includes('Owner'),
                                getData: () => filterUsersByRole({ users: Items.map(prepareUsers), roles, roleName: ROLE_IUSER })
                              },
                              {
                                condition: (item.field || '').includes('iDocDependencyId'),
                                getData: () => Items.filter(({ Value }) => Value !== selectedItem.nodeId)
                              },
                            ];

                            return source.find(src => src.condition).getData();
                          }
                        },
                        {
                          condition: docType === KDOC_TYPE && item.field === `${MDOC_TYPE}Id`,
                          getSource: () => {
                            const parents = (selectedItem.parent?.parents || []).length
                              ? selectedItem.parent.parents
                              : selectedItem.parents
                            ;
                            const parentIdocId = (parents || []).find(docId => docId.includes(`${IDOC_TYPE.toUpperCase()}_`));
                            const iDoc = findItem(items, parentIdocId);
                            const mDocIds = getItemsByCond(
                              iDoc?.children || [],
                              { docType: MDOC_TYPE }
                            ).map(({ nodeId }) => nodeId);
                            const nextItems = (Items || []).filter(({ [`${MDOC_TYPE}Id`]: docId }) => mDocIds.includes(docId));
                            const nextFilteredItems = nextItems.length
                              ? nextItems
                              : Items.filter(({ [`${GDOC_TYPE}Id`]: gDocId }) => gDocId === selectedItem.notGrouped[`${GDOC_TYPE}Id`]);

                            return nextFilteredItems.length
                              ? nextFilteredItems
                              : Items
                            ;
                          }
                        }
                      ].find(({ condition }) => condition) || {};

                      return (
                        <Grid item xl={item.XL || 3} lg={item.LG || 6} md={12} key={item.field || item.label} data-key={item.field || item.label}>
                          {
                            item.DrawLabel && (
                              <div
                                style={{
                                  color: theme.palette.primary.main,
                                  fontWeight: 'bold'
                                }}
                              >
                                { item.label }
                              </div>
                            )
                          }
                          <RenderField
                            item={{
                              ...item,
                              source : getSource()
                            }}
                            fieldsType={fieldsType}
                            docsConfig={docsConfig}
                            selectedFieldItems={selectedFieldItems}
                            setSelectedFieldItems={setSelectedFieldItems}
                            toggleGridModal={
                              !!getTotalByGridValues((selectedFieldItems?.[FIELD_KEYS.TimeFields]?.[`${docType}GridValues`]) || {}) &&
                              toggleGridModal
                            }
                            docType={docType}
                            access={
                              (!disabled || !!item.ApprovalUnLock) && checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_POST, METHOD_PUT] })
                              && !disableTimeFields
                            }
                            validateAccessDocument={validateAccessDocument}
                          />
                        </Grid>
                      )
                    }

                  )
              }
              {
                modals.map((modal) => {
                  const fields = selectedItem[fieldsType].filter(selectedField => modal.Fields.includes(selectedField.field))

                  return (
                    <Grid item xl={3} lg={3} md={13} key={modal.Title}>
                      <Button
                        variant='outlined'
                        onClick={toggleGridModal}
                        className="h-36px"
                      >
                        <span className="btn-wrapper--label">{modal.Title}</span>
                      </Button>
                      <ModalTimeFields
                        openGridModal={openGridModal}
                        toggleGridModal={toggleGridModal}
                        selectedItem={selectedItem}
                        selectedFieldItems={selectedFieldItems}
                        handleGridChange={handleGridChange}
                        handleFieldsCancel={handleFieldsCancel}
                        handleFieldsSave={handleFieldsSave}
                        docType={docType}
                        fields={fields}
                        setSelectedFieldItems={setSelectedFieldItems}
                        validateAccessDocument={validateAccessDocument}
                        fieldsType={fieldsType}
                      />
                    </Grid>
                  )
                })
              }
            </Grid>
          </>
      }
      {
        [FIELD_KEYS.RichTextFields, FIELD_KEYS.Notes, FIELD_KEYS.Audit, FIELD_KEYS.GantChart].includes(fieldsType) &&
          selectedItem[fieldsType].map(item => (
              <RenderField
                key={item.field}
                item={item}
                fieldsType={fieldsType}
                selectedItem={selectedItem}
                docs={docs}
                docsConfig={docsConfig}
                selectedFieldItems={selectedFieldItems}
                setSelectedFieldItems={setSelectedFieldItems}
                docType={docType}
              />
            )
          )
      }
      {
        [FIELD_KEYS.ConsumedValue, FIELD_KEYS.Aggregations].includes(fieldsType) && ![PRDOC_TYPE].includes(docType) &&
          CALCULATIONS_GROUPS.map((calculationsGroupType, index) => {
            return (
              <div key={`${calculationsGroupType}_${index}`}>
                <Accordion defaultExpanded={!index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                  >
                    <h5 className="font-weight-bold text-black margin-none">{ calculationsGroupType }</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    {
                      selectedItem[fieldsType]
                        .filter(({ field = '', label = '', Group }) => Group === calculationsGroupType ||
                          !Group && field.includes(calculationsGroupType) ||
                          !Group && !field.includes(calculationsGroupType) && label.includes(calculationsGroupType) ||
                          !Group && !getGroupByFieldName(field) && !field.includes(calculationsGroupType) && !label.includes(calculationsGroupType) && calculationsGroupType === CALCULATIONS_GROUP_ESTIMATED
                        )
                        .map((item, i) => {

                          if (fieldsType === FIELD_KEYS.Calculations) {
                            console.log('calculationsGroupType: ', calculationsGroupType)
                            console.log('item: ', item)
                          }

                          return (
                            <RenderField
                              key={item.field}
                              item={item}
                              fieldsType={fieldsType}
                              selectedFieldItems={selectedFieldItems}
                              setSelectedFieldItems={setSelectedFieldItems}
                              docType={docType}
                              kDocAggregationMethod={kDocAggregationMethod}
                            />
                          )
                        })
                    }
                  </AccordionDetails>
                </Accordion>
              </div>
            )

          })
      }
      {
        [FIELD_KEYS.Calculations].includes(fieldsType) && ![PRDOC_TYPE].includes(docType) &&
          CALCULATIONS_GROUPS.map((calculationsGroupType, index) => {
            return (
              <div key={`${calculationsGroupType}_${index}`}>
                <Accordion defaultExpanded={!index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-label="Expand"
                  >
                    <h5 className="font-weight-bold text-black margin-none">{ calculationsGroupType }</h5>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RenderField
                      item={{
                        displayController: DISPLAY_CONTROLLERS.GroupedNumber
                      }}
                      docsConfig={docsConfig}
                      selectedItem={selectedItem}
                      fieldsType={fieldsType}
                      group={calculationsGroupType}
                      selectedFieldItems={selectedFieldItems}
                      setSelectedFieldItems={setSelectedFieldItems}
                      docType={docType}
                    />
                  </AccordionDetails>
                </Accordion>
              </div>
            )

          })
      }
      {
        [FIELD_KEYS.Resources].includes(fieldsType) && ![PRDOC_TYPE].includes(docType) &&
        CALCULATIONS_GROUPS.map((calculationsGroupType, index) => {
          return (
            <RenderField
              item={{
                displayController: DISPLAY_CONTROLLERS.GroupedNumber
              }}
              docsConfig={docsConfig}
              selectedItem={selectedItem}
              fieldsType={fieldsType}
              group={calculationsGroupType}
              selectedFieldItems={selectedFieldItems}
              setSelectedFieldItems={setSelectedFieldItems}
              docType={docType}
            />
          )
        })
      }
      {
        [FIELD_KEYS.Aggregations].includes(fieldsType) && [PRDOC_TYPE].includes(docType) &&
          selectedItem[fieldsType]
            .map((item, i) => {
              return (
                <RenderField
                  key={item.field}
                  item={item}
                  docsConfig={docsConfig}
                  selectedItem={selectedItem}
                  fieldsType={fieldsType}
                  selectedFieldItems={selectedFieldItems}
                  setSelectedFieldItems={setSelectedFieldItems}
                  docType={docType}
                />
              )
            })
      }
      {
        fieldsType === FIELD_KEYS.TimeFields && controlDocs[selectedItem.type] &&
        <div className="mb-3 mt-3">
          {
            Object.keys(controlDocs[selectedItem.type]).map(ctrlType => (
              !!controlDocs[selectedItem.type][ctrlType].data.length &&
              <React.Fragment key={ctrlType}>
                <div className="font-weight-bold font-size-lg mb-1 text-black">
                  {controlDocs[selectedItem.type][ctrlType].DocumentType}
                </div>
                {
                  controlDocs[selectedItem.type][ctrlType].data.map(ctrlDoc => (
                    <Link
                      component="button"
                      key={ctrlDoc[`${ctrlType}Id`]}
                      className="mr-2"
                      onClick={() => handleOpenControlDocs(ctrlType, ctrlDoc)}
                      variant="body2"
                    >
                      {ctrlDoc[`${ctrlType}Name`]}
                    </Link>
                  ))
                }
              </React.Fragment>
            ))
          }
        </div>
      }
      {
        [FIELD_KEYS.Overview, FIELD_KEYS.Approvers].includes(fieldsType) &&
          selectedItem[fieldsType].map(item => (
            <RenderField
              key={item.field}
              fieldsType={fieldsType}
              item={item}
              docsConfig={docsConfig}
              docs={docs}
              docType={docType}
              selectedFieldItems={selectedFieldItems}
              setSelectedFieldItems={setSelectedFieldItems}
              items={items}
              selectedItem={selectedItem}
              handleFieldsSave={handleFieldsSave}
              handleFieldsCancel={handleFieldsCancel}
              setDisabled={setDisabled}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              setPendingReviewers={setPendingReviewers}
              pendingReviewers={pendingReviewers}
              access={disabled}
              users={users}
              roles={roles}
            />
          ))
      }
    </>
  )
};

export default RenderTabContent;

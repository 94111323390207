import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Table, Box, SvgIcon } from '@mui/material';
import {
  AdjustTwoTone as AdjustTwoToneIcon,
  DoneTwoTone as DoneTwoToneIcon,
  CloseTwoTone as CloseTwoToneIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import { ReactComponent as OwnerIcon } from '../../../assets/images/icons/owner.svg'
import { ReactComponent as ApproversIcon } from '../../../assets/images/icons/approvers.svg'
import { ReactComponent as SponsorsIcon } from '../../../assets/images/icons/sponsors.svg'
import { ReactComponent as LeadersIcon } from '../../../assets/images/icons/leaders.svg'
import { ReactComponent as SignApproveIcon } from '../../../assets/images/icons/sign-approve.svg'
import { ReactComponent as SignDeclineIcon } from '../../../assets/images/icons/sign-decline.svg'
import { classes, createStyles } from './styles';
import {
  USER_TYPES,
  USER_TYPES_TITLES,
  STATUS_APPROVED,
  STATUS_PENDING,
  STATUS_DECLINED,
  STATUS_NOT_APPROVED,
  TYPE_APPROVVERS,
  TYPE_SPONSORS,
  TYPE_LEADS,
  FIELD_KEYS,
} from './../../../constants';
import { usePendingReviewers } from '../../../hooks';
import { getFieldParams } from "../../../utils/common";

const Root = styled(Box)(createStyles);

const prepareUsers = ({ step, reviewers }) => {
  const userChunks = reviewers
    .filter(({ gate }) => gate === step)
    .sort((reviewerA, reviewerB) =>
      (reviewerA.position - reviewerB.position)
      // (new Date(reviewerA.createdAt) - new Date(reviewerB.createdAt))
    )
    .reduce((result, reviewer) => {
      const chunkIndex = result.findIndex(
        users => !users.some(({ type }) => type === reviewer.type) && users.length < USER_TYPES.length
      );

      if (!Array.isArray(result[chunkIndex])) return [...result, [reviewer]];

      const nextResult = result.filter((_, index) => index !== chunkIndex);
      nextResult.splice(chunkIndex, 0,  [...result[chunkIndex], reviewer]);

      return nextResult;
    }, []);

  return userChunks;
};

const getSignIcon = (reviewer) => {
  const { Icon } = [
    {
      condition: reviewer?.status === STATUS_APPROVED,
      Icon: SignApproveIcon
    },
    {
      condition: reviewer?.status === STATUS_DECLINED || reviewer?.status === STATUS_NOT_APPROVED,
      Icon: SignDeclineIcon
    },
    {
      condition: true, //reviewer.status === STATUS_PENDING,
      Icon: SignApproveIcon
    }
  ].find(({ condition }) => condition);

  return Icon
}

export default (props) => {
  const {
    reviewers = [],
    activeStep = 1,
    selectedFieldItems
  } = props;

  const [owner, setOwner] = useState({});

  const pendingReviewers = usePendingReviewers(reviewers, activeStep);
  const chunksReviewers = prepareUsers({ step: activeStep, reviewers });

  useEffect(() => {
    const { Owner } = selectedFieldItems?.[FIELD_KEYS.Fields] || {};
    const owner = reviewers.find(({ id }) => Owner === id) || {};
    setOwner(owner);

  }, [reviewers]);

  return (
    <Root className={`${classes.root} table-responsive-md`}>
      <Box
        className={classes.owner}
      >
        <span
          style={{
            padding: '3px 4px'
          }}
        >
          <SvgIcon
            component={OwnerIcon}
            viewBox="0 0 16 18"
            sx={{
              width: '16px',
              height: '18px',
            }}
          />
        </span>
        <span className={'header'}>
          Owner
        </span>
        <span>
          <a href={`mailto:${owner.email}`}>{owner.Name} {owner.LastName}</a>
        </span>
      </Box>
      <Box
        sx={{
          paddingTop: '30px',
          width: '100%'
        }}
      >
        <Table className={`${classes.table} table table-borderless`}>
          <thead>
          <tr>
            {
              USER_TYPES.map(userType => {
                const { Icon } = [
                  {
                    condition: userType === TYPE_APPROVVERS,
                    Icon: ApproversIcon
                  },
                  {
                    condition: userType === TYPE_SPONSORS,
                    Icon: SponsorsIcon
                  },
                  {
                    condition: userType === TYPE_LEADS,
                    Icon: LeadersIcon
                  },
                ].find(({ condition }) => condition);

                return (
                  <th
                    width={`${100/USER_TYPES.length}%`}
                    key={userType} className="text-center"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '5px',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '5px',
                      }}
                    >
                      <SvgIcon
                        component={Icon}
                        viewBox="0 0 25 24"
                        sx={{
                          width: '25px',
                          height: '24px',
                        }}
                      />
                      <span>
                        { USER_TYPES_TITLES[userType] }
                      </span>
                    </Box>
                  </th>
                )
              })
            }
          </tr>
          </thead>
          <tbody>
          {
            chunksReviewers.map((chunkUsers, index) => (
              <tr key={index}>
                {
                  USER_TYPES.map(userType => {
                    const reviewer = chunkUsers.find(({ type }) => type === userType);
                    const Icon = getSignIcon(reviewer);

                    return (
                      <td key={userType} className="text-center">
                        {
                          reviewer && (
                            <Box
                              sx={{
                                display: 'flex',
                                padding: '5px',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '5px',
                              }}
                            >
                              <span
                                title={reviewer.status}
                                className={clsx({
                                  [classes.approved]: reviewer.status === STATUS_APPROVED,
                                  [classes.pending]: reviewer.status === STATUS_PENDING,
                                  [classes.declined]: reviewer.status === STATUS_DECLINED || reviewer.status === STATUS_NOT_APPROVED,
                                  [classes.pendingActive]: pendingReviewers.map(({ email }) => email).includes(reviewer.email)
                                })}
                              >
                                <SvgIcon
                                  component={Icon}
                                  viewBox="0 0 25 24"
                                  sx={{
                                    width: '25px',
                                    height: '24px',
                                  }}
                                />
                              </span>
                              <span>
                                <a href={`mailto:${reviewer.email}`}>{reviewer.Name} {reviewer.LastName}</a>
                              </span>
                            </Box>
                          )
                        }
                      </td>
                    )
                  })
                }
              </tr>
            ))
          }
          </tbody>
        </Table>
      </Box>
    </Root>
  );
}

import React, { useCallback, useState } from 'react';
import { Button, Dialog, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RenderField } from '../index';
import { DISPLAY_CONTROLLERS, TITLES } from '../../../constants';

const ModalRemove = props  => {
  const [selectedFieldItems, setSelectedFieldItems] = useState({ [TITLES.DELETE]: '' });
  const {
    openModal,
    toggleModal,
    handleDelete,
    title,
    subTitle,
    confirmWord = TITLES.DELETE,
    cancelTitle = 'Cancel',
    deleteTitle = 'Delete'
  } = props;

  const handleClose = useCallback(() => {
    toggleModal();
    setSelectedFieldItems({ [TITLES.DELETE]: '' });
  }, [toggleModal])
  const handleChange = useCallback(event => {
    setSelectedFieldItems({ [TITLES.DELETE]: event.target.value });
  }, []);

  return (
    <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg' }}>
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
            <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3"/>
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">{title}</h4>
        <p className="mb-0 font-size-lg text-muted">{subTitle}</p>
        <p className="mb-0 mt-2 font-size-lg">To confirm deletion, enter the name of the document - <br /> "{confirmWord}" in the text input field.</p>
        <RenderField
          placeholder={confirmWord}
          item={{
            field: TITLES.DELETE,
            displayController: DISPLAY_CONTROLLERS.Textbox
          }}
          selectedFieldItems={selectedFieldItems}
          userHandleChange={handleChange}
        />
        <div className="pt-4">
          <Button
            variant='outlined'
            className='mx-1'
            onClick={handleClose}
          >
            <span className="btn-wrapper--label"> { cancelTitle } </span>
          </Button>
          <Button
            variant='outlined'
            disabled={selectedFieldItems[TITLES.DELETE] !== confirmWord}
            onClick={() => {
              handleDelete();
              handleClose();
            }}
            className='mx-1'
          >
            <span className="btn-wrapper--label"> { deleteTitle } </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalRemove;

import { stringify } from 'querystring';
import { getAxios } from './../../utils/axios';

const teamsURL = '/teams';

export const getTeams = (query) =>
  getAxios().get(`${teamsURL}?${stringify({ ...query, 'CreatedAt[$ne]': Date.now() })}`);

export const getTeam = (id) =>
  getAxios().get(`${teamsURL}/${id}`);

export const createTeam = (data) =>
  getAxios().post(teamsURL, data);

export const updateTeam = (id, data) =>
  getAxios().put(`${teamsURL}/${id}`, data);

export const removeTeam = (id) =>
  getAxios().delete(`${teamsURL}/${id}`);

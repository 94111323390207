import React from 'react';
import { Skeleton, Typography } from '@mui/material';

export default ({ loading = false } = {}) => {

  return !loading
    ? (<></>)
    : (
      <>
        <Typography component="div" variant={'body1'}>
          <Skeleton/>
        </Typography>
        <Typography component="div" variant={'h3'}>
          <Skeleton/>
        </Typography>
        <Typography component="div" variant={'h1'}>
          <Skeleton/>
        </Typography>
      </>
    )
}

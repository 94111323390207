const prefix = 'StepContent';

const classes = {
  root: `${prefix}-root`,
  table: `${prefix}-table`,
  approved: `${prefix}-approved`,
  pending: `${prefix}-pending`,
  pendingActive: `${prefix}-pendingActive`,
  declined: `${prefix}-declined`,
  owner: `${prefix}-owner`,
};

const createStyles = ({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    background: theme.palette.grey.lightLight,
    borderTop: `1px solid ${theme.palette.grey.light}`,
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '6px',
    // width: '853px',
    padding: '25px 50px',
    marginTop: '42px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '10px',
  },
  [`& .${classes.table}`]: {
    margin: '0px',
    '& th,td': {
      color: 'inherit',
      border: '0px !important',
      padding: '5px 5px 10px 5px',
    }
  },
  [`& .${classes.approved}`]: {
    color: theme.palette.success.main
  },
  [`& .${classes.pending}`]: {
    color: theme.palette.grey.light
  },
  [`& .${classes.pendingActive}`]: {
    color: theme.palette.grey.light
  },
  [`& .${classes.declined}`]: {
    color: theme.palette.red.mid
  },
  [`& .${classes.owner}`]: {
    display: 'flex',
    padding: '5px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '5px',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '6px',
    '& .header': {
      fontWeight: '700',
      fontSize: '14px',
    },
    paddingBottom: '20px',
  },
});

export {
  createStyles,
  classes
};

import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { classes, createStyles } from './styles';
import { RenderField } from '../';
import { DISPLAY_CONTROLLERS, ROLE_IUSER, STATUS_ACTIVE, STATUS_NOT_APPROVED } from '../../../constants';
import { AuthService } from '../../../services';
import { hasUserRole } from './../../../utils/common';

const Root = styled('div')(createStyles);

export const DocStatus = (props = {}) => {
  const {
    item,
    setSelectedFieldItems,
    selectedFieldItems,
    fieldsType,
    roles,
    handleChange
  } = props;

  const [hasIUserRole, setIUserRole] = useState(false);
  const [status, setStatus] = useState('')

  useEffect(() => {
    const authUser = AuthService.getUser();
    const hasIUserRole = hasUserRole(authUser, roles, ROLE_IUSER);
    setIUserRole(hasIUserRole);
  }, []);

  useEffect(() => {

    setStatus(selectedFieldItems?.[fieldsType]?.[item.field])
  }, [selectedFieldItems]);

  const mock = [
    {
      Value: 'Not Approved',
      Label: 'Not Approved',
    },
    {
      Value: "Active",
      Label: "Active",
    },
    {
      Value: "Deferred",
      Label: "Deferred",
    },
    {
      Value: "Abandoned",
      Label: "Abandoned",
    }
  ];

  return (
    <>
      { status &&
        <Root className={classes.root}>
          <RenderField
            item={{
              ...item,
              source: (item.source || []).length ? item.source : mock,
              displayController: DISPLAY_CONTROLLERS.Dropdown,
              value: item.value || STATUS_ACTIVE
            }}
            handleChange={handleChange}
            setSelectedFieldItems={setSelectedFieldItems}
            selectedFieldItems={selectedFieldItems}
            fieldsType={fieldsType}
            access={hasIUserRole}
            notShowDefaultOption={true}
            error={hasIUserRole && status === STATUS_NOT_APPROVED}
          />
        </Root>
      }
    </>
  );
};

export default DocStatus;

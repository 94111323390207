import { useState, useEffect } from 'react';
import {
  BDOC_TYPE,
  FIELD_KEYS,
  KDOC_TYPE,
  PDOC_TYPE, PRDOC_TYPE,
  QDOC_TYPE,
  QSDOC_TYPE,
  STATUS_APPROVED,
  TDOC_TYPE
} from './../constants';
import { getItemsByCond } from './../utils/common';

const useDocProgress = (doc) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const {
      calculate = () => 0
    } = [
      {
        condition: [KDOC_TYPE, PDOC_TYPE].includes(doc?.type),
        calculate: () => undefined
      },
      {
        condition: [QDOC_TYPE, PRDOC_TYPE].includes(doc?.type),
        calculate: () => {
          const countCompleted = (doc.children || []).filter((item) => {
            const completed = (item?.[FIELD_KEYS.Fields] || [])
              .find(({ field }) => field === `Completed`)?.value;

            return completed;
          }).length;

          return (doc.children || []).length
            ? Math.round(countCompleted/doc.children.length*100)
            : 0
          ;
        }
      },
      {
        condition: [QSDOC_TYPE, TDOC_TYPE].includes(doc?.type),
        calculate: () => {
          console.log('[calculate] L19 doc?.[FIELD_KEYS.Fields] ', doc);
          const completed = (doc?.[FIELD_KEYS.Fields] || [])
            .find(({ field }) => field === `Completed`)?.value;

          return completed ? 100 : 0;
        }
      },
      {
        condition: !doc?.[FIELD_KEYS.Overview] && Array.isArray(doc?.children),
        calculate: () => {
          const relationChildItems = getItemsByCond(
            doc.children || [],
            { Tab: FIELD_KEYS.Overview }
          );
          const currentReviewers = ((doc?.[FIELD_KEYS.Overview] || [])
            .find(({ field }) => field === `${doc.type}Reviewers`)?.value || []);

          const allReviewers = relationChildItems.reduce((result, item) => {
            const chunkReviewers =
              ((item?.[FIELD_KEYS.Overview] || [])
                .find(({ field }) => field === `${item.type}Reviewers`)?.value || []);

            return [...result, ...chunkReviewers];
          }, [...currentReviewers]);
          const approvedReviewers = allReviewers
            .filter(({ gate, status }) => status === STATUS_APPROVED)

          const progress = approvedReviewers.length
            ? Math.round(approvedReviewers.length / allReviewers.length * 100)
            : 0
          ;

          return progress;
        }
      },
      {
        condition: doc?.type === BDOC_TYPE,
        calculate: () => {
          const plannedValues = (doc?.[FIELD_KEYS.ConsumedValue] || [])
            .find(({ field }) => field === `${BDOC_TYPE}GridValues`)?.value || {};
          const estimatedValues = (doc?.[FIELD_KEYS.ConsumedValue] || [])
            .find(({ field }) => field === `${BDOC_TYPE}MonthlyGrid`)?.value || {};

          const totalPlanned = Object.keys(plannedValues).reduce((nextEstimate, year) => {
            const sum = plannedValues[year].reduce((nextSum, value) =>
              (nextSum + value), 0);

            return (nextEstimate + sum);
          }, 0);
          const totalEstimated = Object.keys(estimatedValues).reduce((nextEstimate, year) => {
            const sum = estimatedValues[year].reduce((nextSum, value) =>
              (nextSum + value), 0);

            return (nextEstimate + sum);
          }, 0);

          return totalPlanned
            ? Math.round(totalEstimated/totalPlanned*100)
            : 0
          ;
        }
      }
    ].find(({ condition }) => condition) || {};

    setProgress(calculate());
  }, [doc]);

  return progress;
};
export default useDocProgress;

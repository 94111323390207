import * as clients from './clients';
import * as settings from './settings';
import * as docs from './docs';
import * as accessManager from './access-manager';
import * as teams from './teams';

export default {
  ...clients,
  ...settings,
  ...docs,
  ...accessManager,
  ...teams
}

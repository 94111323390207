import React from 'react';
import clsx from 'clsx';
import { AdjustTwoTone as AdjustTwoToneIcon, DoneTwoTone as DoneTwoToneIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { classes, createStyles } from './styles';
import { FORBIDDEN_STATUSES, STATUS_NOT_APPROVED } from '../../../constants';

const Root = styled('div')(createStyles);

export default (props) => {
  const {
    step,
    active,
    completed,
    isOpenGateRequests,
    authUser,
    childDocsApproved,
    pendingReviewers = [],
    docStatus,
    isActiveCard
  } = props;

  const { element } = [
    {
      condition: completed,
      element: <DoneTwoToneIcon fontSize="large" />
    },
    {
      condition: childDocsApproved &&
        !completed && active &&
        (pendingReviewers.map(({ id }) => id).includes(authUser.UserId)) &&
        !FORBIDDEN_STATUSES.includes(docStatus),
      element: <span className="text-center font-weight-bold">Approve?</span>
    },
    {
      condition: true,
      element: !isOpenGateRequests
        ? <AdjustTwoToneIcon fontSize="large" />
        : <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={`Click the button to start approval for Gate ${step}`}
          placement="top-start"
          arrow={true}
        >
          <AdjustTwoToneIcon fontSize="large" />
        </Tooltip>
    }
  ].find(({ condition }) => condition);

  return (
    <Root
      isactivecard={isActiveCard || undefined}
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
        [classes.openGateRequests]: isOpenGateRequests
      })}
    >
      {
        element
      }
    </Root>
  );
}

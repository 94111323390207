import React, { useState, useEffect } from 'react';
import { validateInputRE, prepareCurrency, currencyFormatter } from './utils';

const multipliers = {
  k: 1000,
  m: 1000000,
  b: 1000000000
};

const CurrencyField = React.forwardRef((props, inputRef) => {
  const {
    value,
    onChange = _ => _,
    onBlur = _ => _,
    min = 0,
    max = 99999999999,
    ...other
  } = props;

  const [originValue, setOriginValue] = useState(value);
  const [localValue, setValue] = useState(currencyFormatter(value));

  useEffect(() => {
    if (value !== originValue) {
      setOriginValue(value);
      setValue(currencyFormatter(value));
    }

    if (!String(value)) setValue('');
  }, [value, originValue]);

  const handleChange = (e) => {
    const value = e?.target?.value || '';
    const inputValue = !isNaN(value) ? String(value) : value;

    if (!!inputValue && !validateInputRE.test(inputValue)) {
      return e.preventDefault();
    }

    const [, val = '', rate = ''] = inputValue.match(validateInputRE) || [];

    const nextCleanValue = rate && !val ? 1 : parseFloat(prepareCurrency(val));

    const nextValue = !isNaN(nextCleanValue)
      ? nextCleanValue * (rate ? multipliers[rate.toLowerCase()] : 1)
      : '';

    if (nextValue > max) return setValue(localValue);
    if (nextValue < min) return setValue(currencyFormatter(min));

    setValue(currencyFormatter(prepareCurrency(inputValue)));
    setOriginValue(nextValue);

    onChange({
      ...e,
      target: {
        ...e.target,
        value: nextValue
      }
    });
  };

  const handleBlur = (e) => {
    const inputValue = e?.target?.value || '';

    const [, val = '', rate = ''] = inputValue.match(validateInputRE) || [];

    const cleanValue = rate && !val ? 1 : parseFloat(prepareCurrency(val));

    if (!inputValue || isNaN(cleanValue)) {
      return onBlur({
        ...e,
        target: {
          ...e.target,
          value: ''
        }
      });
    }

    const nextValue = cleanValue * (rate ? multipliers[rate.toLowerCase()] : 1);

    if (nextValue < min) return setValue(localValue);

    setValue(currencyFormatter(nextValue));
    setOriginValue(nextValue);

    onBlur({
      ...e,
      target: {
        ...e.target,
        value: nextValue
      }
    });
  };

  return (
    <input
      type='text'
      {...other}
      ref={inputRef}
      value={localValue}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});

export default CurrencyField;

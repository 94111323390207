import React from 'react';
import { Box } from '@mui/material';

export const LoginWrapper = (props) => {
  const {
    children,
    Bottom
  } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(117deg, #226555 -0.53%, #41806F 34.19%, #216454 93.99%)'
      }}
    >
      <Box
        sx={{
          padding: '50px 35px',
          display: 'grid',
          gridTemplateColumns: `repeat(1, 1fr)`,
          gridTemplateRows: 'auto',
          alignContent: 'center',
          justifyContent: 'center',
          gap: '10px',
          borderRadius: '10px',
          background: '#fff',
          boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
        }}
      >
        { children }
      </Box>
      {
        !Bottom && (
          <>
            <Box
              sx={{
                marginTop: '131px',
                color: '#fff',
                fontSize: '32px',
                fontWeight: 700,
                letterSpacing: '10px',
              }}
            >
              Connecting Innovation with Performance
            </Box>
            <Box
              sx={{
                margin: '20px',
                '& a': {
                  color: '#fff !important',
                }
              }}
            >
              <a href="/privacy-policy"> Privacy Policy </a>
            </Box>
          </>
        )
      }
    </Box>
  )
};

export default LoginWrapper;

const classes = {
  root: 'DocStatus-root'
};

const createStyles = () => {
  return ({
    [`&.${classes.root}`]: {
      width: '100%',
      marginBottom: '20px'
    }
  })
};

export {
  createStyles,
  classes
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@mui/material';

import { ReactComponent as SearchIcon } from '../../../assets/images/icons/search.svg';
import { RenderField } from '../../controls';
import { DISPLAY_CONTROLLERS } from '../../../constants';

const HeaderSearch = (props) => {
  const { setSearch, search } = props;

  return (
    <>
      <RenderField
        mb={false}
        classes={`tree-search`}
        placeholder={'Search'}
        item={{
          field: 'search',
          displayController: DISPLAY_CONTROLLERS.Textbox,
        }}
        selectedFieldItems={{ search }}
        userHandleChange={(e) => {
          setSearch(e.target.value || '')
        }}
        styles={{
          display: 'flex',
          alignItems: 'center',
          alignSelf: 'stretch',
          padding: '2px 16px 2px 0px',
        }}
        InputProps={{
          type: 'search',
          startAdornment: (
            <label
              htmlFor="header-search-input"
              style={{
                padding: '8px',
                gap: '10px',
              }}
            >
              <SvgIcon
                // htmlColor={mapDocIcons[docType]?.color}
                component={SearchIcon}
              />
            </label>
          )
        }}
      />
    </>
  );
};


export default HeaderSearch;

import React, { lazy, Suspense, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@mui/material/styles';

import MuiTheme from './theme';
import { AuthService, Request } from './services';
import {
  MENU_ITEM_TYPES,
} from './constants';
import { LeftSidebar, MinimalLayout } from './components/layout-blueprints';
import { PageTitle } from './components/layout-components';
import { ProtectedRoute, SuspenseLoading } from './components/controls'
import Login from './components/pages/Login';
import Privacy from './components/pages/Privacy';
import Terms from './components/pages/Terms';
import { createDispatches } from './reducers/functions';
const PageRegisterOverlay = lazy(() => import('./components/pages/RegisterOverlay'));
const PageRecoverPassword = lazy(() => import('./components/pages/RecoverPassword'));
const PageError404 = lazy(() => import('./components/pages/Error404'));
const PageDocCreate = lazy(() => import('./components/pages/DocCreate'));
const ApiKey = lazy(() => import('./components/pages/ApiKey'));
const PageDocDetails = lazy(() => import('./components/pages/DocDetails'));
const PageControlDocDetails = lazy(() => import('./components/pages/ControlDocsModify'));
const PageResetPassword = lazy(() => import('./components/pages/ResetPassword'));
const PageVerificationCode = lazy(() => import('./components/pages/VerificationCode'));
const DebugControls = lazy(() => import('./components/pages/DebugControls'));
const Profile = lazy(() => import('./components/pages/Profile'));

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.3
};

const RenderComponent = ({ Component, props }) => {
  const { menuProps: { label } = {} } = props;
  const { setPageButtonsProps, setPageTitle, setPageFormErros } = createDispatches(useDispatch());

  useEffect(() => {
    setPageTitle(label);
    setPageButtonsProps({});
    setPageFormErros([]);
  }, []);

  return <Component {...props} />;
};

const prepareRouteSchema = (menus) => {
  const getMenuPaths = (result, item,) => {
    return item.Type === MENU_ITEM_TYPES.link
      ? [
          ...result,
          {
            key: item.Id,
            path: item.Path,
            component: lazy(
              () => import(`./components/pages/${item.Id}`)
                .catch((error) => ({
                  default: () =>
                    <PageTitle
                      titleHeading={item.Label}
                      titleDescription="Page under development"
                    />
                }))
            ),
            label: item.Label
          }
      ]
      : [...result, ...(item.Items || []).reduce((result, childItem) => getMenuPaths(result, childItem), [])];
  };

  const components = menus.reduce((result, { Items }) => {
    const existsPaths = result.map(({ path }) => path);
    const nextItems = Items.filter(item => !existsPaths.includes(item.Path));

    return [
      ...result,
      ...nextItems.reduce(getMenuPaths, [])
    ]
  }, []);

  return components;
};

const Routes = () => {
  const { setNavMenu, setPageTitle, setRefreshModal } = createDispatches(useDispatch());
  const [menu, setMenu] = useState([]);

  const [prepareRoutes, setPrepareRoutes] = useState([]);
  const location = useLocation();

  const isAuthenticated = AuthService.isAuthenticated();
  useEffect(() => {
    if (!isAuthenticated) return ;

    Request.getMenus().then(menu => {
      const routes = prepareRouteSchema(menu);
      setPrepareRoutes(routes);
      setNavMenu(menu);
      setMenu(menu);
    });
  }, [isAuthenticated,]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route
              path={[
                '/privacy-policy',
                '/terms',
                '/login',
                '/signup',
                '/recover-password',
                '/reset-password',
                '/verification-code',
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      exact
                      path="/privacy-policy"
                      component={Privacy}
                    />
                    <Route
                      exact
                      path="/terms"
                      component={Terms}
                    />
                    <Route
                      exact
                      path="/login"
                      component={Login}
                    />
                    <Route
                      exact
                      path="/signup"
                      component={PageRegisterOverlay}
                    />
                    <Route
                      exact
                      path="/recover-password"
                      component={PageRecoverPassword}
                    />
                    <Route
                      exact
                      path="/reset-password"
                      component={PageResetPassword}
                    />
                    <Route
                      exact
                      path="/verification-code"
                      component={PageVerificationCode}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            <ProtectedRoute
              location={location}
              exact
              path={[
                ...prepareRoutes.map(({ path }) => path),
                '/docs/:docType/:docId',
                '/doc-create/:docType',
                '/doc-create/:docType/:parentDocId',
                '/doc-create',
                '/control-docs/:docType/:docId',
                '/debug-controls',
                '/profile',
              ]}
            >
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    {
                      prepareRoutes.map(({ path, component: Component, key, ...otherProps }) => (
                        <Route
                          exact
                          key={key}
                          path={path}
                          component={
                            props => RenderComponent({
                              Component,
                              props: { ...props, menuProps: otherProps }
                            })
                          }
                        />
                      ))
                    }
                    <Route
                      exact
                      key="xdoc-create"
                      path="/doc-create/:docType"
                      component={PageDocCreate}
                    />
                    <Route
                      exact
                      key="xdoc-create-with-parent"
                      path="/doc-create/:docType/:parentDocId"
                      component={PageDocCreate}
                    />
                    <Route
                      exact
                      key="xdoc-create-idea-library"
                      path="/doc-create"
                      component={PageDocCreate}
                    />
                    <Route
                      exact
                      key="xdoc-details"
                      path="/docs/:docType/:docId"
                      component={PageDocDetails}
                    />
                    <Route
                      exact
                      key="ctrl-docs-details"
                      path="/control-docs/:docType/:docId"
                      component={PageControlDocDetails}
                    />
                    <Route
                      exact
                      key="debug-controls"
                      path="/debug-controls"
                      component={DebugControls}
                    />
                    <Route
                      exact
                      key="profile"
                      path="/profile"
                      component={Profile}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </ProtectedRoute>
            <ProtectedRoute>
              <LeftSidebar>
                {
                  menu.length
                    ? <Route component={PageError404} />
                    : <Route component={SuspenseLoading} />
                }
              </LeftSidebar>
            </ProtectedRoute>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;

import React, { useEffect, useState, useCallback } from 'react';
import { Autocomplete } from '@mui/material';
import { Chip, IconButton, Table, TextField } from '@mui/material';
import {
  Close as CloseIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import {
  DISPLAY_CONTROLLERS,
  ENDPOINTS,
  FIELD_KEYS,
  METHOD_POST,
  METHOD_PUT,
  STATUS_PENDING,
  USER_TYPES,
  TYPE_SPONSORS,
  STATUS_APPROVED,
  USER_TYPES_TITLES,
  TYPE_APPROVVERS,
  STATUS_DECLINED,
  ROLE_LEADERSHIP
} from './../../../constants';
import { formStyles } from './../../../assets/styles';
import { checkAccess, filterUsersByRole } from './../../../utils/common';
import { AuthService, Request } from './../../../services';
import { RenderField } from './../';

export default (props) => {
  const {
    setSelectedFieldItems,
    selectedFieldItems,
    docType,
    fieldsType,
    selectedItem,
    disabled,
    reviewers = [],
    gates,
    roles = []
  } = props;
  const [users, setUsers] = useState([]);

  const { classes, createStyles } = formStyles;
  const StyledTextField = styled(TextField)(createStyles);

  useEffect(() => {
    const authUser = AuthService.getUser();
    Request.getUsers({ OrganizationId: authUser.OrganizationId }).then(setUsers);
  }, []);

  useEffect(() => {
    const sponsorId = selectedFieldItems?.[FIELD_KEYS.Fields]?.[`${docType}SponsorId`];

    const { getReviewers } = [
      {
        condition: Reflect.has(selectedFieldItems?.[FIELD_KEYS.Fields] || {}, `${docType}SponsorId`) &&
          !sponsorId && reviewers.some(({ type }) => type === TYPE_SPONSORS),
        getReviewers: () => reviewers.filter(({ type }) => type !== TYPE_SPONSORS)
      },
      {
        condition: sponsorId &&
          !reviewers.some(({ id, type }) => id === sponsorId && type === TYPE_SPONSORS) &&
          users.some(({ UserId }) => UserId === sponsorId),
        getReviewers: () => {
          const sponsor = users.find(({ UserId }) => UserId === sponsorId);

          return [
            ...reviewers.filter(({ type, email }) => type !== TYPE_SPONSORS && email !== sponsor.Email),
            ...gates.filter((_, i) => i !== 0).map(({ Value: gate }) => ({
              id: sponsor.UserId,
              Name: sponsor.FirstName,
              LastName: sponsor.LastName,
              email: sponsor.Email,
              gate,
              type: TYPE_SPONSORS,
              status: STATUS_PENDING,
              createdAt : new Date(),
              updatedAt : new Date()
            }))
          ]
        }
      }
    ].find(({ condition }) => condition) || {};

    if (typeof getReviewers !== 'function') return;

    const nextReviewers = getReviewers();
    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_KEYS.Overview]: {
        ...selectedFieldItems[FIELD_KEYS.Overview],
        [`${selectedItem.type}Reviewers`]: nextReviewers
      },
      [fieldsType]: {
        ...selectedFieldItems[fieldsType],
        [`${selectedItem.type}Reviewers`]: nextReviewers
      }
    });
  }, [selectedFieldItems?.[FIELD_KEYS.Fields]?.[`${docType}SponsorId`], users, reviewers, gates, docType]);

  const isDisabledGate = useCallback((gate) =>
    (selectedFieldItems?.[FIELD_KEYS.Overview]?.[`${docType}OpenGateRequests`] || [])
      .some((openRequest) => openRequest.gate === gate)
  ,[JSON.stringify(selectedFieldItems?.[FIELD_KEYS.Overview]?.[`${selectedItem.type}OpenGateRequests`] || [])]);

  const isAllowRemove = useCallback((email) =>
    reviewers.some(
      reviewer => reviewer.email === email && ![STATUS_APPROVED, STATUS_DECLINED].includes(reviewer.status)
    )
  ,[JSON.stringify(reviewers)]);

  // console.log('[Approvers] reviewers: ', reviewers);

  return (
    <div className="table-responsive-md">
      {
        USER_TYPES
          .filter(userType => userType !== TYPE_SPONSORS)
          .map(userType => {
            const shownReviewers = reviewers
              .map((reviewer, index) => ({
                ...reviewer,
                position: isNaN(reviewer.position) ? index : reviewer.position
              }))
              .filter(reviewer => reviewer.type === userType)
              .reduce((result, reviewer) => {
                const existReviewer = result.find(({ email }) => email === reviewer.email) || {};
                const nextReviewer = {
                  ...reviewer,
                  gates: [
                    ...(existReviewer?.gates || []),
                    reviewer.gate
                  ]
                };
                return [...result.filter(({ email }) => email !== reviewer.email), nextReviewer];
              }, [])
              .sort((reviewerA, reviewerB) =>
                  (reviewerA.position - reviewerB.position)
                // (new Date(reviewerA.createdAt) - new Date(reviewerB.createdAt))
              );

            return (
              <div key={userType}>
                <div className="mb-3">
                  <h5 className="font-weight-bold">{USER_TYPES_TITLES[userType] || userType}</h5>
                  <Autocomplete
                    options={(() => {
                      const reviewersMails = reviewers.map(({ email }) => email);

                      const filteredUsers = userType === TYPE_APPROVVERS
                        ? users
                        : filterUsersByRole({ users, roles, roleName: ROLE_LEADERSHIP });

                      return filteredUsers
                        .filter(({ Email }) => !reviewersMails.includes(Email))
                        .map(({ FirstName, Email }) => `${FirstName} (${Email})`);
                    })()}
                    getOptionLabel={type => type}
                    onChange={(arg, value) => {
                      if (!value) return;

                      const email = value.split('(').pop().replace(/\)$/, '');

                      if (reviewers.some((reviewer) => reviewer.email === email)) return;

                      const reviewer = users.find(({ Email }) => Email === email);
                      const nextReviewers = [
                        ...reviewers,
                        {
                          id: reviewer.UserId,
                          Name: reviewer.FirstName,
                          LastName: reviewer.LastName,
                          email: reviewer.Email,
                          type: userType,
                          position: reviewers.length,
                          createdAt: new Date(),
                          updatedAt: new Date()
                        }
                      ];
                      setSelectedFieldItems({
                        ...selectedFieldItems,
                        [FIELD_KEYS.Overview]: {
                          ...selectedFieldItems[FIELD_KEYS.Overview],
                          [`${selectedItem.type}Reviewers`]: nextReviewers
                        },
                        [fieldsType]: {
                          ...selectedFieldItems[fieldsType],
                          [`${selectedItem.type}Reviewers`]: nextReviewers
                        }
                      });
                    }}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip label={option} {...getTagProps({ index })} />
                      ))
                    }}
                    renderInput={params => (
                      <StyledTextField
                        {...params}
                        className={classes.textbox}
                        label="Users"
                        variant="outlined"
                        placeholder="Add reviewer"
                      />
                    )}
                  />
                </div>
                <div className="divider" />
                <Table className="table table-borderless text-nowrap mb-0">
                  <thead>
                  <tr>
                    {
                      userType !== TYPE_APPROVVERS &&
                        <th className="text-left">#</th>
                    }
                    <th className="text-left">Name</th>
                    <th className="text-left">Email</th>
                    {
                      gates.filter((_, i) => i !== 0).map(({ Label }) => (
                        <th key={Label} className="text-left">{ Label }</th>
                      ))
                    }
                    <th />
                  </tr>
                  </thead>
                  <tbody>
                  {

                    shownReviewers.map((reviewer, index) => {
                        const width = 98 / (gates.length -1 + 3);

                        return (
                          <tr key={index}>
                            {
                              userType !== TYPE_APPROVVERS &&
                                <td className="text-left" width={'1%'}>{ index+1 }</td>
                            }
                            <td className="text-left" width={`${width}%`}>{ reviewer.Name }</td>
                            <td className="text-left" width={`${width}%`}>{ reviewer.email }</td>
                            {
                              gates
                                .filter((_, i) => i !== 0)
                                .map(({ Value: gate }, i) => (
                                  <td key={gate} className="text-left" width={`${width}%`}>
                                    <RenderField
                                      classes="mb-0"
                                      item={{
                                        label: '',
                                        field: `gates`,
                                        displayController: DISPLAY_CONTROLLERS.Checkbox,
                                        checkBoxId: gate
                                      }}
                                      access={
                                        !isDisabledGate(gate) &&
                                        checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_POST, METHOD_PUT] }) &&
                                        selectedItem?.notGrouped?.CreatedBy !== reviewer.id
                                      }
                                      selectedFieldItems={reviewer}
                                      userHandleChange={
                                        (event) => {
                                          const { gates, ...nextReviewer } = reviewer;
                                          const nextReviewers = [
                                            ...reviewers
                                              .filter((user) => !(user.email === reviewer.email && user.gate === gate && user.type === reviewer.type)),
                                            ...(event.target.checked
                                              ? [{ ...nextReviewer, gate, status: STATUS_PENDING, updatedAt: new Date() }]
                                              : [])
                                          ];

                                          setSelectedFieldItems({
                                            ...selectedFieldItems,
                                            [FIELD_KEYS.Overview]: {
                                              ...selectedFieldItems[FIELD_KEYS.Overview],
                                              [`${selectedItem.type}Reviewers`]: nextReviewers
                                            },
                                            [fieldsType]: {
                                              ...selectedFieldItems[fieldsType],
                                              [`${selectedItem.type}Reviewers`]: nextReviewers
                                            }
                                          });
                                        }
                                      }
                                    />
                                  </td>
                                ))
                            }
                            <td className="text-left" width={'5%'}>
                              {
                                !disabled && isAllowRemove(reviewer.email) && selectedItem?.notGrouped?.CreatedBy !== reviewer.id &&
                                  <IconButton
                                    className={'m-0 p-0'}
                                    onClick={() => {
                                      const nextReviewers = reviewers.filter(({ email }) => reviewer.email !== email);
                                      setSelectedFieldItems({
                                        ...selectedFieldItems,
                                        [FIELD_KEYS.Overview]: {
                                          ...selectedFieldItems[FIELD_KEYS.Overview],
                                          [`${selectedItem.type}Reviewers`]: nextReviewers
                                        },
                                        [fieldsType]: {
                                          ...selectedFieldItems[fieldsType],
                                          [`${selectedItem.type}Reviewers`]: nextReviewers
                                        }
                                      });
                                    }}
                                    size="large">
                                    <CloseIcon />
                                  </IconButton>
                              }
                              {
                                  <IconButton
                                    style={userType !== TYPE_APPROVVERS && !!index ? { } : {  opacity: 0 }}
                                    className={'m-0 p-0'}
                                    onClick={() => {
                                      if (!(userType !== TYPE_APPROVVERS && !!index)) return;

                                      const nextReviewers = [...reviewers.filter(({ email }) => reviewer.email !== email)];
                                      const nextPosition = reviewer.position-1;
                                      nextReviewers.splice(nextPosition, 0, { ...reviewer, position: nextPosition });

                                      const sortReviewers = nextReviewers.map((reviewer, position) => ({ ...reviewer, position }));

                                      setSelectedFieldItems({
                                        ...selectedFieldItems,
                                        [FIELD_KEYS.Overview]: {
                                          ...selectedFieldItems[FIELD_KEYS.Overview],
                                          [`${selectedItem.type}Reviewers`]: [...sortReviewers]
                                        },
                                        [fieldsType]: {
                                          ...selectedFieldItems[fieldsType],
                                          [`${selectedItem.type}Reviewers`]: [...sortReviewers]
                                        }
                                      });
                                    }}
                                    size="large">
                                    <ArrowUpwardIcon />
                                  </IconButton>
                              }
                              {
                                <IconButton
                                  style={userType !== TYPE_APPROVVERS && index < (shownReviewers.length-1) ? { } : {  opacity: 0 }}
                                  className={'m-0 p-0'}
                                  onClick={() => {
                                    if (!(userType !== TYPE_APPROVVERS && index < (shownReviewers.length-1))) return;

                                    const nextReviewers = [...reviewers.filter(({ email }) => reviewer.email !== email)];
                                    const nextPosition = reviewer.position+1;
                                    nextReviewers.splice(nextPosition, 0, { ...reviewer, position: nextPosition });

                                    const sortReviewers = nextReviewers.map((reviewer, position) => ({ ...reviewer, position }));

                                    setSelectedFieldItems({
                                      ...selectedFieldItems,
                                      [FIELD_KEYS.Overview]: {
                                        ...selectedFieldItems[FIELD_KEYS.Overview],
                                        [`${selectedItem.type}Reviewers`]: [...sortReviewers]
                                      },
                                      [fieldsType]: {
                                        ...selectedFieldItems[fieldsType],
                                        [`${selectedItem.type}Reviewers`]: [...sortReviewers]
                                      }
                                    });
                                  }}
                                  size="large">
                                  <ArrowDownwardIcon />
                                </IconButton>
                              }
                            </td>
                          </tr>
                        );
                      })
                  }
                  </tbody>
                </Table>
              </div>
            );
          })
      }
    </div>
  );
}

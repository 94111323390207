const prefix = 'ColorlibStepIcon';

const classes = {
  root: `${prefix}-root`,
  active: `${prefix}-active`,
  openGateRequests: `${prefix}-openGateRequests`,
  completed: `${prefix}-completed`,
  tooltip: `${prefix}-tooltip`
};

const createStyles = ({ theme, isactivecard: isActiveCard }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: isActiveCard ? 45 : 75,
    height: isActiveCard ? 45 : 75,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: isActiveCard ? 10 : 16,
  },
  [`&.${classes.active}`]: {
    backgroundColor: theme.palette.warning.main,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  [`&.${classes.openGateRequests}`]: {
    backgroundColor: '#00a2ff',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  [`&.${classes.completed}`]: {
    backgroundColor: theme.palette.success.main,
  },
  [`& .${classes.tooltip}`]: {
    top: '-15px',
    fontSize: '14px'
  }
});

export {
  createStyles,
  classes
};

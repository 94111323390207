import React, { useMemo, useState } from 'react';
import { LoadingButton, RenderField, ModalConfirm } from './../';
import { useDispatch, useSelector } from "react-redux";
import {Box} from "@mui/material";


import { checkAccess, getFieldParams } from "../../../utils/common";
import { createDispatches } from "../../../reducers/functions";
import {
  DISPLAY_CONTROLLERS, ENDPOINTS,
  FIELD_KEYS, METHOD_POST, METHOD_PUT, STATUS_CLOSED, STATUS_COMPLETED,
  STEP_ACTION_APPROVE,
  STEP_ACTION_INIT,
  TITLES,
  TYPE_LEADS,
  TYPE_SPONSORS
} from "../../../constants";
import { notApprove, prepareComment, resetGate } from "../Steps/functions";

const NavButtons = (props = {}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const {
    setValidateModal,
  } = createDispatches(useDispatch());
  const {
    pageButtonsProps,
    contentSaving,
    pageFormErrors
  } = useSelector(state => state.ThemeOptions);
  const isModifySelectedDoc =  useMemo(
    () =>
      checkAccess({
        path: pageButtonsProps?.selectedDoc?.path,
        methods: pageButtonsProps?.selectedDoc?.methods
      }),
    [
      pageButtonsProps?.selectedDoc?.path,
      pageButtonsProps?.selectedDoc?.methods
    ]
  );

  return (
    <>
      {
        pageButtonsProps?.isShowEditBtns && isModifySelectedDoc && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'flex-end',
              gap: '20px'
            }}
          >
            <LoadingButton
              variant='outlined'
              className="btn-reset"
              onClick={() => {
                setOpenConfirmModal(true)
              }}
              hide={contentSaving}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              variant='contained'
              className="btn-save"
              onClick={() => {
                if (pageFormErrors.length) {
                  console.log('pageFormErrors: ', pageFormErrors)
                  return setValidateModal(true);
                }

                return pageButtonsProps.handleFieldsSave();
              }}
              // className={`mr-2 ${contentSaving ? 'w-100px' : 'w-90px'}`}
              loading={contentSaving}
            >
              {contentSaving ? 'Saving...' : 'Save'}
            </LoadingButton>
            <ModalConfirm
              title={TITLES.AREYOUSURE}
              cancelTitle={''}
              confirmTitle={TITLES.YES}
              resetTitle={''}
              openModal={openConfirmModal}
              toggleModal={() => setOpenConfirmModal(!openConfirmModal)}
              handleConfirm={pageButtonsProps.handleFieldsCancel}
              cancelConfirm={() => {
                setOpenConfirmModal(false)
              }}
              onClose={() => {
                setOpenConfirmModal(false)
              }}
            />
          </Box>
        )
      }
    </>
  )
}

export default NavButtons;

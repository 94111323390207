import { stringify } from 'querystring';
import { getAxios } from './../../utils/axios';
import AuthService from './../Auth';

export const login = (data) =>
  getAxios().post('/sign-in', data);

export const relogin = () => login({ email: AuthService.getUser()?.Email, tokenRefresh: AuthService.getRefreshToken() });

export const signup = (data) =>
  getAxios().post('/profile', data);


export const recoverPassword = (data) =>
  getAxios().post('/recover-password', data);

export const getProfile = () =>
  Promise.resolve(AuthService.getUser());

export const getClients = (query) =>
  getAxios().get(`/pf-admin/clients?${stringify({ ...query, 'CreatedAt[$ne]': Date.now() })}`);

export const createClient = (data) =>
  getAxios().post(`/pf-admin/clients`, data);

export const updateClient = (id, data) =>
  getAxios().put(`/pf-admin/clients/${id}`, data);

export const removeClient = (id) =>
  getAxios().delete(`/pf-admin/clients/${id}`);

export const getXdocConfig = (id = '1.0.0') =>
  getAxios().get(`/pf-admin/xdoc-config/${id}`);

export const updateXdocConfig = (id = '1.0.0', data = {}) =>
  getAxios().put(`/pf-admin/xdoc-config/${id}`, data);

import React from 'react';
import clsx from 'clsx';
import { List, ListItem } from '@mui/material';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { BackToTopButton } from '../../controls';

const Footer = (props) => {
  const { footerShadow, footerBgTransparent } = props;

  return (<></>);
  // return (
  //   <div className="app-footer-fixed">
  //     <div
  //       className={clsx('app-footer text-black-50', {
  //         'app-footer--shadow': footerShadow,
  //         'app-footer--opacity-bg': footerBgTransparent
  //       })}>
  //       <div style={{ paddingLeft: '60px' }}>
  //         <div className="app-footer--second">
  //           <div
  //             className='bg-logo'
  //             style={{
  //               minHeight: '42px'
  //             }}
  //           />
  //           <span
  //             style={{
  //               textAlign: 'center',
  //               width: '200px',
  //               display: 'block'
  //             }}
  //           >
  //             Powered by Innometrix
  //           </span>
  //         </div>
  //         <BackToTopButton />
  //       </div>
  //     </div>
  //   </div>
  // );
};
const mapStateToProps = (state) => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default connect(mapStateToProps)(Footer);

import React from 'react';
import MaskedInput from 'react-text-mask';
import { composeMask } from './../../../utils/common';

const TextMaskField = React.forwardRef((props, inputRef) => {
  const { mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={composeMask(mask)}
      showMask
      guide={true}
    />
  );
});

export default TextMaskField;


import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as KeyboardDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Visibility, VisibilityOff, Check } from '@mui/icons-material';
import {
  Button,
  MenuItem,
  TextField,
  Input,
  Checkbox,
  ListItemText,
  Tooltip,
  Grid,
  FormControlLabel,
  InputAdornment,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { createDispatches } from './../../../reducers/functions';
import {
  DISPLAY_CONTROLLERS,
  INPUT_PROPS,
  ERRORS,
  PASSWORD_TYPES,
  DEFAULT_FORMAT_DATE,
  STATUS_ABANDONED
} from './../../../constants';
import { validatePassword, getFieldParams, getTabInfo } from './../../../utils/common';
import { getDate } from './../../../utils/date';
import { formStyles } from './../../../assets/styles';
import {
  TextMaskField,
  CurrencyField,
  Steps,
  StepContent,
  Approvers,
  ListMessages,
  DocStatus,
  RenderField,
  Help,
  ApprovalScheduler,
  Wysiwyg,
  Notes,
  PasswordChange,
  Logs,
  Tags,
  Gant,
  MfaType,
  Calculations,
  GridGroup,
  GridDetails,
  GridScenario
} from './../';

import { useDebounce } from './../../../hooks'

const { classes: formClasses, createStyles } = formStyles;
const RootGrid = styled(Grid)(createStyles);

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== '$isOverwrite'
})(createStyles);

const TooltipWithIcon = (props) => (
  <>
    <Tooltip placement="right-end" title={props.toolTip}>
      {/*<HelpIcon />*/}
      <span>{props.label}</span>
    </Tooltip>
  </>
);

const detectAutofill = (element) => {
  return new Promise(resolve => {
    setTimeout(() => {
      try {
        resolve(window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button')
      } catch (e) {
        resolve(false)
      }
    }, 600)
  })
};

export default ({
  item,
  fieldsType,
  selectedFieldItems = {},
  selectedItem,
  setSelectedFieldItems = () => {},
  toggleGridModal,
  handleGridChange,
  toggleGridGroupModal,
  docType,
  userHandleChange,
  classes = '',
  styles = {},
  variant = 'outlined',
  placeholder,
  isOverwrite = false,
  access = true,
  docsConfig,
  items,
  handleFieldsSave,
  handleFieldsCancel,
  setDisabled,
  setActiveStep,
  setPendingReviewers,
  pendingReviewers,
  activeStep,
  roles = [],
  docs,
  error,
  isIgnoredNoneOption = false,
  notShowDefaultOption,
  itemValue,
  validateAccessDocument,
  users = [],
  InputProps = {},
  group,
  gridOptions,
  mb = 'mb-3',
  kDocAggregationMethod,
}) => {
  const { setPageFormErros } = createDispatches(useDispatch());
  const pageFormErrors = useSelector(state => state.ThemeOptions.pageFormErrors) || [];

  const [field, setField] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const key = useMemo(
    () => fieldsType ? `${fieldsType} - ${item.field || item.label}` : (item.field || item.label),
    [fieldsType, item.field, item.label]
  );
  const value = useMemo(
    () => itemValue || (fieldsType ? selectedFieldItems?.[fieldsType]?.[item.field] : selectedFieldItems[item.field]),
    [itemValue, selectedFieldItems?.[fieldsType]?.[item.field], selectedFieldItems[item.field]]
  );

  const {
    DateViews = ["year", "month"],
    DateFormat = DEFAULT_FORMAT_DATE,
    Items: source = [],
    DefaultValue = 'Active'
  } = !!item?.source
    ? { ...item, Items: item.source }
    : getFieldParams(docsConfig?.[docType], fieldsType, item.field);

  const { NotDisabledFields = [] } = getTabInfo(docsConfig?.[docType], fieldsType) || {};
  const filteredAcces = !access ? ( NotDisabledFields.includes(item.field)) : access;

  const defaultHandleChange = (e, CheckBoxId) => {
    if (typeof validateAccessDocument === 'function') validateAccessDocument();

    const { composeValue = () => e?.target?.value } = [
      {
        condition: e instanceof Date,
        composeValue: () => getDate(e, DateFormat)
      },
      {
        condition: item.displayController === DISPLAY_CONTROLLERS.Checkbox,
        composeValue: () => e.target.checked
      },
      {
        condition: item.displayController === DISPLAY_CONTROLLERS.CheckBoxesList,
        composeValue: () => {
          const value = e.target.checked
            ? [...(selectedFieldItems?.[fieldsType]?.[item.field] || []), CheckBoxId]
            : (selectedFieldItems?.[fieldsType]?.[item.field] || []).filter((cId) => cId !== CheckBoxId)
          ;

          return value.length ? value : null;
        }
      }
    ].find(({ condition }) => condition) || {};
    setSelectedFieldItems({
      ...selectedFieldItems,
      [fieldsType]: {
        ...selectedFieldItems[fieldsType],
        [item.field]: composeValue()
      }
    });
  };
  const handleChange = (e, CheckBoxId) => {
    const {
      setValue = () => setField(e?.target?.value)
    } = [].find(({ condition }) => condition) || {};
    setValue();

    return !userHandleChange
      ? defaultHandleChange(e, CheckBoxId)
      : userHandleChange(e, CheckBoxId)
  };

  const {
    getError = () => false
  } = [
    {
      condition: item.required && ![PASSWORD_TYPES.password, PASSWORD_TYPES.Password].includes(key) && item.displayController !== DISPLAY_CONTROLLERS.CheckBoxesList,
      getError: () => (!value || typeof value === 'object' && Object.values(value).some(val => !val))
    },
    {
      condition: item.required && ![PASSWORD_TYPES.password, PASSWORD_TYPES.Password].includes(key) && item.displayController === DISPLAY_CONTROLLERS.CheckBoxesList,
      getError: () => !(
        fieldsType
          ? (selectedFieldItems?.[fieldsType]?.[item.field] || []).length
          : (selectedFieldItems?.[item.field] || []).length
      )
    }
  ].find(({ condition }) => condition) || {};
  const isError = getError();
  // const isError = key !== PASSWORD_TYPES.password
  //   ? !!item.required && (!value || typeof value === 'object' && Object.values(value).some(val => !val))
  //   : !validatePassword((value || {})[PASSWORD_TYPES.password] || '')

  useEffect(() => {
    setField(value);
  }, [value]);

  useEffect(() => {
    if (item.displayController === DISPLAY_CONTROLLERS.MultiLineTextbox && source.length) {
      source.forEach(subItem => {
        const errorLabel = subItem.key || subItem.Label;
        const bError = !(value || {})[subItem.Value];

        if (bError && !pageFormErrors.includes(errorLabel)) {
          setPageFormErros([ ...pageFormErrors, errorLabel ]);
        } else if (!bError && pageFormErrors.includes(errorLabel)) {
          setPageFormErros((pageFormErrors || []).filter(error => error !== errorLabel));
        }
      })
    } else {
      const errorLabel = item.key || item.label;
      if (isError && !pageFormErrors.includes(errorLabel)) {
        setPageFormErros([...pageFormErrors, errorLabel]);
      } else if (!isError && pageFormErrors.includes(errorLabel)) {
        setPageFormErros(pageFormErrors.filter(error => error !== errorLabel));
      }
    }
  },[isError, value, pageFormErrors]);

  useEffect(() => {
    const errorLabel = item.key || item.label;

    return () => setPageFormErros(pageFormErrors.filter(error => error !== errorLabel));
  }, []);

  const dropDownComponent = () => (
    <StyledTextField
      $isOverwrite={isOverwrite}
      title={item.toolTip}
      width={item.FieldWidth}
      fullWidth
      select
      error={error || isError}
      helperText={isError && ERRORS.REQUIRED}
      required={item.required}
      variant={variant}
      className={`mb-3 ${formClasses.textbox} ${classes}`}
      key={key}
      value={value || DefaultValue}
      onChange={handleChange}
      label={
        item.toolTip
          ? <TooltipWithIcon toolTip={item.toolTip} label={item.label} />
          : item.label
      }
      style={{
        width: item.FieldWidth
      }}
      SelectProps={{
        disabled: !filteredAcces,
        style: {
          width: `${item.FieldWidth} !important`,
          ...styles
        }
      }}
    >
      {
        (!isIgnoredNoneOption && !DefaultValue) &&
          <MenuItem key="" value=""><em>{!notShowDefaultOption ? 'None' : ''}</em></MenuItem>
      }
      {
        source.map(({ Label, Value }) => {
          const key = typeof Value === 'object' ? String(Label) : String(Value);
          const value = typeof Value === 'object' ? String(Label) : Value;

          return (<MenuItem key={key} value={value}>{Label || Value}</MenuItem>)
        })
      }
    </StyledTextField>
  );

  const TextboxComponent = ({ type = 'text', disabled = false } = {}) => {
    const handle = useDebounce(useCallback((value) => handleChange({ target: { value } })), 500);
    const { helperText, ...TextInputProps } = InputProps;

    return (
      <StyledTextField
        $isOverwrite={isOverwrite}
        title={item.toolTip}
        required={item.required}
        error={isError}
        helperText={(isError && ERRORS.REQUIRED) || item.helperText || helperText}
        fullWidth
        multiline={item.multiline}
        rows={item.rows}
        variant={variant}
        className={`${mb} ${formClasses.textbox} ${classes}`}
        disabled={!!(INPUT_PROPS[item.field] || {}).disabled || disabled}
        type={type}
        label={
          item.toolTip
            ? <TooltipWithIcon toolTip={item.toolTip} label={item.label} />
            : item.label
        }
        placeholder={placeholder}
        key={key}
        // value={value ?? ''}
        // onChange={!disabled && !(INPUT_PROPS[item.field] || {}).disabled ? handleChange : e => e.preventDefault()}
        onBlur={() => handleChange({ target: { value: field } })}
        value={field ?? ''}
        onChange={
          !disabled && !(INPUT_PROPS[item.field] || {}).disabled
            ? e => {
              setField(e.target.value);
              handle(e.target.value);
            }
            : e => e.preventDefault()

        }
        style={{
          width: item.FieldWidth
        }}
        InputProps={{
          ...(INPUT_PROPS[item.field] || {}),
          disabled: disabled || !filteredAcces,
          style: {
            ...styles
          },
          ...(item.mask
              ? { inputComponent: TextMaskField, inputProps: { mask: item.mask, value: field || ' ' }, }
              : {}
          ),
          ...TextInputProps
        }}
      />
    )
  };
  const MultipleSelect = () => {
    return (
      <StyledTextField
        $isOverwrite={isOverwrite}
        title={item.toolTip}
        fullWidth
        select
        multiple
        error={isError}
        required={item.required}
        helperText={isError && ERRORS.REQUIRED}
        className={`${mb} ${formClasses.textbox} ${classes}`}
        variant={variant}
        label={
          item.toolTip
            ? <TooltipWithIcon toolTip={item.toolTip} label={item.label} />
            : item.label
        }
        value={item.resources || []}
        onChange={handleChange}
        style={{
          width: item.FieldWidth
        }}
        input={<Input />}
        SelectProps={{
          style: {
            ...styles
          },
          multiple: true,
          renderValue: (selected) => {
            // console.log('[MultipleSelect] selected: ', selected)

            return selected.map(sId => item.allResources.find(({ id }) => id === sId)?.label || '').join(' ')
          }
        }}
      >
        {(item.allResources || []).map((resource) => {
          return (
            <MenuItem key={resource.id} value={resource.id}>
              <Checkbox checked={(item.resources || []).includes(resource.id)} />
              <ListItemText primary={resource.label} />
            </MenuItem>
          )
        })}
      </StyledTextField>
    )
  };

  const NumericTextbox = () => {
    const handleOnChange = filteredAcces && !(INPUT_PROPS[item.field] || {}).disabled ? handleChange : e => e.preventDefault();
    const { helperText, endAdornment, ...NumericInputProps } = InputProps;
  console.log('[NumericTextbox] L396 item.FieldWidth ', item.FieldWidth);

    return (
      <StyledTextField
        $isOverwrite={isOverwrite}
        title={item.toolTip}
        required={item.required}
        error={isError}
        helperText={isError && ERRORS.REQUIRED || helperText}
        fullWidth={!item.FieldWidth}
        style={{
          width: item.FieldWidth
        }}
        multiline={item.multiline}
        rows={item.rows}
        variant={variant}
        className={`${mb} ${formClasses.textbox} ${classes}`}
        disabled={!!(INPUT_PROPS[item.field] || {}).disabled || !filteredAcces}
        type='text'
        label={
          item.toolTip
            ? <TooltipWithIcon toolTip={item.toolTip} label={item.label} />
            : item.label
        }
        placeholder={placeholder}
        key={key}
        InputProps={{
          ...(INPUT_PROPS[item.field] || {}),
          ...NumericInputProps,
          endAdornment,
          disabled: !!(INPUT_PROPS[item.field] || {}).disabled || !filteredAcces,
          style: {
            ...styles,
          },
          inputComponent: CurrencyField,
          inputProps: {
            value: value !== undefined ? value : '',
            onChange: handleOnChange,
            ...NumericInputProps
          },
        }}
      />
    )
  };

  const DateComponent = () => {

    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
      >
        <KeyboardDatePicker
          id="date-picker-dialog"
          variant={variant}
          views={DateViews}
          inputFormat={DateFormat}
          title={item.toolTip}
          value={value}
          onChange={handleChange}
          disabled={!filteredAcces}
          renderInput={(props) => (
            <StyledTextField
              {...props}
              fullWidth
              $isOverwrite={isOverwrite}
              className={`${mb} ${formClasses.textbox}`}
              required={item.required}
              helperText={isError && ERRORS.REQUIRED}
              error={isError}
              label={item.toolTip
                ? <TooltipWithIcon toolTip={item.toolTip} label={item.label} />
                : item.label}
              style={{
                width: item.FieldWidth
              }}
              InputProps={{
                ...props.InputProps,
                style: {
                  ...styles
                }
              }}
            />
          )}
        />
      </LocalizationProvider>
    )
  };

  const gridGroupEl = () => !!toggleGridGroupModal
    ? <React.Fragment key={`${key}-grid-group-data`}></React.Fragment>
    : <GridGroup
        key={`${key}-grid-group-data`}
        field={item.field}
        label={item.label}
        groupData={value}
        docType={docType}
        selectedFieldItems={selectedFieldItems}
        kDocAggregationMethod={kDocAggregationMethod}
      />
  ;


  const isValidate = useCallback(subItem => !(value || {})[subItem.Value], [value]);

  const MultiLineTextboxComponent = () => {
    if (!source.length) return <></>;

    const xs = 12/source.length;

    return (
      <RootGrid container spacing={1}>
        {
          source.map(subItem => {
            const isError = isValidate(subItem);

            return (
              <Grid item xs={xs} key={subItem.Label}>
                <StyledTextField
                  fullWidth
                  required={item.required}
                  error={isError}
                  helperText={isError && ERRORS.REQUIRED}
                  variant={variant}
                  className={`${mb} ${formClasses.textbox} ${classes}`}
                  label={subItem.Label}
                  placeholder={placeholder}
                  key={key}
                  value={(value || {})[subItem.Value] || ''}
                  onChange={
                    (e) => handleChange({ target: { value: { ...(value || {}), [subItem.Value]: e.target.value } }})
                  }
                  style={{
                    width: item.FieldWidth
                  }}
                  InputProps={{
                    ...(subItem.Mask
                        ? { inputComponent: TextMaskField, inputProps: { mask: subItem.Mask, value: (value || {})[subItem.Value] || ' ' } }
                        : {}
                    )
                  }}
                />
              </Grid>
            )
          })
        }
      </RootGrid>
    )
  };


  const PasswordComponent = () => {
    const isError = !value?.[PASSWORD_TYPES.password] ||
      value?.[PASSWORD_TYPES.confirdPassword] !== value?.[PASSWORD_TYPES.password] ||
      !validatePassword(value?.[PASSWORD_TYPES.password]);

    return (
      <Grid container spacing={1}>
        <Grid item xl={12} lg={12} md={12}>
          <StyledTextField
            fullWidth
            required={item.required}
            error={isError}
            helperText={
              [
                {
                  condition: isError && !value?.[PASSWORD_TYPES.password],
                  message: ERRORS.REQUIRED
                },
                {
                  condition: isError && !validatePassword(value?.[PASSWORD_TYPES.password]),
                  message: 'Password should contains at least 8 characters (numbers, special character, uppercase letters, lowercase letters)'
                }
              ].find(({ condition }) => condition)?.message || ''
            }
            variant={variant}
            type={showPassword ? 'text' : 'password'}
            className={`${mb} ${formClasses.textbox} ${classes}`}
            label={item.label}
            placeholder={placeholder}
            value={(value || {})[PASSWORD_TYPES.password] || ''}
            onChange={
              (e) => {
                handleChange({ target: { value: { ...(value || {}), [PASSWORD_TYPES.password]: e.target.value } }})
              }
            }
            style={{
              width: item.FieldWidth
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    size="large">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12}>
          <StyledTextField
            fullWidth
            required={item.required}
            error={isError}
            helperText={
              [
                {
                  condition: isError && !value?.[PASSWORD_TYPES.confirdPassword],
                  message: ERRORS.REQUIRED
                },
                {
                  condition: isError && value?.[PASSWORD_TYPES.confirdPassword] && value?.[PASSWORD_TYPES.password] !== value?.[PASSWORD_TYPES.confirdPassword],
                  message: 'Passwords does not match'
                }
              ].find(({ condition }) => condition)?.message || ''
            }
            variant={variant}
            type={showPassword ? 'text' : 'password'}
            className={`${mb} ${formClasses.textbox} ${classes}`}
            label="Repeat password"
            placeholder={placeholder}
            value={(value || {})[PASSWORD_TYPES.confirdPassword] || ''}
            onChange={
              (e) => handleChange({
                target: { value: { ...(value || {}), [PASSWORD_TYPES.confirdPassword]: e.target.value }}
              })
            }
            style={{
              width: item.FieldWidth
            }}
          />
        </Grid>
      </Grid>
    )
  };

  const CheckboxComponent = () => {
    const checked = item.checkBoxId ? (value || []).includes(item.checkBoxId) : !!value;

    return (
      <FormControlLabel
        // className={classes}
        title={item.toolTip}
        control={
          <Checkbox
            name={item.checkBoxId ? String(item.checkBoxId) : item.field}
            disabled={!filteredAcces}
            checked={checked}
            onChange={handleChange}
            onClick={e => (e.stopPropagation())}
            icon={<Check />}
            checkedIcon={<Check />}
            sx={
              (theme) => ({
                color: 'transparent',
                '& svg': {
                  border: `1px solid ${theme.palette.primary.main}`,
                },
                '&.Mui-checked svg': {
                  fill: theme.palette.primary.main,
                },
                '&.Mui-disabled': {
                  opacity: '0.5 !important',
                },
                '&.Mui-disabled svg': {
                  fill: checked ? theme.palette.primary.main : '#fff',
                },
                '&~span': {
                  whiteSpace: 'nowrap',
                }
              })
            }
          />
        }
        label={item.label}
      />
    )
  };

  const component = {
    [DISPLAY_CONTROLLERS.Textbox]: TextboxComponent,
    [DISPLAY_CONTROLLERS.NumericBox]: NumericTextbox,
    [DISPLAY_CONTROLLERS.MultiLineTextbox]: MultiLineTextboxComponent,
    [DISPLAY_CONTROLLERS.Password]: PasswordComponent,
    [DISPLAY_CONTROLLERS.PasswordChange]: () => (
      <PasswordChange
        className={`${mb} ${formClasses.textbox} ${classes}`}
        item={item}
        value={value}
        handleChange={handleChange}
        handleFieldsCancel={handleFieldsCancel}
        handleFieldsSave={handleFieldsSave}
        selectedFieldItems={selectedFieldItems}
        selectedItem={selectedItem}
      />
    ),
    [DISPLAY_CONTROLLERS.PasswordTextbox]: PasswordComponent,
    [DISPLAY_CONTROLLERS.Checkbox]: CheckboxComponent,
    // [DISPLAY_CONTROLLERS.AutoCalc]: () => TextboxComponent({ type: 'number', disabled: true }),
    [DISPLAY_CONTROLLERS.Dropdown]: dropDownComponent,
    [DISPLAY_CONTROLLERS.Relation]: dropDownComponent,
    [DISPLAY_CONTROLLERS.RecordSet]: MultipleSelect,
    [DISPLAY_CONTROLLERS.MultiSelect]: MultipleSelect,
    // [DISPLAY_CONTROLLERS.RecordSet]: () => <></>,
    [DISPLAY_CONTROLLERS.Date]: DateComponent,
    [DISPLAY_CONTROLLERS.Grid]: () => (
      toggleGridModal
        ? <Button variant='outlined' key={`${fieldsType} - ${item.field}-grid-data`} onClick={toggleGridModal} className="h-48px">
          <span className="btn-wrapper--label">Show Grid Details</span>
        </Button>
        : <GridDetails
          key={`${key}-grid-data`}
          selectedFieldItems={selectedFieldItems}
          handleChange={handleChange}
          handleGridChange={handleGridChange}
          fieldsType={fieldsType}
          docType={docType}
          fieldName={item.field}
          gridValues={value}
          editable={filteredAcces}
        />
    ),
    [DISPLAY_CONTROLLERS.GridScenario]: () => (
      <GridScenario
        selectedFieldItems={selectedFieldItems}
        setSelectedFieldItems={setSelectedFieldItems}
        handleChange={handleChange}
        handleGridChange={handleGridChange}
        fieldsType={fieldsType}
        docType={docType}
        item={item}
        gridValues={value}
        editable={filteredAcces}
        access={access}
      />
    ),
    [DISPLAY_CONTROLLERS.GridGroup]: gridGroupEl,
    [DISPLAY_CONTROLLERS.MonthlyGrid]: gridGroupEl,
    [DISPLAY_CONTROLLERS.GroupedMonthlyGrid]: gridGroupEl,
    [DISPLAY_CONTROLLERS.GroupedNumber]: () => {
      return (
        <Calculations
          label={item.label}
          field={item.field}
          docType={docType}
          calculations={value}
          selectedItem={selectedItem}
          docsConfig={docsConfig}
          fieldsType={fieldsType}
          group={group}
        />
      )
    },
    [DISPLAY_CONTROLLERS.Steps]: () => (
      <Steps
        setSelectedFieldItems={setSelectedFieldItems}
        selectedFieldItems={selectedFieldItems}
        docsConfig={docsConfig}
        docType={docType}
        selectedItem={selectedItem}
        handleFieldsSave={handleFieldsSave}
        handleFieldsCancel={handleFieldsCancel}
        setDisabled={setDisabled}
        fieldsType={fieldsType}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        items={items}
        openGateRequests={value}
        gates={source}
        setPendingReviewers={setPendingReviewers}
        pendingReviewers={pendingReviewers}
        docs={docs}
      />
    ),
    [DISPLAY_CONTROLLERS.StepContent]: () => (
      <StepContent
        reviewers={value}
        pendingReviewers={pendingReviewers}
        activeStep={activeStep}
        selectedFieldItems={selectedFieldItems}
      />
    ),
    [DISPLAY_CONTROLLERS.ListMessages]: () => (
      <ListMessages
        notifications={value}
        users={users}
      />
    ),
    [DISPLAY_CONTROLLERS.Approvers]: () => (
      <Approvers
        setSelectedFieldItems={setSelectedFieldItems}
        selectedFieldItems={selectedFieldItems}
        docType={docType}
        fieldsType={fieldsType}
        selectedItem={selectedItem}
        disabled={filteredAcces}
        reviewers={value}
        gates={source}
        roles={roles}
      />
    ),
    [DISPLAY_CONTROLLERS.CheckBoxesList]: () => {

      return (
        <Grid container spacing={1}>
          {
            source.map(({ CheckBoxId, Name, Value, ClassName }) => {
              const checkBoxId = CheckBoxId || Value;

              return (
                <Grid item xl={6} lg={6} md={12}  key={checkBoxId} {...gridOptions}>
                  <RenderField
                    classes={ClassName}
                    item={{
                      displayController: DISPLAY_CONTROLLERS.Checkbox,
                      label: Name,
                      field: item.field,
                      checkBoxId
                    }}
                    selectedFieldItems={!fieldsType ? selectedFieldItems : selectedFieldItems[fieldsType]}
                    userHandleChange={event => handleChange(event, checkBoxId)}
                  />
                </Grid>
              )
            })
          }
        </Grid>
      )
    },
    [DISPLAY_CONTROLLERS.DocStatus]: () => (
      <DocStatus
        item={{
          ...item,
          displayController: DISPLAY_CONTROLLERS.Dropdown
        }}
        handleChange={handleChange}
        setSelectedFieldItems={setSelectedFieldItems}
        selectedFieldItems={selectedFieldItems}
        fieldsType={fieldsType}
        roles={roles}
      />
    ),
    [DISPLAY_CONTROLLERS.AbandonedReason]: () => {
      const mockAbandonedReasons = [
        {
          Value: 'Not Viable',
          Label: 'Not Viable',
        },
        {
          Value: "Duplicate",
          Label: "Duplicate",
        }
      ];
      const status = selectedFieldItems?.[fieldsType]?.[`${docType}Status`];

      return (
        <>
          {
            status === STATUS_ABANDONED &&
              <RenderField
                item={{
                  ...item,
                  source: (source || []).length ? source : mockAbandonedReasons,
                  displayController: DISPLAY_CONTROLLERS.Dropdown,
                  value: mockAbandonedReasons[0].Value
                }}
                handleChange={handleChange}
                setSelectedFieldItems={setSelectedFieldItems}
                selectedFieldItems={selectedFieldItems}
                fieldsType={fieldsType}
                notShowDefaultOption={true}
                required={true}
              />
          }
        </>
      )
    },
    [DISPLAY_CONTROLLERS.Help]: () => (
      <Help
        docsConfig={docsConfig}
        docType={docType}
        item={item}
        fieldsType={fieldsType}
      />
    ),
    [DISPLAY_CONTROLLERS.Wysiwyg]: () => (
      <Wysiwyg
        label='Start typing...'
        value={value}
        access={filteredAcces}
        handleChange={handleChange}
      />
    ),
    [DISPLAY_CONTROLLERS.ApprovalScheduler]: () => (
      <ApprovalScheduler
        docsConfig={docsConfig}
        fieldsType={fieldsType}
        selectedItem={selectedItem}
        setSelectedFieldItems={setSelectedFieldItems}
        selectedFieldItems={selectedFieldItems}
        docType={docType}
        item={item}
        dueDatesLimits={value}
        handleChange={handleChange}
        handleFieldsSave={handleFieldsSave}
      />
    ),
    [DISPLAY_CONTROLLERS.Notes]: () => (
      <Notes
        value={value || []}
        label={item.label}
        access={filteredAcces}
        handleChange={handleChange}
      />
    ),
    [DISPLAY_CONTROLLERS.Logs]: () => (
      <Logs
        label={item.label}
        value={value || []}
      />
    ),
    [DISPLAY_CONTROLLERS.Tags]: () => (
      <Tags
        label={item.label}
        value={value ?? []}
        source={source.map(({ Value }) => Value)}
        handleChange={handleChange}
        styles={styles}
      />
    ),
    [DISPLAY_CONTROLLERS.GantChart]: () => (
      <Gant
        docsConfig={docsConfig}
        docs={docs}
        selectedItem={selectedItem}
      />
    ),
    [DISPLAY_CONTROLLERS.MfaType]: () => (
      <MfaType
        label={item.label}
        value={value}
        selectedFieldItems={selectedFieldItems}
        handleChange={handleChange}
      />
    ),
  }[item.displayController];

  return component ? component() : TextboxComponent();
};

const prefix = 'StyledFab';

const classes = {
  root: `${prefix}-root`
};

const createStyles = ({ theme }) => ({
  [`&.${classes.root}`]: {
    lineHeight: 1.5,
    height: '28px',
    color: '#fff',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0 14px',
    margin: '0 2px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: '#fff !important'
    }
  }
});

export default { createStyles, classes };
export { createStyles, classes };

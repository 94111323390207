import { THEME_SIZES } from './../../../constants';

const classes = {
  root: 'StyledChart-root'
};

const createStyles = ({ theme, breakpoint = THEME_SIZES.sm }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down(breakpoint)]: {
      display: 'none'
    }
  }
});

export {
  createStyles,
  classes
};

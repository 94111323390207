import { StepConnector } from '@mui/material';
import { styled } from '@mui/material/styles';

export default styled(StepConnector)(({ isactivecard: isActiveCard }) => ({
  display: isActiveCard ? 'none' : 'block',
  '&.MuiStepConnector-alternativeLabel': {
    top: 37
  },
  // active: {
  //   '& $line': {
  //     backgroundColor: '#3c44b1'
  //   },
  // },
  // completed: {
  //   '& $line': {
  //     backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
  //   },
  // },
  '& .MuiStepConnector-line': {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1
  }
}));

import React from 'react';
import { Autocomplete, styled, TextField, Chip } from '@mui/material';

import { formStyles, autocompleteStyles } from './../../../assets/styles';
import { GDOC_TYPE } from '../../../constants';

const { classes, createStyles } = autocompleteStyles;
const { classes: formClasses, createStyles: createFormStyles } = formStyles;
const StyledTextField = styled(TextField)((props) => ({
  ...createStyles(props),
  ...createFormStyles(props)
}));

const Tags = (props) => {
  const { label, value, source, handleChange, styles } = props;

  return (
    <>
      <div style={styles}>
        <Autocomplete
          multiple
          freeSolo
          options={source}
          value={value}
          onChange={(_, value) => handleChange({ target: { value } })}
          renderTags={() => null}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant='outlined'
              className={`mb-3 ${formClasses.textbox} ${classes.textField}`}
              label={label}
              placeholder={''}
            />
          )}
        />
        <div className="tags">
          {
            value.map((tag) => (
              <Chip
                className="tag"
                variant="outlined"
                key={tag}
                label={tag}
                onDelete={() => handleChange({ target: { value: value.filter(v => v !== tag) } })}
              />
            ))
          }
        </div>
      </div>

    </>
  )
};

export default Tags;

import React from 'react';
import { Button, CircularProgress } from '@mui/material';

export default (props) => {
  const { onClick, className, style = {}, children, loading, hide, disabled = false, variant = 'contained' } = props;

  return hide
    ? (<></>)
    : (
      <Button
        variant={variant}
        color='primary'
        style={style}
        disabled={disabled}
        onClick={onClick}
        className={className}
        {
          ...(
            loading
              ? { startIcon: <CircularProgress size={18} />, disabled: loading }
              : {}
          )
        }
      >
        { children }
      </Button>
    );
}

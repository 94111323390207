import ColorLibConnector from './color-lib-connector';
import { Step, StepButton, StepLabel, Stepper, Box } from '@mui/material';
import { checkAccess, composeInitGateIfNeed, findItem, getFieldParams } from '../../../utils/common';
import {
  COLOR_STATUS_APPROACHING,
  COLOR_STATUS_PAST_DEAD_LINE,
  DISPLAY_CONTROLLERS,
  ENDPOINTS,
  FIELD_KEYS,
  FORBIDDEN_STATUSES,
  METHOD_POST,
  METHOD_PUT,
  STATUS_APPROVED,
  STATUS_CLOSED,
  STATUS_COMPLETED,
  STATUS_OPEN,
  STATUS_PENDING, STEP_ACTION_APPROVE,
  STEP_ACTION_INIT,
  STEP_ACTION_NOT_APPROVED,
  TITLES,
  TYPE_APPROVVERS,
  TYPE_LEADS,
  TYPE_SPONSORS
} from '../../../constants';
import ColorlibStepIcon from './color-lib-step-icon';
import React, { useCallback, useMemo } from 'react';
import { useDocChipsProgress, usePendingReviewers } from '../../../hooks';
import { useSelector } from 'react-redux';
import { useTheme } from "@mui/material";

import { LinerProgressBar, LoadingButton, ModalConfirm, RenderField } from './../';
import { useStepReviewers } from './../../../hooks/';
import { notApprove, prepareComment, resetGate } from './functions';

export default (props) => {
  const {
    reviewers,
    activeStep,
    selectedItem,
    items,
    fieldsType,
    gates,
    childDocsApproved,
    docStatus,
    completed = [],
    authUser,
    setActiveStep,
    selectedFieldItems,
    setSelectedFieldItems,
    setCompleted = () => {},
    setStepAction,
    toggleOverviewModal,
    docType,
    isAuthSponsor,
    isActiveCard = false,
    stepAction,
    toggleModal,
    comment,
    docs,
    setHandleSave,
    authUserReviewerType,
    docsConfig,
    onClose,
    openOverviewModal,
    authUserReviewer,
    setComment
  } = props;
  const theme = useTheme();
  const contentSaving = useSelector(state => state.ThemeOptions.contentSaving);

  const pendingReviewers =  usePendingReviewers(reviewers, activeStep);
  const stepReviewers =  useStepReviewers(reviewers, activeStep);
  const progressChips = useDocChipsProgress(selectedItem, docsConfig);

  const prevActiveGate = useMemo(() =>
      gates.find(({ Value: step }) => (step === activeStep - 1)),
[activeStep, JSON.stringify(gates)]
  );
  const activeGate = useMemo(() =>
      gates.find(({ Value: step }) => (step === activeStep)),
[activeStep, JSON.stringify(gates)]
  );
  const nextGate = useMemo(() =>
      gates.find(({ Value: step }) => (step === activeStep + 1)),
[activeStep, JSON.stringify(gates)]
  );
  const activeGateIndex = useMemo(() =>
      gates.findIndex(({ Value: step }) => (step === activeStep)),
[activeStep, JSON.stringify(gates)]
  );
  const approvedReviewers = useMemo(() => {
    return stepReviewers
      .filter(
        ({ status }) => status === STATUS_APPROVED
      )
  }, [stepReviewers])


  const isNeedInitiateGate = useCallback((index) => {
    const nextStepIndex = gates
      .findIndex(gate => gate.Value === activeStep) + 1;

    return index === nextStepIndex && completed.includes(activeStep)
  }, [
    gates,
    activeStep,
    completed
  ]);

  const isDisabled = useCallback((step, index) => {
    if (selectedItem?.isNew || isActiveCard) return true;

    const reviewers = pendingReviewers.map(({ id }) => id);
    const nextStepIndex = gates.findIndex(gate => gate.Value === activeStep) + 1;
    const bAllowInitStep = selectedItem.parents.every(docId => {
      const parentItem = findItem(items, docId);
      const parentOpenGates = (parentItem?.[fieldsType] || [])
        .find(({ field }) => field === `${parentItem.type}OpenGateRequests`)?.value || [];

      return !parentItem?.[fieldsType] || parentOpenGates.some(({ gate }) => gate === step);
    });

    return contentSaving || !bAllowInitStep || !childDocsApproved || FORBIDDEN_STATUSES.includes(docStatus) ||
      !(index === nextStepIndex && completed.includes(activeStep)) &&
      !(
        step === activeStep &&
        !completed.includes(activeStep) &&
        (reviewers.includes(authUser.UserId))
      )
  }, [
    selectedItem,
    isActiveCard,
    pendingReviewers,
    gates,
    fieldsType,
    contentSaving,
    childDocsApproved,
    docStatus,
    activeStep
  ]);
  const onClick = useCallback((step, index) => {
    setActiveStep(step);
    const isComplete = !(selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}Reviewers`] || reviewers || [])
      .filter(({ gate }) => gate === step)
      .some(({ status }) => status === STATUS_PENDING);
    if (isComplete) setCompleted([...completed, step]);

    const { action = () => {} } = [
      {
        condition: () => {
          const nextStepIndex = gates
            .findIndex(gate => gate.Value === activeStep) + 1;

          return index === nextStepIndex && completed.includes(activeStep) // isActivateStep
        },
        action: () => {
          const nextOpenGateRequest = {
            userId: authUser.UserId,
            gate: step,
            status: isComplete ? STATUS_CLOSED : STATUS_OPEN,
            createdAt: new Date()
          };
          const nextOpenGateRequests = [
            ...(selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}OpenGateRequests`] || []),
            ...composeInitGateIfNeed(nextOpenGateRequest, gates.map(({ Value }) => Value))
          ];
          setSelectedFieldItems({
            ...selectedFieldItems,
            [fieldsType]: {
              ...selectedFieldItems[fieldsType],
              [`${selectedItem.type}OpenGateRequests`]: nextOpenGateRequests
            }
          });
          setStepAction(STEP_ACTION_INIT);
          toggleOverviewModal();
        }
      },
      {
        condition: () =>
          step === activeStep &&
          !completed.includes(step) &&
          (pendingReviewers.map(({ id }) => id).includes(authUser.UserId)),
        action: () => {
          const reviewer = (selectedFieldItems?.[fieldsType]?.[`${docType}Reviewers`] || []).find(({ id, gate, status }) =>
            (id === authUser.UserId && gate === step && status === STATUS_PENDING));

          const nextReviewers = [
            ...(
              selectedFieldItems?.[fieldsType]?.[`${docType}Reviewers`] || []).filter(({ id, gate, status, type }) =>
              !(id === authUser.UserId && gate === step && status === STATUS_PENDING)
            ),
            { ...reviewer, status: STATUS_APPROVED, updatedAt: new Date() }
          ];

          const isCloseStep = !nextReviewers.some(
            ({ gate,  status }) => status === STATUS_PENDING && gate === activeStep
          );
          const openRequest = (selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}OpenGateRequests`] || [])
            .find(({ gate, status }) => (gate === step && status === STATUS_OPEN));
          const nextOpenRequests = !isCloseStep
            ? (selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}OpenGateRequests`] || [])
            : [
              ...(selectedFieldItems?.[fieldsType]?.[`${selectedItem.type}OpenGateRequests`] || [])
                .filter(({ gate, status }) => !(gate === step && status === STATUS_OPEN) ),
              ...composeInitGateIfNeed({ ...openRequest, updatedAt: new Date(), status: STATUS_CLOSED }, gates.map(({ Value }) => Value))
            ]
          ;

          setSelectedFieldItems({
            ...selectedFieldItems,
            [FIELD_KEYS.Approvers]: {
              ...selectedFieldItems[FIELD_KEYS.Overview],
              [`${selectedItem.type}Reviewers`]: nextReviewers
            },
            [fieldsType]: {
              ...selectedFieldItems[fieldsType],
              [`${selectedItem.type}OpenGateRequests`]: nextOpenRequests,
              [`${selectedItem.type}Reviewers`]: nextReviewers
            }
          });
          setStepAction(isCloseStep ? STEP_ACTION_INIT : STEP_ACTION_APPROVE);
          toggleOverviewModal();
        }
      }
    ].find(({ condition }) => condition()) || {};

    action();
  },[
    selectedFieldItems,
    selectedItem,
    fieldsType,
    reviewers,
    pendingReviewers,
    gates,
    completed,
    activeStep,
    authUser,
    docType,
  ]);

  return (
    <Box
      sx={{
        padding: '0px 50px 20px 50px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        {
          !!progressChips.length && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${progressChips.length}, 1fr)`,
                gridTemplateRows: 'auto',
                alignContent: 'center',
                width: '100%',
                gap: '2px'
              }}
            >
              {
                progressChips.map(({ label, gate, status: gateStatus, tooltip }, i) => {
                  const progress = gateStatus ? 100 : 0;
                  const {
                    progressColor = theme.palette.green.approved
                  } = [
                    {
                      condition: gateStatus === STATUS_OPEN,
                      progressColor: theme.palette.green.accent
                    },
                    {
                      condition: gateStatus === COLOR_STATUS_PAST_DEAD_LINE,
                      progressColor: theme.palette.red.mid
                    },
                    {
                      condition: gateStatus === COLOR_STATUS_APPROACHING,
                      progressColor: theme.palette.yellow.mid
                    }
                  ].find(({ condition }) => condition) || {}

                  return (
                    <Box
                      key={i}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        alignContent: 'center',
                        justifyContent: 'center',
                        gap: '10px'
                      }}
                    >
                      <Box
                        title={tooltip}
                        sx={{
                          width: '100%'
                        }}
                      >
                        <LinerProgressBar
                          key={i}
                          className={'progress-nav-doc'}
                          sx={{
                            height: '30px !important',
                            borderRadius: '0.3rem !important',
                          }}
                          progress={progress}
                          progressColor={progressColor}
                          // bgColor={bgColor}
                          transition={'transform .0s linear'}
                        />
                      </Box>
                      <Box
                        title={tooltip}
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          alignContent: 'center',
                          justifyContent: 'center',
                          fontSize: '14px',
                          fontWeight: 700,
                          color: theme.palette.primary.main,
                          padding: '5px',
                        }}
                      >
                        { label }
                      </Box>
                    </Box>
                  );
                })
              }
            </Box>
          )
        }
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            gap: '20px',
            marginTop: '17px',
          }}
        >
          <LoadingButton
            className="btn-reset"
            variant='outlined'
            onClick={toggleModal}
          >
            Archive Document
          </LoadingButton>
          <LoadingButton
            disabled={!(!completed.includes(activeStep) && selectedItem?.notGrouped?.CreatedBy === authUser.UserId)}
            onClick={() => resetGate({
              stepAction,
              activeStep,
              selectedItem,
              selectedFieldItems,
              fieldsType,
              docType,
              setSelectedFieldItems,
              authUser,
              comment,
              setHandleSave,
              docs,
              authUserReviewerType,
              docsConfig
            })}
            variant='outlined'
            className="btn-reset"
          >
            Cancel Gate
          </LoadingButton>
          {
            childDocsApproved &&
              !completed.includes(activeStep) &&
              pendingReviewers.map(({ id }) => id).includes(authUser.UserId) &&
              !FORBIDDEN_STATUSES.includes(docStatus) && (
                <LoadingButton
                  variant='contained'
                  className="btn-save"
                  disabled={isDisabled(activeStep, activeGateIndex)}
                  onClick={() => onClick(activeStep, activeGateIndex)}
                  hide={false}
                >
                  Approve { activeGate.Label }
                </LoadingButton>
            )
          }
          {
            isNeedInitiateGate(activeGateIndex + 1) && (
              <LoadingButton
                variant='contained'
                className="btn-save"
                // disabled={isDisabled(activeStep, activeGateIndex)}
                onClick={() => onClick(nextGate?.Value, activeGateIndex + 1)}
                hide={false}
              >
                Initiate { nextGate?.Label } Approval
              </LoadingButton>
            )
          }
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(4, 1fr)`,
            gridTemplateRows: 'auto',
            alignContent: 'center',
            justifyContent: 'center',
            gap: '10px',
            padding: '40px 50px 0px 50px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'center',
              gap: '5px',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: theme.palette.white,
                  border: `1px solid ${theme.palette.helpers.primary}`
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontWeight: 400,
                color: theme.palette.black,
                height: '100%',
              }}
            >
              { TITLES.NOT_STARTED }
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'center',
              gap: '5px',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: theme.palette.green.approved,
                  border: `1px solid ${theme.palette.helpers.primary}`
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontWeight: 400,
                color: theme.palette.black,
                height: '100%',
              }}
            >
              { TITLES.APPROVED }
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'center',
              gap: '5px',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: theme.palette.green.accent,
                  border: `1px solid ${theme.palette.helpers.primary}`
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontWeight: 400,
                color: theme.palette.black,
                height: '100%',
              }}
            >
              { TITLES.IN_PROGRESS }
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'center',
              justifyContent: 'center',
              gap: '5px',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  width: '12px',
                  height: '12px',
                  backgroundColor: theme.palette.red.mid,
                  border: `1px solid ${theme.palette.helpers.primary}`
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'center',
                fontSize: '12px',
                fontWeight: 400,
                color: theme.palette.black,
                height: '100%',
              }}
            >
              { TITLES.NOT_APPROVED }
            </Box>
          </Box>
        </Box>
      </Box>
      <ModalConfirm
        title={
          [
            {
              condition: stepAction === STEP_ACTION_INIT &&
                selectedFieldItems[FIELD_KEYS.Overview]?.[`${selectedItem.type}OpenGateRequests`]?.length <= 1,
              title: `Initiate ${activeGate?.Label} Approval?`
            },
            {
              condition: stepAction === STEP_ACTION_INIT &&
                selectedFieldItems[FIELD_KEYS.Overview]?.[`${selectedItem.type}OpenGateRequests`]?.length > 1,
              title: `Approve ${prevActiveGate?.Label}?`
            },
            {
              condition: stepAction === STEP_ACTION_APPROVE,
              title: `Approve ${activeGate?.Label}?`
            }
          ].find(({ condition }) => condition)?.title || `${TITLES.APPROVE}?`
        }
        cancelTitle={
          [
            {
              condition: stepAction === STEP_ACTION_APPROVE,
              title: TITLES.NOT_APPROVED
            }
          ].find(({ condition }) => condition)?.title || TITLES.CANCEL
        }
        confirmTitle={
          [
            {
              condition: stepAction === STEP_ACTION_INIT &&
                selectedFieldItems[FIELD_KEYS.Overview]?.[`${selectedItem.type}OpenGateRequests`]?.length <= 1,
              title: TITLES.INITIATE_GATE
            }
          ].find(({ condition }) => condition)?.title || `${TITLES.APPROVE}`
        }
        resetTitle={
          [
            {
              condition: [TYPE_SPONSORS, TYPE_LEADS].includes(authUserReviewerType),
              title: TITLES.RESET_GATE
            }
          ].find(({ condition }) => condition)?.title
        }
        openModal={openOverviewModal}
        toggleModal={toggleOverviewModal}
        handleConfirm={
          () => {
            // const { source: configOpenGateRequests = [] } = getInitialDocValue({ selectedItem, fieldsType, suffix: 'OpenGateRequests' }) || {};
            if (
              stepAction === STEP_ACTION_INIT &&
              !selectedFieldItems[FIELD_KEYS.Overview]?.[`${selectedItem.type}OpenGateRequests`]?.length
            ) {
              return onClose();
            }

            const { Items: configOpenGateRequests } = getFieldParams(docsConfig[docType], fieldsType, `${docType}OpenGateRequests`);

            const { Value: lastConfigGate } = configOpenGateRequests[configOpenGateRequests.length - 1] || {};
            const openGateRequests = selectedFieldItems[FIELD_KEYS.Overview]?.[`${selectedItem.type}OpenGateRequests`] || [];
            const isCompleted = (openGateRequests.find(({ gate }) => gate === lastConfigGate) || {}).status === STATUS_CLOSED;

            const lastIndex = openGateRequests.length - 1;

            const comments = stepAction === STEP_ACTION_INIT && !!openGateRequests[lastIndex - 1]
              ? [
                prepareComment({ authUser, activeStep: openGateRequests[lastIndex - 1]?.gate, stepAction: STEP_ACTION_APPROVE, comment, authUserReviewerType }),
                // prepareComment({ authUser, activeStep, stepAction, comment, authUserReviewerType }),
              ]
              : [prepareComment({ authUser, activeStep, stepAction, comment, authUserReviewerType })]
            ;

            setSelectedFieldItems({
              ...selectedFieldItems,
              ...(isCompleted && { [FIELD_KEYS.Fields]: {
                  ...selectedFieldItems[FIELD_KEYS.Fields],
                  [`${selectedItem.type}Status`]: STATUS_COMPLETED,
                }}),
              [fieldsType]: {
                ...selectedFieldItems[fieldsType],
                [`${selectedItem.type}History`]: [
                  ...(selectedFieldItems[fieldsType][`${selectedItem.type}History`] || []),
                  ...comments
                ]
              }
            });
            setHandleSave(true);
          }
        }
        cancelConfirm={
          [
            {
              condition: stepAction === STEP_ACTION_APPROVE,
              cancelConfirm: () => {
                notApprove({
                  selectedItem,
                  selectedFieldItems,
                  fieldsType,
                  setSelectedFieldItems,
                  setHandleSave,
                  authUser,
                  activeStep,
                  comment,
                  authUserReviewerType,
                  authUserReviewer
                });
              }
            }
          ].find(({ condition }) => condition)?.cancelConfirm
        }
        resetConfirm={
          () => resetGate({
            stepAction,
            activeStep,
            selectedItem,
            selectedFieldItems,
            fieldsType,
            docType,
            setSelectedFieldItems,
            authUser,
            comment,
            setHandleSave,
            docs,
            authUserReviewerType,
            docsConfig
          })
        }
        onClose={onClose}
        additionalFields={[
          <RenderField
            key='history'
            // classes="mt-2 mb-2"
            styles={{
              width: '400px'
            }}
            item={{
              label: 'Comment',
              field: `comment`,
              displayController: DISPLAY_CONTROLLERS.Textbox,
              multiline: true,
              rows: 2
            }}
            access={
              checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_POST, METHOD_PUT] })
            }
            selectedFieldItems={{ comment }}
            userHandleChange={
              (event) => setComment(event.target.value)
            }
          />
        ]}
      />
      <Box
        sx={{
          marginTop: '20px',
          display: 'none'
        }}
      >
        {
          false && (
            <Stepper
              className={`clear-both ${isActiveCard ? 'mt-4 ml-1' : ''}`}
              alternativeLabel activeStep={activeStep}
              connector={<ColorLibConnector isactivecard={isActiveCard || undefined} active='true' />}
            >
            {gates.map(({ Label: label, Value: step }, index) => (
              <Step
                key={label}
                disabled={isDisabled(step, index)}
                completed={completed.includes(step)}
              >
                <StepButton
                  style={{ zIndex: 15 }}
                  onClick={() => onClick(step, index)}
                >
                  <StepLabel
                    StepIconComponent={
                      props => (
                        <ColorlibStepIcon
                          { ...props }
                          isActiveCard={isActiveCard}
                          docStatus={docStatus}
                          step={step}
                          authUser={authUser}
                          childDocsApproved={childDocsApproved}
                          isAuthSponsor={isAuthSponsor}
                          pendingReviewers={pendingReviewers}
                          isOpenGateRequests={isNeedInitiateGate(index)}
                        />
                      )
                    }
                  >
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
          )
        }
      </Box>
    </Box>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
  Grid,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  TextField,
  IconButton,
  Box
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import Alert from '@mui/material/Alert';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import { AUTH_STATUS_NEWPASSWORD, AUTH_STATUS_MFA } from './../../../constants';
import { LoginWrapper, LoginLogo } from './../../../components/controls';
import { AuthService } from './../../../services';

export default () => {
  const history = useHistory();

  const [checked, setChecked] = useState(true);
  const [redirect, setRedirect] = useState('');
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const isAuthenticated = AuthService.isAuthenticated();

  const handleSelectChange = useCallback((type) => ({
    [type]: (value) => {
      setFields(prevFields => ({ ...prevFields, [type]: value.target.value }));
    }
  }[type]), []);

  const handleChange = useCallback((event) => {
    setChecked(event.target.checked);
  }, []);

  const handleSubmit = useCallback(() => {
    setErrors([]);
    setLoading(true);

    AuthService.login(fields)
      .then(authData => {
        console.log(':authData ', authData)


        if (authData.status === AUTH_STATUS_NEWPASSWORD) {
          history.push({
            pathname: '/reset-password',
            authData
          });

          return;
        }

        if (authData.status === AUTH_STATUS_MFA) {
          history.push({
            pathname: '/verification-code',
            authData: { ...authData, fields },
          });

          return;
        }

        if (authData.accessToken) {
          setRedirect('/');

          return;
        };

        const message = {
          redirect: window.self.location.href,
          date: Date.now(),
        };
        console.log('message: ', message)
        window.parent.postMessage(message, '*');
      })
      .catch((error) => {
        setErrors(error.errors || ['The User email and/or password combination are incorrect.']);
      })
      .finally(() => setLoading(false));
  },[JSON.stringify(fields)]);

  useEffect(() => {
    if (isAuthenticated) setRedirect('/');
  }, [isAuthenticated]);

  if (redirect) return (<Redirect to={redirect}/>);

  return (
     <LoginWrapper>
        <LoginLogo />
        <div className="text-center mt-4">
          <h1 className="font-size-xxl mb-1 font-weight-bold">
            Login
          </h1>
        </div>
        <div className="py-4 content-login">
          <div>
            <div className="mb-4">
              <TextField
                fullWidth
                variant="outlined"
                id="textfield-email"
                label="Email address"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailOutlineTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleSelectChange('email')}
                value={fields.email || ''}
              />
            </div>
            <div className="mb-3">
              <TextField
                fullWidth
                variant="outlined"
                id="textfield-password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockTwoToneIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end" >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        size="large">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                onChange={handleSelectChange('password')}
                value={fields.password || ''}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    value="checked"
                    color="primary"
                  />
                }
                className="font-size-md"
                label="Remember me"
              />
              <div>
                <a
                  className="text-first"
                  href="/recover-password"
                >
                  Recover password
                </a>
              </div>
            </div>
            {
              errors.map((error, index) => (
                <Alert
                  key={index}
                  className="mb-4"
                  severity="error">
                  {error}
                </Alert>
              ))
            }
            <div className="login text-center py-4">
              <LoadingButton
                style={{
                  minHeight: '40px',
                  minWidth: '164px',
                  backgroundColor: '#00a66e',
                  color: '#fff',
                }}
                loading={loading}
                onClick={handleSubmit}
              >
                {!loading && 'Sign in'}
              </LoadingButton>
            </div>
          </div>
        </div>
      </LoginWrapper>
  );
}

import React, { useCallback, useState } from 'react';
import { List, ListItem, useTheme } from '@mui/material';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { hideBorderStyles } from './../../../assets/styles';

const { createStyles } = hideBorderStyles;
const HideBorderDiv = styled('div')(createStyles);

const Tabs = (props) => {
  const { defaultTab, tabsInfo = [] } = props;
  const theme = useTheme();

  const [activeTab, setActiveTab] = useState(defaultTab);
  const toggleTab = useCallback((tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }, [activeTab]);

  return (
    <>
      <List
        className="nav-tabs nav-tabs d-flex justify-content-left w-100"
        style={{
          // background: theme.palette.headerBlockBackground,
          position: 'relative',
          top: '-4px'
        }}
      >
        {
          tabsInfo.map(({ key, title, onClick = () => {} }) => (
            <ListItem
              key={key}
              style={{ borderRadius: 0 }}
              button
              disableRipple
              selected={activeTab === key}
              onClick={() => {
                toggleTab(key);
                onClick();
              }}
            >
              <span className="font-weight-bold text-uppercase font-size-sm">
                { title }
              </span>
            </ListItem>
          ))
        }
      </List>
      {
        tabsInfo.map(({ key, content }) => (
          <div
            key={key}
            className={clsx('tab-item-wrapper w-100', {
              active: activeTab === key
            })}
            index={key}
          >
            <div className="d-flex flex-wrap">
              <div className="w-100 p-1">
                <HideBorderDiv
                  variant="outlined"
                  className={`d-block text-left p-5`}
                >
                  { content }
                </HideBorderDiv>
              </div>
            </div>
          </div >
        ))
      }
    </>
  );
};

export default Tabs;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Typography, IconButton, Tooltip, SvgIcon, Box } from '@mui/material';
import { TreeItem } from '@mui/lab';
import * as Icons from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';

import { ReactComponent as InfoIcon } from './../../../assets/images/tabs/info.svg'
import { classes, createStyles } from './styles';
import {
  CONTROL_DOC_CDOC,
  TITLES,
  DEFAULT_DOC_VIEW_BOX, STATUS_OPEN, COLOR_STATUS_PAST_DEAD_LINE, COLOR_STATUS_APPROACHING
} from './../../../constants';
import { useDocChipsProgress, useDocProgress } from './../../../hooks';
import { getExpandIds, getMapDocIcons } from './../../../utils/common';
import { LinerProgressBar } from './../';
// import { Request } from './../../../services';


export const StyledTreeItem = (props) => {
  const {
    controlDocsConfig,
    docsConfig,
    clientName,
    labelInfo,
    children = [],
    nodeId,
    notification,
    className,
    item,
    expanded,
    setExpanded,
    setRequestSent,
    setRequestAccessItem,
    setOpenRequestAccessModal,
    type: docType
  } = props;
  const theme = useTheme();
  const progress = useDocProgress(item);
  const progressChips = useDocChipsProgress(item, docsConfig);

  const mapDocIcons = useMemo(
  () => getMapDocIcons(theme),
  []
  );
  const accessRequestProperty = item.access === false && {
    icon: 'CallMade',
    title: TITLES.REQUEST_ACCESS,
    onClick: (event) => {
      event.preventDefault();

      setRequestAccessItem(item);
      setOpenRequestAccessModal(true);
    }
  }
  const RequestIcon = accessRequestProperty && Icons[accessRequestProperty.icon];
  const KeyboardArrowDownTwoToneIcon = Icons['KeyboardArrowDownTwoTone'];

  return (
    <TreeItem
      className={className}
      label={
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gridTemplateRows: 'auto',
            // gap: '5px'
          }}
        >
          <Box
            className={classes.labelRoot}
            sx={{ gap: '10px' }}
          >
            <Box
              sx={{
                display: 'flex',
                padding: '5px',
                gap: '8px',
                alignContent: 'center',
                justifyContent: 'center',
                backgroundColor: mapDocIcons[docType]?.backgroundColor,
              }}
            >
              <SvgIcon
                htmlColor={mapDocIcons[docType]?.color}
                component={mapDocIcons[docType]?.Icon}
                sx={{
                  height: '18px',
                  width: '18px'
                }}
                viewBox={mapDocIcons[docType]?.viewBox ?? DEFAULT_DOC_VIEW_BOX}
              />
            </Box>
            <Box>
              <Typography
                variant="body2"
                className={`${classes.clientName} ${children.length ? classes.hasChildren : ''}`}>
                {
                  accessRequestProperty ? TITLES.NO_ACCESS_DOCUMENT : clientName
                }
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'flex-start',
              }}
            >
              {
                notification &&
                <Tooltip
                  title={`Can't find ${controlDocsConfig?.[CONTROL_DOC_CDOC]?.DocumentType} for this documents tree`}
                  placement="top-start"
                  arrow={true}
                >
                  <SvgIcon
                    htmlColor={theme.palette.red.mid}
                    component={InfoIcon}
                    // sx={{
                    //   height: '24px',
                    //   width: '24px'
                    // }}
                  />
                </Tooltip>
              }
              <Typography variant="ca ption" color="inherit" style={{ marginRight: '15px' }}>{labelInfo}</Typography>
              {
                accessRequestProperty && (
                  <IconButton
                    aria-label="Add"
                    className={`${classes.actionBtn} mr-2`}
                    title={accessRequestProperty.title}
                    onClick={accessRequestProperty.onClick}
                    size="large"
                  >
                    <RequestIcon data-forbidden-expand={true}/>
                  </IconButton>
                )
              }
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignContent: 'center',
                justifyContent: 'flex-end',
                width: '100%'
              }}
            >
              {
                !!children.length && (
                  <IconButton
                    aria-label="Open"
                    className={classes.actionBtn}
                    title={accessRequestProperty.title}
                    onClick={(event) => {
                      event.preventDefault()
                      console.log('children: ', item.children)

                      const childrenIds = getExpandIds(item.children);
                      const nextExpended = [...new Set([...expanded, ...childrenIds, nodeId])];
                      console.log('nextExpended: ', nextExpended)
                      setTimeout(() => setExpanded && setExpanded(nextExpended), 50)
                    }}
                    size="large"
                  >
                    <KeyboardArrowDownTwoToneIcon/>
                  </IconButton>
                )
              }
            </Box>
          </Box>
          {
            !!setRequestSent && (
              <Box>
                {
                  !isNaN(progress) && !progressChips.length && (
                    <LinerProgressBar
                      className={'progress-nav-doc tree'}
                      title={`${progress}%`}
                      progress={progress}
                    />
                  )
                }
                {
                  !!progressChips.length && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${progressChips.length}, 1fr)`,
                        gridTemplateRows: 'auto',
                        alignContent: 'center',
                        width: '80%',
                        gap: '2px'
                      }}
                    >
                      {
                        progressChips.map(({ status: gateStatus, tooltip }, i) => {
                          const progress = gateStatus ? 100 : 0;
                          const {
                            progressColor = theme.palette.green.approved
                          } = [
                            {
                              condition: gateStatus === STATUS_OPEN,
                              progressColor: theme.palette.green.accent
                            },
                            {
                              condition: gateStatus === COLOR_STATUS_PAST_DEAD_LINE,
                              progressColor: theme.palette.red.mid
                            },
                            {
                              condition: gateStatus === COLOR_STATUS_APPROACHING,
                              progressColor: theme.palette.yellow.mid
                            }
                          ].find(({ condition }) => condition) || {}

                          return (
                            <LinerProgressBar
                              key={i}
                              title={tooltip}
                              className={'progress-nav-doc'}
                              sx={{
                                height: '6px !important',
                                // borderRadius: '0.3rem !important',
                              }}
                              progress={progress}
                              progressColor={progressColor}
                              // bgColor={bgColor}
                              transition={'transform .0s linear'}
                            />
                          );
                        })
                      }
                    </Box>
                  )
                }
              </Box>
            )
          }
        </Box>
      }
      //onLabelClick={e => e.preventDefault()}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: classes.labelIcon,
      }}
      {...{ nodeId, children }}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string
};

export default styled(StyledTreeItem)(createStyles);

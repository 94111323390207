const classes = {
  textField: 'StyledForm-textField'
};

const createStyles = () => ({
  [`&.${classes.textField}`]: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: '0 !important'
    }
  }
});

export default { createStyles, classes };

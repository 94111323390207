import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { Collapse } from '@mui/material';
import * as Icons from '@mui/icons-material';

import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';
import SidebarUserbox from '../SidebarUserbox';

import { MENU_BLOCKS, MENU_ITEM_TYPES } from '../../../constants';
import { sortPosition } from './../../../utils/common';
const {
  ChevronRightTwoTone: ChevronRightTwoToneIcon,
  LinkTwoTone: LinkTwoToneIcon,
  WbIncandescentTwoTone,
  Settings: SettingsIcon,
} = Icons;

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox, menu } = props;
  const Items = menu
    .reduce(
      (result, { Items }) => [...result, ...Items.filter(({ Block }) => Block === MENU_BLOCKS.leftSideBar || !Block)],
      []
    );
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [opens, setOpens] = useState({});
  const toggleOpens = (label, event) => {
    setOpens({ ...opens, [label]: !opens[label] });
    event.preventDefault();
  };

  const renderLeftSideBar = (Items=[]) =>
    Items.sort(sortPosition).map(item => {
      const Icon = item.Icon && Icons[item.Icon];

      const {
        renderItem = () => (
          <li key={item.Id}>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              exact
              to={item.Path}
            >
              {
                Icon &&
                <span className="sidebar-icon">
                  <Icon/>
                </span>
              }
              {item.Label}
            </NavLink>
          </li>
        ) // default type MENU_ITEM_TYPES.link
      } = [
        {
          condition: item.Type === MENU_ITEM_TYPES.subMenu,
          renderItem: () => (
            <li key={item.Id}>
              <a
                href="#/"
                onClick={(e) => toggleOpens(item.Id, e)}
                className={clsx({ active: opens[item.Id] })}
              >
                {
                  Icon &&
                  <span className="sidebar-icon">
                  <Icon/>
                </span>
                }
                <span className="sidebar-item-label">{item.Label}</span>
                <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon/>
              </span>
              </a>
              <Collapse in={opens[item.Id]}>
                <ul>
                  {renderLeftSideBar(item.Items)}
                </ul>
              </Collapse>
            </li>
          )
        },
        {
          condition: item.Type === MENU_ITEM_TYPES.externalLink,
          renderItem: () => (
            <li key={item.Id}>
              <Link
                to={{ pathname: item.Path }}
                target="_blank"
              >
                {
                  Icon &&
                  <span className="sidebar-icon">
                  <Icon/>
                </span>
                }
                {item.Label}
              </Link>
            </li>
          )
        }
      ].find(({ condition }) => condition) || {};

      return renderItem();
  });

  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          {/*<div className="sidebar-header">*/}
          {/*  <span>Navigation menu</span>*/}
          {/*</div>*/}
          <ul>
            {
              renderLeftSideBar(Items)
            }
          </ul>
          <div className="sidebar-menu-box">
            <div className="sidebar-header">
              <span>Resources</span>
            </div>
            <ul>
              <li>
                <a
                  className="font-weight-normal"
                  href="https://foresidegroup.com/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <span className="sidebar-icon">
                    <LinkTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">Web Site</span>
                </a>
              </li>
              <li>
                <a
                  className="font-weight-normal"
                  href="https://docs.uifort.com/bamburgh-react-admin-dashboard-pro-docs"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <LinkTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">Documentation</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  menu: state.ThemeOptions.menu
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);

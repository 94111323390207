import { stringify } from 'querystring';
import { getAxios } from './../../utils/axios';

export const getMenus = () =>
  getAxios().get('/menus');

export const getMenuItems = () =>
  getAxios().get('/menu-items');

export const getSystemFields = () =>
  getAxios().get('/pf-admin/settings/1.0.0');

export const updateSystemFields = ({ _id, ...data }) =>
  getAxios().put('/pf-admin/settings/1.0.0', data);

export const getCustomUserConfig = () =>
  getAxios().get('/client/user/config');

export const createCustomUserConfig = (data) =>
  getAxios().post('/client/user/config', data);

export const updateCustomUserConfig = (data) =>
  getAxios().put('/client/user/config', data);

export const getClientConfig = () =>
  getAxios().get('/profile/config');

export const getUserConfig = () =>
  getAxios().get('/profile/user/config');

export const getReportsConfig = () =>
  getAxios().get('/reports/config');

export const getGridViewConfig = () =>
  getAxios().get('/grid-view/config');

export const getDashboardConfig = () =>
  getAxios().get('/dashboard/config');

export const getNotifications = (query) =>
  getAxios().get(`/notifications?${stringify({ ...query, 'CreatedAt[$ne]': Date.now() })}`)
    .then(data => Array.isArray(data) ? data : []);

export const createNotification = (data) =>
  getAxios().post(`/notifications`, data);

export const updateNotification = (id, data) =>
  getAxios().put(`/notifications/${id}`, data);

export const removeNotification = (id) =>
  getAxios().delete(`/notifications/${id}`);

export const getApiConfig = () =>
  getAxios().get('/api-config');

export const createApiConfig = (data) =>
  getAxios().post('/api-config', data);

export const createRequestNotification = (data) =>
  getAxios().post(`/notifications/request`, data);

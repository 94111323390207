import React from 'react';
import {
  Box,
} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const LinerProgressBar = (props = {}) => {
  const {
    progress = 0,
    className = '',
    sx = {},
    bgColor,
    progressColor,
    transition,
    ...otherProps
  } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgress
        className={className}
        variant="determinate"
        value={progress}
        {...otherProps}
        sx={{
          ...sx,
          ...(
            !bgColor
              ? {}
              : {
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: bgColor
                },
              }
          ),
          ...(
            !progressColor
              ? {}
              : {
                [`& .${linearProgressClasses.bar}`]: {
                  backgroundColor: progressColor,
                  ...(
                    !transition
                      ? {}
                      : {
                        transition
                      }
                  )
                },
              }
          )
        }}
      />
    </Box>
  )
}

export default LinerProgressBar;

const classes = {
  root: 'StyledCheckbox-root'
};

const createStyles = () => ({
  [`&.${classes.root}`]: {
    '& .MuiCheckbox-root': {
      padding: '4px'
    }
  }
});

export default { createStyles, classes };
export { createStyles, classes };

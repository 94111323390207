import React, { useState, useCallback } from 'react';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  styled, Grid, Box
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthService } from './../../../services';
import Wysiwyg from '../Wysiwyg';
import Note from './Note'



const Notes = props => {
  const {
    value: notes,
    label,
    access,
    handleChange
  } = props;

  const { Email, FirstName = '', LastName = '', UserId } = AuthService.getUser();

  const [isOpen, setOpen] = useState(false);
  const [note, setNote] = useState({ user: { Email, FirstName, LastName, UserId }, date: new Date(), content: '' });

  const closeModal = () => setOpen(false);

  const onChange = (e) => {
    setNote(prevNote => ({ ...prevNote, content: e.target.value }))
  };

  const handleSave = useCallback(() => {
    closeModal();
    handleChange({ target: { value: [...notes, { ...note, date: new Date() }] } });
    setNote({ user: { Email, FirstName, LastName, UserId }, date: new Date(), content: '' });
  }, [note, notes, handleChange]);

  const handleEdit = useCallback((value, index) => {
    const nextNotes = [...notes];
    nextNotes[index] = { ...nextNotes[index], content: value, date: new Date() };

    handleChange({ target: { value: nextNotes } });
  }, [notes, handleChange]);

  const handleDelete = useCallback((index) => {
    const nextNotes = [...notes];
    nextNotes.splice(index, 1);

    handleChange({ target: { value: nextNotes } });
  }, [notes, handleChange]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Wysiwyg
          access={access}
          value={note.content}
          handleChange={onChange}
          height={200}
        />
        <Box
          sx={{
            float: 'right',
            padding: '15px 0px'
          }}
        >
          <Button
            variant='contained'
            className="btn-save"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Box>
      </Grid>
      <Grid item xs={4}>
      </Grid>

      {
        notes.map((note, i) => (
          <Grid key={`note-details-${i}`} item xs={12} className="mt-5">
            <Note
              editable={access && note?.user?.UserId === UserId}
              value={note}
              onChange={value => handleEdit(value, i)}
              onDelete={() => handleDelete(i)}
            />
            <Divider className='mt-3 mb-2' />
          </Grid>
        ))
      }
    </Grid>
  )
};

export default Notes;

import { useState, useCallback } from 'react';

const minDrawerWidth = 100;
const maxDrawerWidth = 1000;

const useResizeWidth = (defaultWidth = 380) => {
  const [width, setWidth] = useState(defaultWidth);

  const handleMouseDown = () => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
  };

  const handleMouseMove = useCallback(e => {
    const newWidth = e.clientX - document.body.offsetLeft;
    if (newWidth > minDrawerWidth && newWidth < maxDrawerWidth) {
      setWidth(newWidth);
    }
  }, []);

  return [width, handleMouseDown];
};

export default useResizeWidth;

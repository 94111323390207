const prefix = 'StyledTreeItem';
const classes = {
  root: `${prefix}Root`,
  content: `${prefix}Content`,
  hasChildren: `${prefix}HasChildren`,
  group: `${prefix}Group`,
  expanded: `${prefix}Expanded`,
  label: `${prefix}Label`,
  labelRoot: `${prefix}LabelRoot`,
  labelIcon: `${prefix}LabelIcon`,
  clientName: `${prefix}ClientName`,
  actionBtn: `${prefix}ActionBtn`
};

const createStyles = ({ theme, ...props }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: 'transparent !important'
    },
    // '&:focus > $content, &$selected > $content': {
    //   backgroundColor: `var(--tree-view-bg-color, #e8e8e8)`,
    //   color: 'var(--tree-view-color)',
    // },
    // '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
    //   backgroundColor: 'transparent',
    // },
  },
  [`& .${classes.content}`]: {
    color: theme.palette.text.secondary,
    padding: '10px 8px 10px 10px',
    fontWeight: theme.typography.fontWeightMedium,
    borderBottom: `1px solid ${theme.palette.headerBlockBackground}`,
    // '$expanded > &': {
    //   fontWeight: theme.typography.fontWeightRegular,
    // },
  },
  [`& .${classes.hasChildren}`]: {
    fontWeight: theme.typography.fontWeightBold,
  },
  [`& .${classes.group}`]: {
    // marginLeft: 20,
    // '& $content': {
    //   paddingLeft: theme.spacing(2),
    // },
    marginLeft: '15px !important',
    paddingLeft: '17px !important',
    // borderLeft: props.tree?.borderLeft || `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`
  },
  [`& .${classes.expanded}`]: {},
  [`& .${classes.label}`]: {
    // fontWeight: 'inherit',
    color: 'inherit',
  },
  [`& .${classes.labelRoot}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 0px 6px 0px',
    '&:hover > $actionBtn': {
      opacity: 1
    },
    '& .MuiTextField-root': {
      marginRight: theme.spacing(1),
      // width: '25ch',
    }
  },
  [`& .${classes.labelIcon} svg:not(.defaultEndIcon)`]: {
    fontSize: '22px !important',
    marginRight: theme.spacing(1),
  },
  [`& .${classes.clientName}`]: {
    // fontWeight: 'inherit',
    flexGrow: 1
  },
  [`& .${classes.actionBtn}`]: {
    // opacity: '0',
    backgroundColor: '#ffffff',
    padding: '0px',
    position: 'relative',
    margin: '0 2px',
    zIndex: 100
  }
});

export {
  classes,
  createStyles
};

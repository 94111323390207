import clsx from 'clsx';
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import {
  IconButton,
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  ClickAwayListener,
  Grow,
  Fade,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Fab as MuiFab,
  Typography,
  Box,
  SvgIcon,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { ReactComponent as SupportIcon } from '../../../assets/images/icons/support.svg';
import { ReactComponent as HelpIcon } from '../../../assets/images/icons/help.svg';
import { ReactComponent as PlusIcon } from '../../../assets/images/icons/plus.svg';
import { ReactComponent as TreeViewIcon } from '../../../assets/images/icons/tree-view.svg';
import { ReactComponent as CardViewIcon } from '../../../assets/images/icons/card-view.svg';
import { ReactComponent as GridViewIcon } from '../../../assets/images/icons/grid-view.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/icons/filter.svg';
import { ReactComponent as ArrowDownIcon } from '../../../assets/images/icons/arrow-down.svg';

import { useWidth } from './../../../hooks';
import { createDispatches } from './../../../reducers/functions';
import { checkAccess, sortPosition } from './../../../utils/common';
import HeaderSearch from './../../layout-components/HeaderSearch';
import HeaderMenu from './../HeaderMenu';
import { LoadingButton, RenderField } from './../../controls';
import { dialogStyles, fabStyles } from './../../../assets/styles';
import { DISPLAY_CONTROLLERS, ENDPOINTS, MENU_BLOCKS, MENU_ITEM_TYPES, METHOD_GET } from '../../../constants';
import HeaderDots from "../HeaderDots";
import HeaderUserbox from "../HeaderUserbox";

const { Close: CloseIcon } = Icons;
const { createStyles, classes } = dialogStyles;
const { createStyles: createFabStyles, classes: fabClasses } = fabStyles;

const Dialog = styled(MuiDialog)(createStyles);
const Fab = styled(MuiFab)(createFabStyles);

const RenderTopSideBar = (props) => {
  const { menuRef, Items = [] } = props;
  const location = useLocation();
  const theme = useTheme();

  const [open, setOpen] = useState({});
  const anchorRef =  useRef({});

  const handleToggle = (id) => {
    setOpen({
      ...open,
      [id]: !open[id]
    });
  }

  const handleClose = (event, id) => {
    setOpen({
      // ...open,
      [id]: false
    });
  };

  const handleListKeyDown = (event, id) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen({
        ...open,
        [id]: false
      });
    } else if (event.key === 'Escape') {
      setOpen({
        ...open,
        [id]: false
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center'
      }}
      ref={menuRef}
    >
      {
        Items.sort(sortPosition).map((item, i) => {
          // const Icon = item.Icon && Icons[item.Icon];
          const {
            renderItem = () => {

              return (
                <Fab
                  key={item.Id}
                  variant="extended"
                  size="small"
                  id="composition-button"
                  className={`white-space-nowrap font-weight-bold ${fabClasses.root}`}
                  aria-haspopup="true"
                  component={Link}
                  to={item.Path}
                  style={
                    item.Path === location.pathname
                      ? {
                        background: theme.palette.primary.main,
                      }
                      : {
                        background: 'none',
                        color: theme.palette.defaultTextColor,
                      }}
                >
                  {/* <Icon sx={{ mr: 1 }}/> */}
                  {item.Label}
                </Fab>
              )
            } // default type MENU_ITEM_TYPES.link
          } = [
            {
              condition: item.Type === MENU_ITEM_TYPES.subMenu,
              renderItem: () => {

                return (
                  <React.Fragment key={item.Id}>
                    <Fab
                      variant='extended'
                      size="small"
                      ref={ref => {
                        anchorRef.current[item.Id] = ref;
                      }}
                      id="composition-button"
                      className={`white-space-nowrap font-weight-bold ${fabClasses.root}`}
                      aria-controls={open[item.Id] ? 'composition-menu' : undefined}
                      aria-expanded={open[item.Id] ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={() => handleToggle(item.Id)}
                      style={
                        item.Path === location.pathname
                          ? {
                            background: theme.palette.primary.main
                          }
                          : {
                            background: 'none',
                            color: theme.palette.defaultTextColor
                          }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignContent: 'center',
                          justifyContent: 'center',
                          gap: '5px',
                          height: '100%',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'center',
                          }}
                        >
                          { item.Label }
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            alignContent: 'center',
                          }}
                        >
                          <SvgIcon
                            sx={{
                              width: '10px',
                              height: '7px'
                            }}
                            viewBox="0 0 10 7"
                            component={ArrowDownIcon}
                          />
                        </Box>
                      </Box>
                    </Fab>
                    <Popper
                      className="popper-under"
                      open={!!open[item.Id]}
                      anchorEl={anchorRef.current[item.Id]}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === 'bottom-start' ? 'left top' : 'left bottom',
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={(e) => handleClose(e, item.Id)}
                            >
                              <MenuList
                                autoFocusItem={!!open[item.Id]}
                                id="composition-menu"
                                aria-labelledby="composition-button"
                                onKeyDown={(e) =>
                                  handleListKeyDown(e, item.Id)}
                              >
                                {
                                  item.Items.map(item => (
                                    <MenuItem
                                      key={item.Id}
                                      onClick={(e) => handleClose(e, item.Id)}
                                      component={Link}
                                      to={item.Path}
                                    >
                                      <ListItemText>
                                        { item.Label }
                                      </ListItemText>
                                    </MenuItem>
                                  ))
                                }
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </React.Fragment>
                )
              }
            },
            {
              condition: item.Type === MENU_ITEM_TYPES.externalLink,
              renderItem: () => (
                <Fab
                  className={`font-weight-bold ${fabClasses.root}`}
                  key={item.Id}
                  variant='text'
                  id="composition-button"
                  aria-haspopup="true"
                  component={Link}
                  to={{ pathname: item.Path }}
                  target="_blank"
                  style={
                    item.Path === location.pathname
                    ? {
                      background: theme.palette.primary.main,
                    }
                    : {
                      background: 'none',
                      color: theme.palette.defaultTextColor,
                    }}
                >
                  {item.Label}
                </Fab>
              )
            }
          ].find(({ condition }) => condition) || {};

          return renderItem();
        })
      }
    </div>
  )
};
const fieldsType = 'filter';
const field = 'docTypes';
const HeaderTopMenu = (props) => {
  const { pageTitle } = props;
  const menuRef = useRef(null);
  // const width = useWidth();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const {
    setSidebarToggleMobile,
    setNavMenu,
    setSearch,
    setDocTypes,
    setDocTreeOptions,
    setValidateModal,
  } = createDispatches(useDispatch());
  const {
    menu,
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    pageButtonsProps,
    contentSaving,
    pageFormErrors,
    search,
    docsConfig,
    docMenu,
    docTreeOptions,
    isShowValidateModal,
  } = useSelector(state => state.ThemeOptions);

  const anchorRef = useRef(null);
  const anchorMenuRef = useRef(null);

  // const [openModal, setOpenModal] = useState(false);
  const [openActions, setOpenActions] = useState(false);
  const [openMenuActions, setOpenMenuActions] = useState(false);
  const [selectedFieldItems, setSelectedFieldItems] = useState({ [fieldsType]: {} });

  const Items = useMemo(() =>
      menu
        .reduce(
          (result, { Items }) => [...result, ...Items.filter(({ Block }) => Block === MENU_BLOCKS.topSideBar || !Block)],
          []
        ),
    [menu]
  );
  const profileItems = useMemo(() =>
      menu
        .reduce(
          (result, { Items }) => [...result, ...Items.filter(({ Block }) => Block === MENU_BLOCKS.profileSideBar)],
          []
        ),
    [menu]
  );

  const getSources = useCallback(() => {

    return Object.keys(docsConfig)
      .filter(docType => checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_GET] }))
      .map(docType => ({
        CheckBoxId: docType,
        ClassName: "checkbox-label",
        Name: docsConfig[docType].DocumentType || docType,
        // id: docType,
        // label: docsConfig[docType].DocumentType || docType
      }))
  }, [docsConfig]);
  const handleToggleActions = useCallback(() => {
    setOpenActions(!openActions);
  }, [openActions]);
  const handleToggleMenuActions = useCallback(() => {
    setOpenMenuActions(!openMenuActions);
  }, [openMenuActions]);
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const isModify =  useMemo(
    () =>
      checkAccess({
        path: pageButtonsProps?.selectedDoc?.path,
        methods: pageButtonsProps?.selectedDoc?.methods
      }),
    [
      pageButtonsProps?.selectedDoc?.path,
      pageButtonsProps?.selectedDoc?.methods
    ]
  );

  // useEffect(() => {
  //   if (
  //     !menu ||
  //     !width ||
  //     menuRef?.current?.clientWidth === undefined
  //   ) return;
  //
  //   const [{ Items = [], ...MainMenu } = {}] = menu;
  //
  //   if (
  //     !((width - menuRef?.current?.clientWidth) > 700 && Items.some(({ Changed }) => Changed)) &&
  //     !((width - menuRef?.current?.clientWidth) < 553)
  //   ) return;
  //
  //   let changed = false;
  //   if ((width - menuRef?.current?.clientWidth) > 700 && Items.some(({ Changed }) => Changed)) {
  //     const nextItems = Items.sort(sortPosition).map((Item) => {
  //       if (!changed && Item.Changed) {
  //         changed = true;
  //
  //         return ({ ...Item, Block: MENU_BLOCKS.topSideBar, Changed: false});
  //       }
  //
  //       return Item;
  //     });
  //
  //     setNavMenu([{...MainMenu, Items: nextItems}])
  //   }
  //
  //   if ((width - menuRef?.current?.clientWidth) < 553) {
  //     const nextItems = Items.sort(sortPosition).reverse().map((Item) => {
  //       if (!changed && Item.Block === MENU_BLOCKS.topSideBar) {
  //         changed = true;
  //
  //         return ({ ...Item, Block: MENU_BLOCKS.leftSideBar, Changed: true });
  //       }
  //
  //       return Item;
  //     });
  //
  //     setNavMenu([{...MainMenu, Items: nextItems}])
  //   }
  // }, [menu, width, menuRef?.current?.clientWidth]);

  const isShowTopNavPanel = useMemo(
    () => (
      (pageButtonsProps?.selectedDoc && isModify) ||
      (pageButtonsProps?.childTypes || []).length ||
      (pageButtonsProps?.isShowEditBtns && isModify) ||
      pageButtonsProps?.isShowTopNavPanel
    ),
    [
      pageButtonsProps?.selectedDoc,
      isModify,
      pageButtonsProps?.childTypes,
      pageButtonsProps?.isShowEditBtns,
      pageButtonsProps?.isShowTopNavPanel
    ]
  );

  return !!Items.length && (
    <Box
      className='app-header'
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gridTemplateRows: 'auto',
        padding: '0px'
      }}
    >
      <Box
        className='app-header--wrapper'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >

        <Box
          className='app-header--pane w-100'
          sx={{
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row',
            // gridTemplateColumns: 'repeat(2, 1fr)',
            // gridTemplateRows: 'auto',
            height: '100%',
            width: '100%',
            maxWidth: '1800px',
            padding: '0px',
            backgroundColor: theme.palette.white,
            gap: '20px'
          }}
        >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'flex-start',
                marginLeft: Items.length > 10 ? '30px' : '80px',
                height: '100%',
                width: '100%',
                gap: '20px'
              }}
            >
              <div className='bg-logo'></div>
              <RenderTopSideBar
                {...props}
                menuRef={menuRef}
                Items={Items}
              />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'nowrap',
              alignContent: 'center',
              justifyContent: 'flex-end',
              gap: '20px',
              padding: '0px 10px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <Button
                variant="text"
                className=""
                sx={{
                  padding: '0px !important'
                }}
                onClick={() => {
                  window.open('https://innometrix.net/support', '_blank');
                }}
              >
                <SvgIcon
                  // htmlColor={mapDocIcons[docType]?.color}
                  component={SupportIcon}
                />
              </Button>
            </Box>
            {/*<Box*/}
            {/*  sx={{*/}
            {/*    display: 'flex',*/}
            {/*    alignContent: 'center',*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <Button*/}
            {/*    variant="text"*/}
            {/*    className=""*/}
            {/*    sx={{*/}
            {/*      padding: '0px !important'*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <SvgIcon*/}
            {/*      component={HelpIcon}*/}
            {/*      sx={{*/}
            {/*        width: '20px',*/}
            {/*        height: '20px',*/}
            {/*      }}*/}
            {/*      viewBox="0 0 20 20"*/}
            {/*    />*/}
            {/*  </Button>*/}
            {/*</Box>*/}
            <HeaderDots />
            <HeaderUserbox
              profileItems={profileItems}
            />
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
      {
        isShowTopNavPanel && (
          <Box
            className='app-header--wrapper'
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
              padding: '10px 25px 40px 30px',
              height: '100%',
              backgroundColor: theme.palette.grey.lightLight,
              maxWidth: '1800px',
              width: '100%'
            }}
          >
            <Box
              className="app-header--pane w-100 pl-4"
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridTemplateRows: 'auto',
                alignContent: 'center',
                // padding: '0px 10px'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-start',
                  gap: '30px',
                  height: '100%',
                }}
              >
                <Box
                  className="font-weight-bolder font-size-xl"
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                  }}
                >
                  { pageTitle }
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center'
                  }}
                >
                  {
                    !!docMenu.length && (
                      <>
                        <Button
                          variant='contained'
                          className="btn-save"
                          ref={anchorMenuRef}
                          aria-controls={openMenuActions ? 'menu-list-grow' : undefined}
                          aria-haspopup="true"
                          onClick={handleToggleMenuActions}
                          sx={{
                            padding: '10px 24px 10px 16px !important',
                            gap: '8px',
                          }}
                        >
                          <Box>
                            <SvgIcon
                              // htmlColor={mapDocIcons[docType]?.color}
                              component={PlusIcon}
                              sx={{
                                width: '18px',
                                height: '18px',
                              }}
                              viewBox="0 0 18 18"
                            />
                          </Box>
                          <Box>
                            Add
                          </Box>
                        </Button>
                        <Popper
                          open={openMenuActions}
                          anchorEl={anchorMenuRef.current}
                          role={undefined}
                          transition
                          disablePortal
                          placement={'right-start'}
                        >
                          {
                            ({ TransitionProps, placement }) => (
                              <Fade
                                {...TransitionProps}
                              >
                                <Paper>
                                  <ClickAwayListener
                                    onClickAway={() => setOpenMenuActions(false)}
                                  >
                                    <MenuList autoFocusItem={openMenuActions} id="menu-list-grow">
                                      {
                                        docMenu
                                          .filter(({ ignoreTopDocMenu }) => !ignoreTopDocMenu)
                                          .map(({ title, onClick }) => (
                                            <MenuItem
                                              key={title}
                                              onClick={onClick}
                                            >
                                              { title }
                                            </MenuItem>
                                          ))
                                      }
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Fade>
                            )
                          }
                        </Popper>
                      </>
                    )

                  }
                  {
                    !docMenu.length && pageButtonsProps?.selectedDoc?.title && isModify &&
                      <Button
                        variant='contained'
                        className="btn-save"
                        sx={{
                          padding: '10px 24px 10px 16px !important',
                          gap: '8px',
                        }}
                        onClick={(event) => {
                          event.preventDefault();
                          pageButtonsProps.onAdd(pageButtonsProps.selectedDoc.docType);
                        }}
                      >
                        <Box>
                          <SvgIcon
                            // htmlColor={mapDocIcons[docType]?.color}
                            component={PlusIcon}
                            sx={{
                              width: '18px',
                              height: '18px',
                            }}
                            viewBox="0 0 18 18"
                          />
                        </Box>
                        <Box>
                          { pageButtonsProps.selectedDoc.title }
                        </Box>
                      </Button>
                  }
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  justifyContent: 'flex-end',
                  gap: '10px'
                }}
              >
                {
                  !!pageButtonsProps?.isShowTopFilters && (
                    <Box
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: 'auto',
                        alignContent: 'center',
                        width: '414px',
                        paddingTop: '20px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignContent: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignContent: 'center',
                            backgroundColor: theme.palette.white,
                            width: '112px',
                            padding: '10px',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            border: `1px solid ${theme.palette.grey.light}`
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                            }}
                          >
                            <Button
                              variant="text"
                              className=""
                              sx={{
                                padding: '0px !important'
                              }}
                              onClick={() => history.push('/documenttree')}
                            >
                              <SvgIcon
                                htmlColor={
                                  location.pathname === '/documenttree'
                                    ? theme.palette.green.accent
                                    : theme.palette.grey.mid
                                }
                                component={TreeViewIcon}
                              />
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button
                              variant="text"
                              className=""
                              sx={{
                                padding: '0px !important'
                              }}
                              onClick={() => history.push('/cards')}
                            >
                              <SvgIcon
                                htmlColor={
                                  location.pathname === '/cards'
                                    ? theme.palette.green.accent
                                    : theme.palette.grey.mid
                                }
                                component={CardViewIcon}
                              />
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                            }}
                          >
                            <Button
                              variant="text"
                              className=""
                              sx={{
                                padding: '0px !important'
                              }}
                              onClick={() => history.push('/documents')}
                            >
                              <SvgIcon
                                htmlColor={
                                  location.pathname === '/documents'
                                    ? theme.palette.green.accent
                                    : theme.palette.grey.mid
                                }
                                component={GridViewIcon}
                              />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignContent: 'center',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            width: '262px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '30px'
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                            }}
                          >
                            <Button
                              variant="text"
                              ref={anchorRef}
                              aria-controls={openActions ? 'menu-list-grow' : ''}
                              aria-haspopup="true"
                              onClick={handleToggleActions}
                              sx={{
                                padding: '0px !important'
                              }}
                            >
                              <SvgIcon
                                // htmlColor={mapDocIcons[docType]?.color}
                                component={FilterIcon}
                              />
                            </Button>
                            <Popper
                              open={openActions}
                              anchorEl={anchorRef.current}
                              transition
                              disablePortal
                              placement={'bottom-end'}
                            >
                              {
                                ({ TransitionProps, placement }) => (
                                  <Fade
                                    {...TransitionProps}
                                  >
                                    <Box
                                      sx={{
                                        // zIndex: 10,
                                        display: 'inline-flex',
                                        backgroundColor: theme.palette.white,
                                        padding: '20px 20px 20px 20px',
                                        width: '330px',
                                        height: '100%',
                                        margin: '20px 5px 0px 0px !important',
                                        border: `1px solid ${theme.palette.grey.light}`,
                                        borderRadius: '6px',
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener
                                          onClickAway={() => setOpenActions(false)}
                                        >
                                          <Box>
                                            <Box>
                                              <RenderField
                                                item={{
                                                  label: 'Doc Types',
                                                  field,
                                                  displayController: DISPLAY_CONTROLLERS.CheckBoxesList,
                                                  source: getSources(),
                                                }}
                                                gridOptions={{
                                                  xl: 6,
                                                  lg: 6,
                                                  md: 6
                                                }}
                                                fieldsType={fieldsType}
                                                selectedFieldItems={selectedFieldItems}
                                                setSelectedFieldItems={setSelectedFieldItems}
                                              />
                                            </Box>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                alignContent: 'center',
                                                justifyContent: 'center',
                                                padding: '30px 10px 10px 0px',
                                                gap: '10px'
                                              }}
                                            >
                                              <Button
                                                variant='outlined'
                                                className="btn-reset"
                                                onClick={() => {
                                                  setSelectedFieldItems({
                                                    ...selectedFieldItems,
                                                    [fieldsType]: {
                                                      ...selectedFieldItems[fieldsType],
                                                      [field]: []
                                                    }
                                                  });
                                                  setDocTypes([]);
                                                  setDocTreeOptions({
                                                    ...docTreeOptions,
                                                    recalculate: true
                                                  });
                                                }}
                                              >
                                                Cancel Filter
                                               </Button>
                                              <Button
                                                variant='contained'
                                                className="btn-save"
                                                onClick={() => {
                                                  setDocTypes(selectedFieldItems[fieldsType]?.[field] || []);
                                                  setDocTreeOptions({
                                                    ...docTreeOptions,
                                                    recalculate: true
                                                  });
                                                }}
                                              >
                                                Apply
                                              </Button>
                                            </Box>
                                          </Box>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Box>
                                  </Fade>
                                )
                              }
                            </Popper>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignContent: 'center',
                            }}
                          >
                            <HeaderSearch
                              {...{ setSearch, search }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )
                }
                {
                  !pageButtonsProps?.isShowTopFilters && pageButtonsProps?.isShowEditBtns && isModify && (
                    <div>
                      <LoadingButton
                        variant='outlined'
                        onClick={pageButtonsProps.handleFieldsCancel}
                        className="w-90px mr-2 b-1"
                        hide={contentSaving}
                      >
                        Cancel
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        onClick={() => {
                          if (pageFormErrors.length) {
                            console.log('pageFormErrors: ', pageFormErrors)

                            return setValidateModal(true)
                          }

                          return pageButtonsProps.handleFieldsSave();
                        }}
                        className={`mr-2 ${contentSaving ? 'w-100px' : 'w-90px'}`}
                        loading={contentSaving}
                      >
                        { contentSaving ? 'Saving...' : 'Save' }
                      </LoadingButton>
                    </div>
                  )
                }
              </Box>
              <button
                className={clsx(
                  'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
                  { 'is-active': sidebarToggleMobile }
                )}
                onClick={toggleSidebarMobile}>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </Box>
            <Dialog open={isShowValidateModal} onClose={() => setValidateModal(false)} classes={{ paper: 'shadow-lg' }}>
              <div className="position-relative mb-2">
                <IconButton
                  aria-label="close"
                  className={`${classes.closeButton} mb-3`}
                  onClick={() => setValidateModal(false)}
                  size="large">
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="text-center p-5">
                <h5>You should fill rest required field(s):</h5>
                <div className="mb-2">
                  {
                    pageFormErrors.map((field, i) => (
                      <p key={i} className="mb-0 point text-danger text-left font-weight-bold">{field}</p>
                    ))
                  }
                </div>
                <div className="pt-4">
                  <Button
                    onClick={() => setValidateModal(false)}
                    className="btn-primary mx-1"
                  >
                    <span className="btn-wrapper--label">OK</span>
                  </Button>
                </div>
              </div>
            </Dialog>
          </Box>
        )
      }
      </Box>
    </Box>
  );
};

export default HeaderTopMenu;

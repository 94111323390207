import React from 'react';
import { Card as MuiCard, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';

import { tableBlockStyles } from './../../../assets/styles';

const { classes, createStyles } = tableBlockStyles;
const Card = styled(MuiCard)(createStyles);

const Container = (props) => {
  const { children, className = '' } = props;
  const theme = useTheme();

  return (
    <Card
      className={`${classes.rootElement} ${className}`}
    >
      { children }
    </Card>
  );
};

export default Container;

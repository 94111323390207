import React, { useState, useEffect, useCallback } from 'react';
import { Table as MuiTable, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MONTH_ABBR_SHORT, THEME_SIZES, TITLES, CALCULATIONS_GROUP_ESTIMATED, UOM_FORMATS } from '../../../constants';
import { round, getTotalByGridValues, composeGridValues, uomFormat, getTotalByMethod } from '../../../utils/common';
import { createStyles } from '../../../assets/styles/tableResponsive';
import { formatNumber } from 'devextreme/localization';

const Table = styled(MuiTable, {
  shouldForwardProp: (prop) => prop !== '$columns' && prop !== '$breakpoint'
})(createStyles);

export default (props) => {
  const {
    handleChange,
    selectedFieldItems,
    handleGridChange,
    docType,
    fieldsType,
    isModal = false,
    fieldName = '',
    gridValues: GridValues,
    editable = true,
    disableTitle = false
  } = props;
  const method = selectedFieldItems[`${docType}Agg`];

  const prefix = fieldName.replace(`${docType}GridValues`, '');

  // console.log('fieldsType ======================: ', fieldsType)
  // console.log('fieldName ======================: ', fieldName)
  // console.log('prefix ======================: ', prefix)
  // console.log('GridValues ======================: ', GridValues)

  useEffect(() => {
    if (!prefix || GridValues) return;

    const composeNexGridData = (defaultGridValues = {}) =>
      Object.keys(defaultGridValues || {}).reduce((nextGridValues, year) => ({
        ...nextGridValues,
        [year]: defaultGridValues[year].map(() => 0)
      }), {});
    const {
      getDefaultValues = () => selectedFieldItems?.[`${docType}GridValues`],
      composeNextState = (defaultGridValues) => ({
        ...selectedFieldItems,
        [fieldName]: composeNexGridData(defaultGridValues)
      })
    } = [
      {
        condition: Boolean(fieldsType),
        getDefaultValues: () => selectedFieldItems?.[fieldsType]?.[`${docType}GridValues`],
        composeNextState: (defaultGridValues) => ({
          ...selectedFieldItems,
          [fieldsType]: {
            ...selectedFieldItems[fieldsType],
            [fieldName]: composeNexGridData(defaultGridValues)
          }
        })
      }
    ].find(({ condition }) => condition) || {};

    const emptyValues = composeNexGridData(getDefaultValues());
    handleChange({ target: { value: emptyValues } });

  }, []);

  const transientProps = {
    $columns: [TITLES.YEAR, ...MONTH_ABBR_SHORT, TITLES.TOTAL],
    $breakpoint: isModal ? THEME_SIZES.md : THEME_SIZES.lg
  };

  return GridValues
    ? (
      <>
        {
          !disableTitle && (
            <Typography mt={1}>{ (prefix || CALCULATIONS_GROUP_ESTIMATED).toUpperCase() }</Typography>
          )
        }
        <Table className={`grid-details table table-bordered text-nowrap mt-4 mb-4`} {...transientProps}>
          <thead>
            <tr>
              <th className="text-center w-100px">{TITLES.YEAR}</th>
              {
                MONTH_ABBR_SHORT.map((month, index) =>
                  (<th key={`${index}-th`} className="text-center">{month}</th>))
              }
              <th className="text-center w-100px">{TITLES.TOTAL}</th>
            </tr>
          </thead>
          <tbody>
          {
            Object.keys(GridValues).map(
              (year, index) => (
                <tr key={`grid-details-${index}`}>
                  <td className="text-center">{year}</td>
                  {
                    GridValues[year].map((val, subIndex) => (
                      <td key={`${index}-${subIndex}-td`} className="text-center">
                        <TextField
                          disabled={!editable}
                          className="empty-legend"
                          variant="outlined"
                          type='number'
                          label={val}
                          value={val}
                          onChange={e => handleGridChange(year, subIndex, e.target.value, prefix)}
                          onPaste={e => {
                            e.preventDefault();

                            console.log('e.clipboardData: ', e.clipboardData)

                            if (!e.clipboardData) return;

                            const nextGridValues = e.clipboardData.getData('text/plain').trim()
                              .split(/\r\n|\n|\r/)
                              .reduce((result, rows, index) => {
                                const nextYear = Number(year) + index;
                                const nextRows = !index
                                 ? rows.split('\t').slice(0, MONTH_ABBR_SHORT.length - subIndex)
                                 : rows.split('\t').slice(0, MONTH_ABBR_SHORT.length);
                                const nextSubIndex = !index ? subIndex : 0;

                                return composeGridValues({
                                  gridValues: result,
                                  year: nextYear,
                                  index: nextSubIndex,
                                  val: nextRows
                                });
                              }, GridValues);

                            handleChange({ target: { value: nextGridValues } });
                          }}
                        />
                      </td>
                    ))
                  }
                  <td className="text-center">
                    {
                      (() => {
                        const total = getTotalByMethod(GridValues[year], method);

                        return isNaN(total) ? 0 : uomFormat(UOM_FORMATS.TIME, total)
                      })()
                    }
                  </td>
                </tr>
              )
            )
          }
          </tbody>
        </Table>
        <div className="float-right font-weight-bold pr-3 pb-3">
          Total: {

            (() => {
              const total = getTotalByGridValues(GridValues, method);

              return isNaN(total) ? 0 : uomFormat(UOM_FORMATS.TIME, total)
            })()
          }
        </div>
      </>
    )
    : (<></>);
}

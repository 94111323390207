import React from 'react';
import {
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { DISPLAY_CONTROLLERS, FIELD_KEYS } from './../../../constants';
import { dialogStyles, spanHeaderStyles } from './../../../assets/styles';
import { RenderField  } from './../';

const { createStyles: createSpanHeaderStyles } = spanHeaderStyles;
const { classes: dialogClasses, createStyles: createDialogStyles } = dialogStyles;

const Dialog = styled(MuiDialog)(createDialogStyles);
const StyledSpan = styled('span')(createSpanHeaderStyles);

const ModalTimeFields = props => {
  const {
    controlDoc,
    openGridModal,
    toggleGridModal,
    selectedItem,
    selectedFieldItems,
    handleGridChange,
    handleFieldsCancel,
    handleFieldsSave,
    docType,
    fields = [],
    validateAccessDocument,
    fieldsType,
    setSelectedFieldItems,
    userHandleChange
  } = props;

  const { value: gates = [] } = (selectedItem?.[FIELD_KEYS.Overview] || [])
    .find(({ field }) => field === `${docType}OpenGateRequests`) || {};
  const { gate = 0 } = [...gates].pop() || {};
  // console.log('gate: ', gate)

  return (
    <Dialog
      key="grid-data-dialog"
      scroll="body"
      fullWidth={true}
      maxWidth={false}
      open={openGridModal}
      onClose={toggleGridModal}
      classes={{
        paper: 'modal-content border-0 bg-white rounded-lg p-3 p-xl-0'
      }}
    >
      <DialogTitle className={dialogClasses.root}>
        <Typography id="form-dialog-title" className="text-lg-center">
          { selectedItem && !controlDoc ? (<><StyledSpan>Document:</StyledSpan> { selectedItem.clientName } (Gate {gate}) </>) : ''}
          { selectedItem && controlDoc ? (<><StyledSpan>Document:</StyledSpan> { selectedItem.clientName } </>) : ''}
        </Typography>
        <IconButton
          aria-label="close"
          className={dialogClasses.closeButton}
          onClick={toggleGridModal}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {
          fields
            .filter(({ field = '' }, i) => controlDoc || !field.includes('Actuals') || (field.includes('Actuals') && gate >= 5)) // need move to config
            .map((item, i) => {

              return (
                <div key={item.field || item.label}>
                  <RenderField
                    item={item}
                    fieldsType={fieldsType}
                    selectedFieldItems={selectedFieldItems}
                    docType={docType}
                    access={controlDoc || ((!item.field.includes('Actuals') && gate < 5 || item.field.includes('Actuals') && gate >= 5) && gate < 6)} // need move to config
                    handleGridChange={handleGridChange}
                    validateAccessDocument={validateAccessDocument}
                    setSelectedFieldItems={setSelectedFieldItems}
                    userHandleChange={userHandleChange && (event => userHandleChange({ field: item.field, event }))}
                  />
                </div>
              )
            })
        }
      </DialogContent>
      <DialogActions className="p-4 w-100">
        <Button
          variant='outlined'
          onClick={() => {
            // handleFieldsCancel();
            toggleGridModal();
          }}
        >
          <span className="btn-wrapper--label">Cancel</span>
        </Button>
        <Button variant='contained' onClick={handleFieldsSave} className="m-2">
          <span className="btn-wrapper--label">Save</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default ModalTimeFields;

import React, { useState, useCallback, useEffect } from 'react';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import {
  Badge,
  Menu,
  Button,
  List,
  ListItem,
  Divider, Box, SvgIcon
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as UserIcon } from '../../../assets/images/icons/user.svg'
import { ReactComponent as ArrowIcon } from '../../../assets/images/icons/arrow-down.svg'
import { AuthService } from '../../../services'
import theme from "../../../theme";

const StyledBadge = styled(Badge)({
  top: -1,
  '& .MuiBadge-badge': {
    marginRight: '8px',
    backgroundColor: 'var(--secondary)',
    color: 'var(--secondary)',
    // boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""'
    }
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0
    }
  }
});

const HeaderUserbox = (props) => {
  const { profileItems } = props;
  const [authData, setAuthData] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [redirect, setRedirect] = useState('');
  const [organization, setOrganization] = useState();
  const [authUser, setAuthUser] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    AuthService.logout();
    setRedirect('/login');
  });

  useEffect(() => {
    const authUser = AuthService.getUser();
    setAuthUser(authUser);

    const Organization = AuthService.getOrganization();
    setOrganization(Organization);

    setAuthData({
      Name: Organization?.ClientName || authUser?.FirstName || authUser?.Name,
      Email: authUser?.Email || AuthService.getDataByKey('email')
    });
  }, []);

  if (redirect) return (<Redirect to={redirect}/>);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        variant="text"
        onClick={handleClick}
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          alignContent: 'center',
          padding: '0px !important',
          height: '100%',
          gap: '5px'
        }}
      >
        <Box>
          <SvgIcon
            // htmlColor={mapDocIcons[docType]?.color}
            component={UserIcon}
            sx={{
              width: '27px',
              height: '27px',
            }}
            viewBox="0 0 27 28"
          />
          <Box
            sx={{
              position: 'absolute',
              top: '10px',
              width: '27px',
              textAlign: 'center',
              color: theme.palette.defaultTextColor,
              fontWeight: 700,
            }}
          >
            { (authUser.FirstName || ' ').split('').shift().toUpperCase() }{ (authUser.LastName || ' ').split('').shift().toUpperCase() }
          </Box>
        </Box>
        <Box
          sx={{
            padding: '12px 5px 7px 5px'
          }}
        >
          <SvgIcon
            // htmlColor={mapDocIcons[docType]?.color}
            component={ArrowIcon}
            sx={{
              width: '10px',
              height: '7px',
            }}
            viewBox="0 0 10 7"
          />
        </Box>

        {/*<div className="d-block p-0 avatar-icon-wrapper">*/}
        {/*  {*/}
        {/*    organization?.LogoUrl && (*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          backgroundImage: `url('${organization?.LogoUrl}')`,*/}
        {/*          backgroundPosition: 'center -1px',*/}
        {/*          backgroundRepeat: 'no-repeat',*/}
        {/*          width: '40px',*/}
        {/*          height: '42px',*/}
        {/*          marginRight: '10px'*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )*/}
        {/*  }*/}
        {/*  {*/}
        {/*    !organization?.LogoUrl && (*/}
        {/*      <StyledBadge*/}
        {/*        overlap="circular"*/}
        {/*        anchorOrigin={{*/}
        {/*          vertical: 'bottom',*/}
        {/*          horizontal: 'right'*/}
        {/*        }}*/}
        {/*        badgeContent=" "*/}
        {/*        classes={{ badge: 'badge-circle border-0' }}*/}
        {/*        variant="dot">*/}
        {/*      </StyledBadge>*/}
        {/*    )*/}
        {/*  }*/}
        {/*</div>*/}
        {/*<div className="line-height-1 nowrap" style={{ color: 'var(--primary)' }}>{authData.Name}</div>*/}
        {/*<div className="font-weight-bold d-xl-block pl-2 nowrap" style={{ color: 'var(--primary)' }}>{authData.Email}</div>*/}
        {/*<span className="pl-1 pl-xl-3 font-weight-bold ">*/}
        {/*  <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5 " style={{ color: 'var(--primary)', top:2, position:'relative' }} />*/}
        {/*</span>*/}
      </Button>
      <Menu
        style={{ marginTop: '30px' }}
        anchorEl={anchorEl}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0 profile-popup' }}
        onClose={handleClose}
      >
        <div className=" p-0">
          <List
            component="div"
          >
            {
              profileItems.map(({ Label, Path }) => (
                <ListItem
                  className="d-block text-left"
                  component={Link}
                  to={Path}
                >
                  { Label }
                </ListItem>
              ))
            }
            <ListItem className="d-block text-left" component={Link} to="/profile">
              Profile settings
            </ListItem>

            <ListItem className="d-block">
              <Button
                onClick={handleLogout}
                variant="contained"
                // size="small"
              >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                  </span>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </ListItem>
          </List>
        </div>
      </Menu>
    </Box>
  );
};

export default HeaderUserbox;

import React from 'react';
import { CardContent as MuiCardContent } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fieldsBlockStyles, tableBlockStyles, cardStyles } from './../../../assets/styles';

const { classes: fieldsBlockClasses, createStyles: createFieldsBlockStyles } = fieldsBlockStyles;
const { classes: tableBlockClasses, createStyles: createTableBlockStyles } = tableBlockStyles;
const { createStyles: createCardStyles } = cardStyles;

const CardContent = styled(MuiCardContent)(props => ({
  ...createFieldsBlockStyles(props),
  ...createTableBlockStyles(props)
}));
const SubCard = styled(CardContent)(createCardStyles);

const RightContentBar = (props) => {
  const { children, className } = props;

  return (
    <CardContent className={`text-right ${fieldsBlockClasses.right} ${tableBlockClasses.cellElement} ${className}`}>
      <SubCard>
        { children }
      </SubCard>
    </CardContent>
  );
};

export default RightContentBar;

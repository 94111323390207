import React from 'react';
import { Autocomplete, styled, TextField } from '@mui/material';
import { formStyles } from './../../../assets/styles';
import styles from './styles';

const { classes, createStyles } = styles;
const { classes: formClasses, createStyles: createFormStyles } = formStyles;
const StyledTextField = styled(TextField)((props) => ({
  ...createStyles(props),
  ...createFormStyles(props)
}));

export const TypeaHead = (props) => {
  const {
    defaultValue,
    options,
    getOptionLabel = ({ label = '' }) => label,
    onChange = () => {},
    label,
    placeholder
  } = props;

  return (
    <Autocomplete
      defaultValue={defaultValue}
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={params => (
        <StyledTextField
          {...params}
          variant='outlined'
          className={`mb-3 ${formClasses.textbox} ${classes.textField}`}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default TypeaHead;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

import { AuthService } from './../../../services';
import { createDispatches } from './../../../reducers/functions';

const ProtectedRoute = (props) => {
  const { setRefreshModal } = createDispatches(useDispatch());
  const isShowRefreshModal = useSelector(state => state.ThemeOptions.isShowRefreshModal);

  const location = useLocation();
  const isAuthenticated = AuthService.isAuthenticated();

  useEffect(() => {
    AuthService.resetActivity();

    if(window.self !== window.top) AuthService.logout();
  }, [location.pathname]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const secondsInactive = AuthService.getInactiveSeconds();
      const sessionActive = AuthService.getSessionActive();

      const {
        refreshModal = () => {
          // console.log('secondsInactive: ', secondsInactive);
          if (secondsInactive >= AuthService.getSessionTime()) AuthService.logout();
        }
      } = [
        {
          condition: secondsInactive >= AuthService.getSessionCountdown() && secondsInactive < AuthService.getSessionTime(),
          refreshModal: () => {
            if (!isShowRefreshModal) setRefreshModal(true);
          }
        },
        {
          condition: secondsInactive < AuthService.getSessionCountdown() && sessionActive >= AuthService.getSessionCountdown(),
          refreshModal: () => {
            // console.log('sessionActive: ', sessionActive)

            AuthService.relogin();
          }
        }
      ].find(({ condition }) => condition) || {};

      refreshModal();
    }, 1000);

    return () => clearInterval(intervalId);
  },[isAuthenticated, location.pathname, isShowRefreshModal]);

  return (
    isAuthenticated
      ? (<Route {...props} />)
      : (<Redirect to={{ pathname: '/login', state: { from: location } }}/>)
  );
};

export default ProtectedRoute;

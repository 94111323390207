import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';

const NewPassword = (props) => {
  const {
    title = 'Change Password',
    fields,
    handleSelectChange,
    SubTextComponent = () => (<></>),
    VerificationCodeComponent = () => (<></>),
    AdditionComponent = () => (<></>)
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <div className="text-center mt-4">
        <h1 className="font-size-xxl mb-1 font-weight-bold">
          { title }
        </h1>
        <SubTextComponent />
      </div>
      <div className="py-2 content-login">
        <p className="mb-1 font-weight-bold text-left">
          Password policy requirements:
        </p>
        <p className="mb-0 point text-left">
          Should contains at least 8 characters
        </p>
        <p className="mb-0 point text-left">
          Should contains numbers
        </p>
        <p className="mb-0 point text-left">
          Should contains special character
        </p>
        <p className="mb-0 point text-left">
          Should contains uppercase letters
        </p>
        <p className="mb-0 point text-left">
          Should contains lowercase letters
        </p>
      </div>
      <div className="py-4 content-login">
        <div>
          { VerificationCodeComponent() }
          <div className="mb-4">
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleSelectChange('newPassword')}
              value={fields.newPassword || ''}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="mb-3">
            <TextField
              fullWidth
              variant="outlined"
              label="Confirm Password"
              type={showPassword ? 'text' : 'password'}
              onChange={handleSelectChange('confirm_password')}
              value={fields.confirm_password || ''}
            />
          </div>
        </div>
        <AdditionComponent />
      </div>
    </>
  );
};

export default NewPassword;

import React from 'react';
import { Box } from '@mui/material';

export const LoginLogo = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url('/logo.png')`,
          backgroundPosition: 'center 15px',
          backgroundRepeat: 'no-repeat',
          backgroundSize: `245px`,
          width: '245px',
          height: '70px',
        }}
      />
    </Box>
  )
};

export default LoginLogo;

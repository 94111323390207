import { useState, useEffect } from 'react';

const useStepReviewers = (allReviewers = [], step = 0) => {
  const [reviewers, setReviewers] = useState([]);

  useEffect(() => {
    const reviewers = allReviewers.filter(({ gate }) => gate === step)
    setReviewers(reviewers);
  }, [JSON.stringify(allReviewers), step]);

  return reviewers;
};

export default useStepReviewers;

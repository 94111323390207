import { createTheme  as createMuiTheme } from '@mui/material/styles';
import shadows from './shadows';

const baseFontSize = '0.875rem';
const smFontSize = `${parseFloat(baseFontSize) * .875}rem`;
const lgFontSize = `${parseFloat(baseFontSize) * 1.1}rem`;
const primaryColor = '#358370';
const midGrey = '#868686';
const greenAccent = '#00A66E';
const white = '#fff';
const black = '#000';
const greyLight = '#E6E6E6';

const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      contrastText: white //button text white instead of black
    },
    grey: {
      300: '#fefefe',
      A100: '#f8f9ff',
      light: greyLight,
      lightLight: '#FBFBFB',
      mid: midGrey,
      dark: '#878787',
      darkDark: '#49454F',
    },
    blue: {
      mid: '#67A3FE',
      midDark: '#1C3FBC',
    },
    green: {
      accent: greenAccent,
      approved: '#5d8a7b66',
      dark: '#1C462B',
      mid: '#179232',
      midLight: '#18C93F',
      light: '#02BC9A',
    },
    orange: {
      mid: '#FF8A00'
    },
    yellow: {
      dark: '#D7AE1E',
      mid: '#FFF854'
    },
    pink: {
      dark: '#BC1C8F',
      light: '#FF4AED'
    },
    violet: {
      mid: '#9747FF'
    },
    brown: {
      mid: '#987A57'
    },
    red: {
      mid: '#C5000C'
    },
    secondary: {
      main: '#f1f1f1'
    },
    error: {
      main: '#b26450'
    },
    success: {
      main: primaryColor
    },
    info: {
      main: '#11c5db'
    },
    warning: {
      main: '#b26450'
    },
    helpers: {
      primary: '#575859',
      main: 'rgba(241, 241, 241, .035)'
    },
    white,
    black,
    defaultTextColor: '#58595b',
    defaultInputBackground: white, //'#fdeab2',
    contextButtonsBackground: '#f3f3f3' , //'#d9d9d9',
    bgHeader: white,
    // profileBackground: '#a9d18e',
    // profileBackground: '#e3e3e2',
    profileBackground: '#edf3ff',
    menuSelectedBackground: '#f1f1f1',
    headerBlockBackground: '#f0f0ef',
    changedInputBackground: 'rgba(78, 140, 122, 0.05)',
    contrastThreshold: 3,
    tonalOffset: 0.1,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 1300,
      xl: 1800
    }
  },
  shape: {
    borderRadius: 0.3
  },
  typography: {
    color: '#58595b',
    fontFamily: ['Heebo', 'sans-serif'].join(','),
    fontSize: 14
  },
  //shadows,
  shadows: Array(25).fill('none'),
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 'bold'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#070919',
          padding: '8px 16px',
          fontSize: '13px'
        },
        arrow: {
          color: '#070919'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // '& fieldset': {
          //   borderRadius: '17px',
          // }
        },
      }
    },
    // MuiCard: {
    //   styleOverrides: {
    //     root: {
    //       '&': ''
    //     }
    //   }
    // },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          '&.progress-nav-doc': {
            height: '10px',
            border: `1px solid ${midGrey}`,
            borderRadius: '2px',
            backgroundColor: white,
            '&.tree': {
              height: '6px',
              width: '80%'
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
          fontWeight: 'normal',
          padding: '10px 24px !important',
          fontSize: baseFontSize,
          '&.btn-reset': {
            borderColor: midGrey,
            color: midGrey
          },
          '&.btn-save': {
            backgroundColor: greenAccent
          },
          '&.btn-nav-doc': {
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '5px 8px 5px 5px !important',
            borderRadius: '0px',
            '& .icon-nav-doc': {
              height: '18px',
              width: '18px',
            }
          }
        },
        sizeSmall: {
          padding: '4px 18px',
          fontSize: smFontSize
        },
        outlinedSmall: {
          padding: '4px 18px',
          fontSize: smFontSize
        },
        textSmall: {
          padding: '4px 18px',
          fontSize: smFontSize
        },

        sizeMedium: {
          padding: '6px 18px',
          fontSize: baseFontSize
        },
        outlined: {
          color: primaryColor,
          padding: '6px 18px',
          fontSize: baseFontSize
        },
        text: {
          padding: '6px 18px',
          fontSize: baseFontSize
        },

        sizeLarge: {
          padding: '10px 22px',
          fontSize: lgFontSize
        },
        outlinedLarge: {
          padding: '10px 22px',
          fontSize: lgFontSize
        },
        textLarge: {
          padding: '10px 22px',
          fontSize: lgFontSize
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.tree-search input': {
            padding: '12px 0px !important',
          }
        },
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: lgFontSize
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: baseFontSize,
          transform: 'translate(12px, 10px) scale(1)',
          userSelect: 'text'
        },
        shrink: {
          transform: 'translate(12px, -6px) scale(0.75)',
          userSelect: 'text'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: baseFontSize,
          userSelect: 'text'
        },
        input: {
          padding: '10px !important',
          userSelect: 'text',
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          // display: 'flex',
          // flexDirection: 'column',
          padding: '10px 40px 22px 16px',
          background: white,
          borderRadius: '6px',
          border: `1px solid ${greyLight}`,
          '& li:not(:last-child),& a:not(:last-child)': {
            marginBottom: '7px',
          },
          '&.nav-tabs .MuiListItem-root.MuiListItem-button': {
            padding: '0.3rem 1rem'
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: baseFontSize
        }
      }
    },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#f0f0ef'
    //     }
    //   }
    // },
  },
});

export default MuiTheme;

import React, { useEffect, useState } from 'react';
import { Autocomplete, Grid, Table } from '@mui/material';

import {
  FIELD_TITLES,
  FIELD_KEYS,
  USER_TYPES_TITLES,
  USER_TYPES,
  DISPLAY_CONTROLLERS,
  STATUS_CLOSED
} from './../../../constants';
 import { formatDate } from './../../../utils/date';
import { LoadingButton, RenderField, TypeaHead } from './../';
import { excludeFirstElement, getFieldParams } from './../../../utils/common';
import { useSelector } from 'react-redux';

const dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

const isNaNString = str => isNaN(parseInt(str));

export const ApprovalScheduler = (props) => {
  const {
    docsConfig,
    fieldsType,
    selectedItem,
    selectedFieldItems,
    setSelectedFieldItems,
    docType,
    item,
    dueDatesLimits = {},
    handleChange,
    handleFieldsSave
  } = props;

  const { contentSaving } = useSelector(state => state.ThemeOptions);

  const scheduleTemplates = ((docsConfig?.[docType]?.Tabs || []).find(({ Name }) => Name === fieldsType)?.Fields || [])
    .find(({ FieldName }) => FieldName === 'Templates')?.Items || [];

  const [calculatedDates, setCalculatedDates] = useState({});
  const [completionDate, setCompletionDate] = useState('');

  const openGateRequestsField = selectedItem[FIELD_KEYS.Overview].find(({ field }) => field === `${docType}OpenGateRequests`);
  const { Items: gates } = getFieldParams(docsConfig[docType], FIELD_KEYS.Overview, `${docType}OpenGateRequests`);

  const firstGate = [...(openGateRequestsField?.value || [])].sort(
    ({ createdAt: createdAtA }, { createdAt: createdAtB }) =>
      (new Date(createdAtA)).getTime() - (new Date(createdAtB)).getTime()
  ).shift();
  const disabledGates = (openGateRequestsField?.value || [])
    .reduce((result, { gate, status }) => ({
      ...result,
      [gate]: status === STATUS_CLOSED
    }), {});
  const firstGateTitle = !firstGate
    ? ''
    : (gates || []).find(gate => gate[`${docType}GateId`] === firstGate.gate)?.Label;
  const firstGateDate = firstGate ? formatDate(firstGate.createdAt, dateOptions) : '';

  useEffect(() => {
    if (!firstGate?.createdAt) return;

    let bCalculateCompletion = true;
    let lastDays = 0;
    const calculatedDates = (gates || [])
      .filter(excludeFirstElement)
      .reduce((nextGateDates, gate) => ({
        ...nextGateDates,
        [gate[`${docType}GateId`]]: USER_TYPES.reduce((nextCalculatedTypes, userType) => {
          const currentDays = dueDatesLimits?.[userType]?.[gate[`${docType}GateId`]];
          bCalculateCompletion = bCalculateCompletion && !isNaNString(currentDays);
          lastDays = !isNaNString(currentDays) ? currentDays + lastDays : lastDays;

          const startDate = new Date(firstGate.createdAt);
          startDate.setDate(startDate.getDate() + lastDays);

          return ({
            ...nextCalculatedTypes,
            [userType]: !isNaNString(currentDays) ? formatDate(startDate, dateOptions) : ''
          });
        }, {})
      }), {});

    setCalculatedDates(calculatedDates);

    if (bCalculateCompletion) {
      const startDate = new Date(firstGate.createdAt);
      startDate.setDate(startDate.getDate() + lastDays);

      const completionDate = formatDate(startDate, dateOptions);

      setCompletionDate(completionDate);
      setSelectedFieldItems({
        ...selectedFieldItems,
        [FIELD_KEYS.Fields]: {
          ...selectedFieldItems[FIELD_KEYS.Fields],
          [`${docType}CompletionDate`]: completionDate
        }
      });
    } else {
      setCompletionDate('');
      setSelectedFieldItems({
        ...selectedFieldItems,
        [FIELD_KEYS.Fields]: {
          ...selectedFieldItems[FIELD_KEYS.Fields],
          [`${docType}CompletionDate`]: ''
        }
      });
    }
  }, [JSON.stringify([firstGate?.createdAt, gates, docType, dueDatesLimits, openGateRequestsField])]);

  return (
    <div className="pt-5 pb-3">
      <h5 className="font-weight-bold">{ item.label }</h5>
      <Grid container spacing={1} className="pt-2">
        <Grid item xs={2} className="font-weight-bold">
          { firstGateTitle } { FIELD_TITLES.StartDate }
        </Grid>
        <Grid item xs={2}>
          { firstGateDate }
        </Grid>
        <Grid item xs={2} className="font-weight-bold">
          { FIELD_TITLES.CompletionDate }
        </Grid>
        <Grid item xs={2}>
          { completionDate }
        </Grid>
        <Grid item xs={4}>
          {
            !!scheduleTemplates.length &&
              <TypeaHead
                options={
                  scheduleTemplates
                    .map(({ Label: label, Value: dueDateTemplate }) => ({ label, id: dueDateTemplate }))
                }
                onChange={
                  (id, template) => {
                    if (!template) return;

                    const nextValue = Object.keys(template.id || {}).reduce((result, userType) => {
                      const nextUserTypeValues = Object.keys(template?.id?.[userType] || {})
                        .reduce((typeValues, gate) =>
                          ({ ...typeValues, [gate]: disabledGates[gate] ? dueDatesLimits?.[userType]?.[gate] : template?.id?.[userType]?.[gate] })
                          , {}
                        );


                      return { ...result, [userType]: nextUserTypeValues };
                    }, {});

                    handleChange({ target: { value: nextValue } })
                  }
                }
                label={'Template'}
                placeholder={'Choose a template'}
              />
          }
        </Grid>
      </Grid>
      <div className="divider" />
      <Table className="table table-borderless text-nowrap mb-0">
        <thead>
          <tr>
            <th></th>
            {
              (gates || [])
                .filter(excludeFirstElement)
                .map(gate => (<th key={gate[`${docType}GateId`]} >{ gate.Label }</th>))
            }
          </tr>
        </thead>
        <tbody>
        {
          Object.keys(USER_TYPES_TITLES).map(userType => (
            <tr key={userType}>
              <td>{ USER_TYPES_TITLES[userType] }</td>
              {
                (gates || [])
                  .filter(excludeFirstElement)
                  .map(gate => (
                    <td key={gate[`${docType}GateId`]} >
                      <RenderField
                        styles={{ width: '70px' }}
                        item={{
                          displayController: DISPLAY_CONTROLLERS.NumericBox,
                          field: gate[`${docType}GateId`],
                        }}
                        selectedFieldItems={dueDatesLimits[userType] || {}}
                        userHandleChange={(event) => {
                          const nextValue = {
                            ...dueDatesLimits,
                            [userType]: {
                              ...(dueDatesLimits[userType] || {}),
                              [gate[`${docType}GateId`]]: event.target.value
                            }
                          };
                          handleChange({ target: { value: nextValue } })
                        }}
                        access={!disabledGates[gate[`${docType}GateId`]]}
                      />
                    </td>
                  ))
              }
            </tr>
          ))
        }
        </tbody>
      </Table>
      {
        !!firstGate && !!Object.keys(calculatedDates).length && (
          <Table className="table table-borderless text-nowrap mt-5">
            <thead>
            <tr>
              <th></th>
              {
                (gates || [])
                  .filter(excludeFirstElement)
                  .map(gate => (<th key={gate[`${docType}GateId`]} >{ gate.Label }</th>))
              }
            </tr>
            </thead>
            <tbody>
            {
              Object.keys(USER_TYPES_TITLES).map(userType => (
                <tr key={userType}>
                  <td>{ USER_TYPES_TITLES[userType] }</td>
                  {
                    (gates || [])
                      .filter(excludeFirstElement)
                      .map(gate => (
                        <td key={gate[`${docType}GateId`]} >
                          { calculatedDates[gate[`${docType}GateId`]][userType] }
                        </td>
                      ))
                  }
                </tr>
              ))
            }
            </tbody>
          </Table>
        )
      }
    </div>
  );
};

export default ApprovalScheduler;

import axios from 'axios';
import {
  INNOMETRIX_CLIENT_DOMAIN_PROD,
  INNOMETRIX_CLIENT_DOMAIN_DEMO,
  INNOMETRIX_CLIENT_DOMAIN_DEV,
  INNOMETRIX_API_DOMAIN_PROD,
  INNOMETRIX_API_DOMAIN_DEMO,
  INNOMETRIX_API_DOMAIN_DEV,
  INNOMETRIX_CLIENT_DOMAIN_LOCAL,
  INNOMETRIX_API_DOMAIN_LOCAL,
} from './../constants';
import AuthService from './../services/Auth';

const { INNOMETRIX_API_DOMAIN = INNOMETRIX_API_DOMAIN_DEMO } = [
  {
    condition: window.location.href.includes(INNOMETRIX_CLIENT_DOMAIN_PROD),
    INNOMETRIX_API_DOMAIN: INNOMETRIX_API_DOMAIN_PROD
  },
  {
    condition: window.location.href.includes(INNOMETRIX_CLIENT_DOMAIN_DEV),
    INNOMETRIX_API_DOMAIN: INNOMETRIX_API_DOMAIN_DEV
  },
  {
    condition: window.location.href.includes(INNOMETRIX_CLIENT_DOMAIN_DEMO),
    INNOMETRIX_API_DOMAIN: INNOMETRIX_API_DOMAIN_DEMO
  },
  {
    condition: window.location.href.includes(INNOMETRIX_CLIENT_DOMAIN_LOCAL),
    INNOMETRIX_API_DOMAIN: INNOMETRIX_API_DOMAIN_LOCAL
  }
].find(({ condition }) => condition) || {};

const NOT_ACTIVITY_REQUESTS = ['/notifications', '/sign-in'];
let instance = {};
export const getAxios = () => {
  const token = AuthService.getToken();
  if (instance[token]) return instance[token];

  instance[token] = axios.create({ baseURL: INNOMETRIX_API_DOMAIN });
  instance[token].interceptors.request.use(
    config => {
      if (token) config.headers['Authorization'] = `Bearer ${token}`;

      if (!NOT_ACTIVITY_REQUESTS.some(path => (config.url || '').includes(path))) {
        AuthService.resetActivity();
      }

      return config;
    }
  );
  instance[token].interceptors.response.use(
    response => response.data,
    error => {
      if (error.response && ([401].includes(error.response.status))) {
        AuthService.logout();
      }

      return Promise.reject(error?.response?.data || error);
    }
  );

  return instance[token];
};

import { ulid } from 'ulid';
import { set as setByDot, get as getByDot } from 'dot-prop';

import { ReactComponent as GdocIcon } from './../assets/images/docs/gdoc.svg';
import { ReactComponent as BdocIcon } from './../assets/images/docs/bdoc.svg';
import { ReactComponent as SdocIcon } from './../assets/images/docs/sdoc.svg';
import { ReactComponent as IdocIcon } from './../assets/images/docs/idoc.svg';
import { ReactComponent as IndocIcon } from './../assets/images/docs/indoc.svg';
import { ReactComponent as OutdocIcon } from './../assets/images/docs/outdoc.svg';
import { ReactComponent as PdocIcon } from './../assets/images/docs/pdoc.svg';
import { ReactComponent as PrdocIcon } from './../assets/images/docs/prdoc.svg';
import { ReactComponent as QdocIcon } from './../assets/images/docs/qdoc.svg';
import { ReactComponent as RdocIcon } from './../assets/images/docs/rdoc.svg';
import { ReactComponent as MdocIcon } from './../assets/images/docs/mdoc.svg';
import { ReactComponent as KdocIcon } from './../assets/images/docs/kdoc.svg';
import { ReactComponent as TdocIcon } from './../assets/images/docs/tdoc.svg';
import { ReactComponent as QsdocIcon } from './../assets/images/docs/qsdoc.svg';
import {
  DOC_HIERARCHY,
  DOC_LEVELS,
  IDOC_TYPE,
  TDOC_TYPE,
  MDOC_TYPE,
  FIELD_KEYS,
  DISPLAY_CONTROLLERS,
  YEAR_MONTHS_COUNT,
  UOM_FORMATS,
  RDOC_TYPE,
  CONTROL_DOC_CDOC,
  ENDPOINTS,
  METHOD_GET,
  ACTION_ALLOW,
  GDOC_TYPE,
  FIELD_TITLES,
  ALL_ENTITIES,
  PERCENT_ALL,
  STATUS_ABANDONED,
  STATUS_DEFERRED,
  STATUS_COMPLETED,
  TYPE_APPROVVERS,
  STATUS_PENDING,
  TYPE_SPONSORS,
  TYPE_LEADS,
  STATUS_CLOSED,
  STATUS_OPEN,
  USER_TYPES,
  DISTRIBUTION_CURVE_MANUAL,
  EXCLUDE_DEFAULT_RESOURCES,
  EXCLUDE_ADMIN_RESOURCES,
  CURRENCY_USD,
  BASE_SCENARIO,
  TYPE_PREV_VALUE,
  SDOC_TYPE,
  BDOC_TYPE,
  INDOC_TYPE,
  OUTDOC_TYPE,
  PDOC_TYPE,
  PRDOC_TYPE,
  QDOC_TYPE,
  KDOC_TYPE,
  QSDOC_TYPE,
  STATUS_APPROVED, AGG_METHOD_AVERAGE, AGG_METHOD_LAST_PERIOD,
} from '../constants';
import { AuthService, Storage } from './../services';

const DISPLAY_CONTROLLERS_VALUES = Object.values(DISPLAY_CONTROLLERS);

// export const getParentTypesByDocs = (docs) => {
//   const docTypes = Object.keys(docs).filter(docType => (docs[docType] || []).length)
//
//   return DOC_LEVELS.find(docLevels => docTypes.some(docType => docLevels.includes(docType)))
// };

export const cacheDecorator = function(fn) {
  const _cache = new Map();

  return function() {
    const key = `__cachekey__${JSON.stringify(arguments)}`;

    if (!_cache.has(key)) {
      const result = fn.apply(this, [...arguments]);
      _cache.set(key, result);
    }

    return _cache.get(key);
  };
};
export const getTabInfoWithoutCache = ((docConfig = {}, tabName) => (docConfig.Tabs || []).find(({ Name }) => tabName === Name));
export const getTabInfo = cacheDecorator((docConfig = {}, tabName) => (docConfig.Tabs || []).find(({ Name }) => tabName === Name));
export const getFieldParams = (docConfig, TabName, FieldName) => {
  const tabInfo = getTabInfo(docConfig, TabName);

  return (tabInfo?.Fields || []).find(tabInfo => tabInfo.FieldName === FieldName) || {};
};
export const getParentTypesByDocs = (docs) => {
  const hasParents = (docType) =>
    docs[docType].some((doc) => {
      const parentType = getParentType(docType)
      const parentId = doc[`${parentType}Id`];

      return parentId  && !(docs[parentType] || []).some(doc => doc[`${parentType}Id`] === parentId)
    });
  const getParents = (docType) => docs[docType]
    .filter((doc) => {
      const parentType = getParentType(docType)
      const parentId = doc[`${parentType}Id`];

      return parentId  && !(docs[parentType] || []).some(doc => doc[`${parentType}Id`] === parentId)
    });

  return DOC_LEVELS.reduce((result, docTypes) => {
    const nextDocsData = docTypes.reduce((result, docType) => {
      const { getDocsData = () => ({}) } = [
        {
          condition: () => docType === GDOC_TYPE && (docs[docType] || []).length,
          getDocsData: () => ({
            docType,
            docIds: docs[docType].map(({ [`${docType}Id`]: docId }) => docId)
          })
        },
        {
          condition: () => docType !== GDOC_TYPE && (docs[docType] || []).length && hasParents(docType),
          getDocsData: () => ({
            docType,
            docIds: getParents(docType).map(({ [`${docType}Id`]: docId }) => docId)
          })
        }
      ].find(({ condition }) => condition()) || {};
      const nextDocs = getDocsData();

      return (nextDocs.docIds || []).length ? [...result, getDocsData()] : result
    }, []);

    return [...result, ...nextDocsData]
  }, []);

};

export const getChildTypes = (docType) => DOC_HIERARCHY[docType];

export const getParentType = (docType) =>
  Object.keys(DOC_HIERARCHY).find(parentType => DOC_HIERARCHY[parentType].includes(docType));

export const getParentTypes = (docType, parentTypes = []) => {
  const parentType = getParentType(docType);
  parentTypes = [...parentTypes, parentType];

  if (parentType &&  getParentType(parentType)) {
    parentTypes = getParentTypes(parentType, parentTypes);
  }

  return parentTypes;
};

export const getConfigsDocFields = docConfig =>
  docConfig.Tabs.reduce((result, tabInfo) => [
    ...result,
    ...tabInfo.Fields
        .filter(({ DisplayController, Display }) =>
          !!DisplayController && Display !== false && DisplayController !== DISPLAY_CONTROLLERS.Relation && DISPLAY_CONTROLLERS_VALUES.includes(DisplayController) ||
          DisplayController === DISPLAY_CONTROLLERS.Relation && !!Display
        )
        .map(({ FieldName }) => FieldName)
  ], []);

export const getNotConfigsDocFields = (doc = {}, docConfig) => {
  const configFields = getConfigsDocFields(docConfig);

  return Object.keys(doc)
    .filter(key => !configFields.includes(key))
    .reduce((result, key) => ({ ...result, [key]: doc[key] }), [])
};

export const getParents = ({ id, docType, docs }) => {
  let parents = [];
  const setParents = (docType, id) => {
    const parentType = getParentType(docType);
    const doc = findDoc({ id, docType, docs });

    if (parentType && doc?.[`${parentType}Id`]) {
      parents = [...parents, doc[`${parentType}Id`]];
      setParents(parentType, doc[`${parentType}Id`]);
    }
  };

  setParents(docType, id);

  return parents
};

export const composeDoc = ({ doc, docConfig, docType, docs = [], parent }) => {
  const mapFields = objField => {
    const { FieldWidth, Label, ToolTip, DisplayPosition, DisplayController, FieldName, Items, Required, ...props } = objField;

    return {
      label: Label,
      required: Required,
      toolTip: ToolTip,
      DisplayPosition,
      FieldWidth,
      displayController: DisplayController,
      field: FieldName,
      // source: Items || [],
      value: getByDot(doc || '', FieldName),
      // ...props
    };
  };
  const titleName = doc[`${docType}Name`];
  const id = doc[`${docType}Id`] || doc['Id'];

  return docConfig.Tabs
    // .filter(({ Name }) => Name === 'Fields')
    .reduce((result, tabInfo) => ({
      ...result,
      [tabInfo.Name]: tabInfo.Fields
        .filter(
          objField => !!objField.DisplayController && objField.Display !== false &&
            objField.DisplayController !== DISPLAY_CONTROLLERS.Relation && DISPLAY_CONTROLLERS_VALUES.includes(objField.DisplayController) ||
            objField.DisplayController === DISPLAY_CONTROLLERS.Relation && !!objField.Display
        )
        .map(mapFields)
    }), {
    notGrouped: getNotConfigsDocFields(doc, docConfig),
    nodeId: id,
    parents: getParents({ id, docType, docs }),
    parent,
    type: docType,
    collection: docConfig.TargetCollection,
    weight: doc.Weight,
    startDate: doc[`${docType}StartDate`],
    clientName: titleName || docConfig.DocumentType,
    access: !doc.NoAccess && checkAccess({ path: `${ENDPOINTS.DOCS}${docType}/`, methods: [METHOD_GET] })
  });
};

const sortDocs = (
  { type, clientName: clientNameA = '', startDate: startDateA, weight: weightA = 1000 },
  { clientName: clientNameB = '', startDate: startDateB, weight: weightB = 1000 }
) => {
  const {
    sort = () => clientNameA.localeCompare(clientNameB)
  } = [
    {
      condition: !isNaN(weightA) || !isNaN(weightB),
      sort: () =>
        (Number(weightA) > Number(weightB) ? 1 : -1)
    },
    {
      condition: type === TDOC_TYPE,
      sort: () => (Number(new Date(startDateA)) > Number(new Date(startDateB)) ? -1 : 1)
    }
  ].find(({ condition }) => condition) || {};

  return sort();
};

const getData = ({ docsConfig, docs, docType, docIds, childQuery, parent }) => {
  const docConfig = docsConfig[docType];
  const { getListDocs = () => [] } = [
    {
      condition: !!docIds,
      getListDocs: () => docs[docType].filter(({ [`${docType}Id`]: docId }) => docIds.includes(docId))
    },
    {
      condition: !docIds && !childQuery,
      getListDocs: () => docs[docType]
    },
    {
      condition: !!childQuery,
      getListDocs: () => (docs[docType] || []).filter(doc => doc[childQuery.field] === childQuery.value)
    }
  ].find(({ condition }) => condition);
  const listDocs = getListDocs();

  return listDocs.map(doc => {
    const isChildrens = (getChildTypes(docType) || []).some(
      docChildType => (docs[docChildType] || [])
        .filter(
          childDoc => {
            const { getCondition } = [
              {
                condition : docChildType === IDOC_TYPE && docType === IDOC_TYPE,
                getCondition: () => childDoc[`${docType}DependencyId`] === doc[`${docType}Id`]
              },
              {
                condition : docChildType === IDOC_TYPE && docType !== IDOC_TYPE,
                getCondition: () => !childDoc[`${docType}DependencyId`] && childDoc[`${docType}Id`] === doc[`${docType}Id`]
              },
              {
                condition : true,
                getCondition: () => childDoc[`${docType}Id`] === doc[`${docType}Id`]
              },
            ].find(({ condition }) => condition)

            return getCondition()
          }
        ).length
    );
    const composeFields = composeDoc({ doc, docConfig, docType, docs, parent });
    const notification = [RDOC_TYPE].includes(docType) && !doc[`${CONTROL_DOC_CDOC}Id`];

    return {
      ...composeFields,
      notification,
      ...(!isChildrens
        ? {}
        : {
          children: getChildTypes(docType).reduce(
            (result, docChildType) => {
              const { getQuery } = [
                {
                  condition : docChildType === IDOC_TYPE && docType === IDOC_TYPE,
                  getQuery: () => ({ field: `${docType}DependencyId`, value: doc[`${docType}Id`] })
                },
                {
                  condition : true,
                  getQuery: () => ({ field: `${docType}Id`, value: doc[`${docType}Id`]})
                },

              ].find(({ condition }) => condition)

              return [
                ...result,
                ...getData({ docsConfig, docs, docType: docChildType, childQuery: getQuery(), parent: composeFields })
              ]
            }, []).sort(sortDocs)
        }
      )
    }
  });
};

export const getNotificationItems = (items) => {
  let notificationsItems = []
  const set = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].notification)
        notificationsItems = [
          ...notificationsItems,
          ...items[i].parents,
          items[i].nodeId
        ];

      if (items[i].children) set(items[i].children);
    }
  };

  set(Array.isArray(items) ? items : [items])

  return notificationsItems;
};

export const setNotifications = (result, item) => {
  const notificationsItems = getNotificationItems(item);
  const set = item => ({
    ...item,
    notification: notificationsItems.includes(item.nodeId),
    ...(
      !item.children
        ? {}
        : { children : item.children.map(childItem => set(childItem)) }
    )
  });
  const nextItem = set(item)

  return [...result, nextItem]
};

export const populateNoAccessDocs = (docs) => {
  const groupedByGoals = Object.keys(docs)
    .reduce((grouped, docType) => {
      const subGouped = docs[docType]
        .filter(doc => !!doc[`${GDOC_TYPE}Id`])
        .reduce((result, doc) => {
          const gDocId = doc[`${GDOC_TYPE}Id`];

          return ({ ...result, [gDocId]: [...(result[gDocId] || []), { ...doc, docType }] })
        }, {});
      const nextGrouped = Object.keys(subGouped).reduce((result, gDocId) => {

        return { ...result, [gDocId]: [...(result[gDocId] || []), ...subGouped[gDocId]] };
      }, grouped);

      return nextGrouped;
    }, {});

  const populateNoAccessParents = (doc) => {
    const parentType = getParentType(doc.docType);

    if (!parentType) return;

    const parentId = doc[`${parentType}Id`];
    const isExists = docs[parentType]?.some((doc) => doc[`${parentType}Id`] === parentId);

    if (!isExists && Array.isArray(docs[parentType])) {
      const parentDoc = {
        docType: parentType,
        [`${parentType}Id`]: parentId,
        [`${getParentType(parentType)}Id`]: doc[`${getParentType(parentType)}Id`] || `${doc[`${GDOC_TYPE}Id`]}_NOACCESS`,
        [`${GDOC_TYPE}Id`]: doc[`${GDOC_TYPE}Id`],
        NoAccess: true
      };
      docs[parentType].push(parentDoc);
      populateNoAccessParents(parentDoc);
    }
  }

  Object.keys(groupedByGoals).forEach((gDocId) => {
    const isExists = docs[GDOC_TYPE]?.some((doc) => doc[`${GDOC_TYPE}Id`] === gDocId);
    if (!isExists && Array.isArray(docs[GDOC_TYPE])) {
      console.log('gDocId: ', gDocId)

      docs[GDOC_TYPE].push({ [`${GDOC_TYPE}Id`]: gDocId, NoAccess: true })
    }

    const group = groupedByGoals[gDocId];
    group.forEach(doc => {
      const parentType = getParentType(doc.docType);

      if (!parentType) return;

      const parentId = doc[`${parentType}Id`]

      if (!parentId) return;

      populateNoAccessParents(doc);
    });

  });

  return docs
}

export const prepareTree = ({ docsConfig, docs }) =>
  getParentTypesByDocs(populateNoAccessDocs(docs))
    .reduce((result, { docType, docIds }) => [...result, ...getData({ docsConfig, docs, docType, docIds })], [])
    .reduce(setNotifications, [])
    .sort(sortDocs)
;

export const findItem = (items, id) => {
  const find = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].nodeId === id ) return items[i];

      if (items[i].children) {
        const item = find(items[i].children);

        if (item && item.nodeId === id) return item
      }
    }
  };

  return find(items);
};

export const findItemsByDocType = (items, docType) => {
  const searchItems = [];

  const populate = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].type === docType) searchItems.push(items[i]);

      if (items[i].children) populate(items[i].children);
    }
  };
  populate(items);

  return searchItems;
};

export const getInitItem = (items) => {
  const find = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].access) return items[i];

      if (items[i].children) {
        const item = find(items[i].children);

        if (item) return item;
      }
    }
  };

  return find(items);
};

export const getExpandIds = cacheDecorator((items = [], inputDocTypes = []) => {
  const docTypes = inputDocTypes;

  const filterByDocType = ({ type }) => Array.isArray(docTypes) && docTypes.length ? docTypes.includes(type) : true;
  const getChildrenIds = (result, item) => {
    if (item.children)
      return [
        ...result,
        item.nodeId,
        ...item.children.filter(filterByDocType).reduce(getChildrenIds, [])
      ];

    return [...result, item.nodeId];
  };

  return items.filter(filterByDocType).reduce(getChildrenIds, []);
});

export const getItemsByCond = (items, conditions = {}) => {
  const filterItems = (item) => {
    const { isPass = () => true } = [
      {
        condition: !!conditions.Tab,
        isPass: () => !!item[conditions.Tab]
      },
      {
        condition: !!conditions.docType,
        isPass: () => item.type === conditions.docType
      }
    ].find(({ condition }) => condition) || {};

    return isPass();
  };
  const getChildren = (result, item) => {
    if (item.children)
      return [
        ...result,
        item,
        ...item.children.reduce(getChildren, [])
      ];

    return [...result, item];
  };

  return items.reduce(getChildren, []).filter(filterItems);
};

export const modifyItems = (items, inputItem) => {
  const mofidyItem = (item) => {
    if (item.nodeId === inputItem.id || item.nodeId === inputItem.nodeId)
      return { ...item, ...inputItem };

    if (item.nodeId !== inputItem.id && item.children)
      return { ...item, children: item.children.map(mofidyItem) };

    if (item.nodeId !== inputItem.id && !item.children)
      return item;
  };

  return items.map(mofidyItem);
};

export const removeItems = (items, id) => {
  const removeItem = (item) => {
    if (!item.children) return item;

    return {
      ...item,
      children: item.children.filter(({ nodeId }) => nodeId !== id).map(removeItem)
    }
  };

  return items.map(removeItem).filter(({ nodeId }) => nodeId !== id);
};

export const generateId = (docType = '') => `${docType.toUpperCase()}_${ulid()}`;
export const AddItem = ({ items, parentId, docType, generatedId, docAdditionalParams, docConfig, docs }) => {
  console.log('AddItem ')

  if (!parentId) return [
    ...items,
    { ...composeDoc({ doc: { ...docAdditionalParams, [`${docType}Id`]: generatedId }, docConfig, docType, docs }), isNew: true }
  ].sort(sortClientName);

  let added = false;
  const parent = findItem(items, parentId);

  const mofidyItem = (item) => {
    if (item.nodeId === parentId) {
      added = true;

      return {
        ...item,
        children: [
          ...(item.children || []).filter(({ type }) => type === docType),
          {
            ...composeDoc({
              doc: { ...docAdditionalParams, [`${docType}Id`]: generatedId },
              docConfig,
              docType,
              parent
            }), isNew: true
          },
          ...(item.children || []).filter(({ type }) => type !== docType)
        ].sort(sortClientName)
      };
    }

    if (item.nodeId !== parentId && item.children)
      return { ...item, children: item.children.map(mofidyItem).sort(sortClientName) };

    if (item.nodeId !== parentId && !item.children)
      return item;
  };

  const nextItems = items.map(mofidyItem);
  console.log('added: ', added);
  if (!added) {
    nextItems.push({
      ...composeDoc({
        doc: { ...docAdditionalParams, [`${docType}Id`]: generatedId },
        docConfig,
        docType,
        parent
      }), isNew: true
    });
  }

  return nextItems.sort(sortClientName);
};

export const prepareDbDoc = ({
  selectedItem,
  selectedFieldItems: { nodeId, ...selectedFieldItems } = {},
  parentId,
  docType,
  Tabs = []
}) => {
  const nextFieldsTypes = Object.keys(selectedFieldItems)
    .filter(fieldsType => fieldsType !== 'notGrouped')
    .reduce((result, fieldsType) => {
      const modifyItems = selectedFieldItems[fieldsType];

      return {
        ...result,
        [fieldsType]: [
          ...(selectedItem[fieldsType] || []).map(
            (fieldObj) => modifyItems[fieldObj.field] === undefined
              ? fieldObj
              : { ...fieldObj, value: modifyItems[fieldObj.field] }
          )
        ]
      }
    }, { notGrouped: { ...selectedItem?.notGrouped || {}, ...selectedFieldItems?.notGrouped || {} }});

  const nextSelectedItem = { ...selectedItem, ...nextFieldsTypes };
  const { notGrouped = {} } = nextSelectedItem;
  const parentType = getParentType(docType);

  const tabs = Tabs.length
    ? Tabs.map(({ Name }) => Name)
    : Object.keys(FIELD_KEYS)
  ;

  const dbDoc = tabs.reduce((result, fieldsType) => ({
    ...result,
    ...(nextSelectedItem[fieldsType] || []).reduce(((fieldResult, { field, value }) =>
        (![undefined, ''].includes(value) ? { ...fieldResult, [field]: value } : fieldResult)
    ), {})
  }), parentType && parentId ? { ...notGrouped, [`${parentType}Id`]: parentId } : notGrouped);

  return dbDoc;
};

export const getParentDocId = ({ id, docType, docs }) => {
  const parentType = getParentType(docType);

  if (!parentType) return undefined;

  const doc = docs[docType].find(doc => doc[`${docType}Id`] === id || doc['Id'] === id);

  if (!doc) return undefined;

  return doc[`${parentType}Id`];
};

export const findDoc = ({ id, docType, docs }) => (docs?.[docType] || []).find(({ [`${docType}Id`]: docId }) => docId === id);
export const findChildDocs = ({ id, docType, docs, docTypes = [] }) => {
  const findChildDocs = (childType, docType, parentDocIds = []) => {
    const childDocs = (docs?.[childType] || [])
      .filter(({ [`${docType}Id`]: docId }) => parentDocIds.includes(docId))
      .map(doc => ({ ...doc, docType: childType }))
    const nextChildTypes = childDocs.length && getChildTypes(childType);

    if (nextChildTypes) {
      return nextChildTypes.reduce((nextChilds, nextChildType) => {
        return [
          ...nextChilds,
          ...findChildDocs(
              nextChildType,
              childType,
              childDocs.map(({ [`${childType}Id`]: id }) => id)
          )
        ]
      }, childDocs)
    }

    return childDocs;
  }

  return (getChildTypes(docType) || [])
    .reduce((childs, childType) => {
      return [
        ...childs,
        ...findChildDocs(childType, docType, [id])
      ]
    }, [])
    .filter(({ docType }) => docTypes.length ? docTypes.includes(docType) : true)
}

export const getDocProgressAndSponsorsIds = ({ id, docType, docs, }) => {
  const docTypes = [IDOC_TYPE, RDOC_TYPE, MDOC_TYPE];
  const childDocs = !docTypes.includes(docType)
    ? findChildDocs({ id, docType, docs, docTypes })
    : [findDoc({ id, docType, docs })]
  ;
  const allReviewers = childDocs.reduce((result, doc) =>
    [...result, ...(doc[`${doc.docType}Reviewers`] || [])], []);

  const sponsors = [
    ...new Set(
      allReviewers
        .filter(({ type }) => type === TYPE_SPONSORS)
        .map(({ email }) => email)
    )
  ]
  return { childDocs, sponsors };
}

export const round = (num) => Math.round(num * 100) / 100;

export const calculateGridData = ({ docType, defaultValues = {}, TimeFields, docConfig }) => {
  const isCalcGridData = docType && TimeFields &&
    // TimeFields[`${docType}PerPeriod`] &&
    TimeFields[`${docType}PeriodType`] &&
    TimeFields[`${docType}NumberOfYears`] !== undefined && (
      defaultValues[`${docType}PerPeriod`] !== TimeFields[`${docType}PerPeriod`] ||
      defaultValues[`${docType}PeriodType`] !== TimeFields[`${docType}PeriodType`] ||
      defaultValues[`${docType}NumberOfYears`] !== TimeFields[`${docType}NumberOfYears`] ||
      defaultValues[`${docType}StartDate`] !== TimeFields[`${docType}StartDate`] ||
      defaultValues[`${docType}DistributionCurve`] !== TimeFields[`${docType}DistributionCurve`]
      // || defaultValues[`${docType}Total`] !== TimeFields[`${docType}Total`]
    )
  ;

  if (!isCalcGridData) return;

  const gridParams = getTabInfo(docConfig, FIELD_KEYS.TimeFields).Fields.find(({ FieldName }) => FieldName === `${docType}GridValues`);
  const distributionCurveParams = getTabInfo(docConfig, FIELD_KEYS.TimeFields).Fields.find(({ FieldName }) => FieldName === `${docType}DistributionCurve`);

  const {
    [`${docType}PerPeriod`]: xDocPerPeriod,
    [`${docType}PeriodType`]: xDocPeriodType,
    [`${docType}DistributionCurve`]: xDocDistributionCurve,
    [`${docType}NumberOfYears`]: xDocNumberOfYears,
    [`${docType}StartDate`]: xDocStartDate
  } = TimeFields;

  const periods = (distributionCurveParams?.Items || []).find(({ Label }) => Label === xDocDistributionCurve)?.Periods || [];

  console.log('xDocDistributionCurve: ', xDocDistributionCurve)
  console.log('periods: ', periods)

  const startDate = new Date(xDocStartDate || Date.now());
  const year = startDate.getFullYear();
  const countElements = YEAR_MONTHS_COUNT * xDocNumberOfYears;
  const yearValue = xDocPerPeriod / gridParams.NumberOfDivisions[xDocPeriodType] * YEAR_MONTHS_COUNT;
  const evenValue = round(xDocPerPeriod / gridParams.NumberOfDivisions[xDocPeriodType]);
  const allValues = Array(countElements)
    .fill(evenValue)
    .fill(0, 0, startDate.getMonth())
    .map((value, index) => {
      // if (round(periods.reduce((result, { DistributionValue: value }) => (result + value), 0)) !== PERCENT_ALL) return value;

      const month = (index % YEAR_MONTHS_COUNT) + 1;
      const curve = periods.find(({ MonthNum }) => MonthNum === month)?.DistributionValue ?? 0;

      return value ? round(yearValue/PERCENT_ALL*curve) : value;
    });

  const gridValues = allValues
    .reduce((acc, value, i) => {
      if ((i % YEAR_MONTHS_COUNT) === 0) acc.push([]);
      acc[acc.length - 1].push(value);

      return acc;
    }, [])
    .reduce((result, value, i) => ({ ...result, [year+i]: value }), {})
  ;
  const total = round(allValues.reduce((a, b) => (Number(a) + Number(b)), 0));

  return {
    gridValues,
    total: isNaN(total) ? 0 : total
  }
};

export const getTotalByMethod = (values, method) => {
  const {
    calculate = () => round(values.reduce((sum, val) => (Number(sum) + Number(val)), 0))
  } = [
    {
      condition: method === AGG_METHOD_AVERAGE,
      calculate: () => {
        let count = 0;
        const sumValues = values.reduce((sum, val) => {
          if (!!val || (count && !val))
            count++

          return (Number(sum) + Number(val));
        }, 0)

        return round(sumValues/count);
      }
    },
    {
      condition: method === AGG_METHOD_LAST_PERIOD,
      calculate: () => round([...values || []].pop())
    }
  ].find(({ condition }) => condition) || {};

  return calculate() || 0;
};

export const getTotalByGridValues = (gridValues, method) => Object.keys(gridValues).reduce(((sum, year) => {
  const data = Array.isArray(gridValues[year]) ? gridValues[year] : [];
  const yearTotal = getTotalByMethod(data, method);

  return sum + yearTotal;
}) ,0);


export const composeGridValues = ({ gridValues, year, index, val }) => {
  const isArray = Array.isArray(val);
  let nextGridValues;
  if (isArray) {
    nextGridValues = [...(gridValues[year] || [])];
    const nextValues = val.map(v => Number(v) ? round(v) : 0);
    nextGridValues.splice(index, val.length, ...nextValues);
  } else {
    nextGridValues = gridValues[year]
      .filter((_, i) => i !== index);
    nextGridValues.splice(index, 0, round(val));
  }

  return {
    ...gridValues,
    [year]: nextGridValues
  }
};

export const uomFormat = (format, value) => {
  const currency = Storage.getDataByKey('currency') || CURRENCY_USD;
  const formatFunctions = {
    [UOM_FORMATS.CURRENCY]: () => Number(value) < 0
      ? `(${Math.abs(Number(value)).toLocaleString('en-US', { style: 'currency', currency })})`
      : Number(value).toLocaleString('en-US', { style: 'currency', currency  }),
    [UOM_FORMATS.TIME]: () => Number(value).toLocaleString('en-US', { style: 'decimal' }),
    [UOM_FORMATS.PERCENT]: () => `${(Math.trunc(Number(value)*10000)/100)}%`,
    [UOM_FORMATS.DECIMAL]: () => `${(Math.trunc(Number(value)*100)/100)}`
  };

  return formatFunctions[format] ? formatFunctions[format]() : Number(Number(value).toFixed(3));
};

export const haveParent = ({ doc, docType }) => {
  const parentType = getParentType(docType);

  return !!doc[`${parentType}Id`]
};

export const getTopParentId = ({ doc, docType, docs }) => {
  const parentType = getParentType(docType);

  if (!parentType) return doc[`${docType}Id`];

  const parentId = doc[`${parentType}Id`];

  if (!parentId) return doc[`${docType}Id`];

  const parentDoc = findDoc({ id: parentId, docType: parentType, docs });

  if (!parentDoc) return doc[`${docType}Id`];

  return getTopParentId({ doc: parentDoc, docType: parentType, docs });
};

export const getIdsTopParents = ({ searchDocs = [], docs }) =>
  searchDocs.reduce(((result, { doc, docType }) => {
    const topParent = getTopParentId({ doc, docType, docs });

    return [...result, topParent]
  }), []);

export const isRegex = s => s.slice(0,1) === '/' && s.slice(-1) === '/' && s.length > 2;

export const composeMask = sMask => sMask.split(',').map(s => isRegex(s) ? new RegExp(s.slice(1,-1)) : s);

export const checkAccess = ({ path, methods }) => {
  if (!path || !methods) return true;

  const user = AuthService.getUser();
  if (!user) return false;

  const resources = (user.Policies || [])
    .filter(({ Name }) => !['AllowToReadGroupDocuments', 'AllowToReadParentGroupDocuments'].includes(Name)) // tmp fix
    .filter(({ Action }) => Action === ACTION_ALLOW)
    .reduce((nextResources, policy) => [
      ...nextResources,
      ...policy.Resources || []
    ], []);

  const isAvailable = !!resources.find(({ Path, Methods }) =>
    (Path === '*' || (Path.includes('*')
      ? path.includes(Path.replace('*', '')) && (Methods === '*' || methods.every(method => Methods.includes(method)))
      : path.replace(/\/?$/, '') === Path)) && (Methods === '*' || methods.every(method => Methods.includes(method)))
  );

  return isAvailable;
};

export const sortClientName = ({ clientName: clientNameA = '' }, { clientName: clientNameB = '' }) =>
  clientNameA.localeCompare(clientNameB);

export const sortPosition = ({ DisplayPosition: positionA = '' }, { DisplayPosition: positionB = '' }) =>
  Number(positionA) > Number(positionB) ? 1 : -1;

export const getErrors = error   =>
  Array.isArray(error.errors)
    ? error.errors
    : [error?.response?.data?.message || error.message]
;

export const validatePassword = (password = '') => {
  const isValid = password.length >= 8 &&
    /\d/.test(password) &&
    /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /[@~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(password);

  return isValid
};

export const isObject = (data) => data && !Array.isArray(data) && typeof data === 'object';

export const isSystemUser = user => user && (!user.OrganizationId || user.OrganizationId === ALL_ENTITIES);

export const getRole = (roles = [], roleName = '') => roles.find(({ Name }) => roleName === Name);

export const filterUsersByRole = ({ users = [], roles = [], roleName = '' }) => {
  const { RoleId }  = getRole(roles, roleName) || {};

  if (!RoleId) return [];

  return users.filter(({ Roles }) => (Roles || []).includes(RoleId));
};

export const hasUserRole = (user = {}, roles = [], roleNames = []) => {
  const nextRoleNames = Array.isArray(roleNames) ? roleNames : [roleNames];

  return nextRoleNames.find(roleName => {
    const { RoleId } = getRole(roles, roleName) || {};

    return (user.Roles || []).includes(RoleId);
  }) || false;
};

export const getAuthUserReviewer = (reviewers = [], gate) => {
  const { UserId : authUserId } = AuthService.getUser();

  return reviewers.find(reviewer => reviewer.id === authUserId && reviewer.gate === gate) || {};
};

export const getInitialDocValue = ({ selectedItem, fieldsType, suffix }) =>
  (selectedItem?.[fieldsType] || []).find(tab => tab.field === `${selectedItem.type}${suffix}`);

export const getDocStatusQuery = (active = true) => ({
  ...([IDOC_TYPE, RDOC_TYPE, MDOC_TYPE].reduce((resQuery, type) => ({ ...resQuery, [`${type}Status[$ne]`]: STATUS_ABANDONED }), {})),
  [`Status[${active ? '$nin' : '$in'}][]`]: [STATUS_ABANDONED, STATUS_DEFERRED, STATUS_COMPLETED],
});

export const getPendingReviewers = (reviewers = [], step) => {
  const { setPendingUsers = () => [] } = [
    {
      condition: reviewers.some(({ type, status, gate }) =>
        type === TYPE_APPROVVERS && status === STATUS_PENDING && gate === step),
      setPendingUsers: () =>
        reviewers.filter(({ type, status, gate }) => type === TYPE_APPROVVERS && status === STATUS_PENDING && gate === step)
    },
    {
      condition: reviewers.some(({ type, status, gate }) =>
        type === TYPE_SPONSORS && status === STATUS_PENDING && gate === step),
      setPendingUsers: () =>
        [reviewers.find(({ type, status, gate }) => type === TYPE_SPONSORS && status === STATUS_PENDING && gate === step)]
    },
    {
      condition: reviewers.some(({ type, status, gate }) =>
        type === TYPE_LEADS && status === STATUS_PENDING && gate === step),
      setPendingUsers: () =>
        [reviewers.find(({ type, status, gate }) => type === TYPE_LEADS && status === STATUS_PENDING && gate === step)]
    }
  ].find(({ condition }) => condition) || {};

  return setPendingUsers();
};

export const joinNonEmptyStrings = (arr) => [...arr].filter(Boolean).join(' ');

export const verifyChildrenActiveCards = (item, step, nested) => {
  const { children = [], [FIELD_KEYS.Overview]: Overview = [] } = item;
  if (!children.length && !nested) return false;

  if (children.length) {
    children.forEach(cItem => verifyChildrenActiveCards(cItem, step, true))
  }

  if (!Overview.length) return false;

  const { value: reviewers } = Overview.find(({ displayController }) => displayController === DISPLAY_CONTROLLERS.StepContent);

  return getPendingReviewers(reviewers, step).length;
};

export const composeInitGateIfNeed = (openRequest, gates = []) => {
  const authUser = AuthService.getUser();
  const lastGate = [...gates.sort()].pop();

  return openRequest.status === STATUS_CLOSED && openRequest.gate != lastGate
    ? [openRequest, { userId: authUser.UserId, gate: openRequest.gate + 1, status: STATUS_OPEN, createdAt: new Date(), updatedAt: new Date() }]
    : [openRequest]
};

export const prepareDueDateDocs = (docs = [], docType = IDOC_TYPE) => docs.reduce((nextDocs, doc) => {
  const {
    // [`${docType}OpenGate`]: OpenGate,
    [`${docType}OpenGateRequests`]: OpenGateRequests = [],
    [`${docType}DueDatesLimits`]: DueDatesLimits = {}
  } = doc || {};
  const currentGate = OpenGateRequests.find(({ status }) => status === STATUS_OPEN);
  const nextDoc = !currentGate || !Object.keys(DueDatesLimits).length
    ? doc
    : {
      ...doc,
      countDueDateGate: USER_TYPES.reduce(
        (count, userType) => isNaN(Number(DueDatesLimits?.[userType]?.[currentGate.gate]))
          ? count
          : count + Number(DueDatesLimits?.[userType]?.[currentGate.gate])
        , 0),
      startDate: currentGate.createdAt
    }
  ;

  return [...nextDocs, nextDoc];
}, []);

export const getVarsFromString = (s) => {
  if (typeof s !== 'string') return []
  const reg = /{{([^}]+)}}/
  const vars = s.match(new RegExp(reg, 'g')) || []
  const setKeyField = (output, key) => {
    const [_, match] = key.match(reg) || [] // eslint-disable-line
    return !match
      ? output
      : [
        ...output,
        {
          key,
          field: match
        }
      ]
  }
  return vars.reduce(setKeyField, [])
};

export const deepMergeArrays = (arr1, arr2, arrayMergeFields) => {
  const arrResult = [].concat(arr1);
  const source = Array.isArray(arr2) ? arr2 : [arr2];
  if (arrayMergeFields.length === 0) {
    return arrResult.concat(source);
  }
  source.forEach((sourceObj) => {
    const resultObjIdx = arrResult.findIndex((obj) => arrayMergeFields.some((field) => obj[field] && obj[field] === sourceObj[field]));
    console.log('[deepMergeArrays] resultObjIdx: ', resultObjIdx);

    if (resultObjIdx > -1) {
      arrResult[resultObjIdx] = deepMerge(arrResult[resultObjIdx], sourceObj, arrayMergeFields);
    } else {
      arrResult.push(sourceObj);
    }
  });

  return arrResult;
};

export const deepMerge = (obj1, obj2, arrayMergeFields = []) => {
  if (obj1 === undefined) {
    return obj2;
  }
  if (obj2 === undefined) {
    return obj1;
  }

  if (typeof obj2 !== 'object') {
    return obj2;
  }

  if (typeof obj1 !== 'object') {
    obj1 = {};
  }

  return Object.keys(obj2).reduce((result, key) => {
    if (obj1[key] === undefined) {
      return { ...result, [key]: obj2[key] };
    }

    if (Array.isArray(obj1[key])) {
      return { ...result, [key]: deepMergeArrays(obj1[key], obj2[key], arrayMergeFields) };
    }

    return {
      ...result,
      [key]: deepMerge(obj1[key], obj2[key], arrayMergeFields),
    };
  }, obj1);
};

export const excludeFirstElement = (_, index) => !!index;

export const prepareDevExtremeData = (chartSeries, categories, argumentField = 'date') => {
  if (!chartSeries.length) return { series: [], dataSource: [] };

  const composeArgumentField = (value, groupKey) => ({
    date: new Date(
      ...(
        categories
          ? categories[value].split('-').map((v, i) => i === 1 ? Number(v) - 1 : v)
          : [groupKey, value, '01']
      )

    ).toLocaleString('US', { year: 'numeric', month: 'short' })
  }[argumentField] || categories[value])

  const keyWithBiggetsData = [...chartSeries].sort(
    ({ data: dataA }, { data: dataB }) => dataA.length > dataB.length ? -1 : 1
  ).shift().name;
  const groupKeys = chartSeries.map(({ name }) => name);
  const groupData = chartSeries.reduce((nextGroupData, { name, data }) => (({
    ...nextGroupData,
    [name]: data
  })), {});

  const series = chartSeries.map(seriesItem => ({
    name: String(seriesItem.name),
    valueField: String(seriesItem.name)
  }));

  // console.log('keyWithBiggetsData: ', keyWithBiggetsData)
  // console.log('groupData: ', groupData)


  const dataSource = groupData[keyWithBiggetsData].map((_, index) => ({
    ...groupKeys.reduce((nextDataSource, groupKey) => ({
      ...nextDataSource,
      [argumentField]: nextDataSource[groupKey] ?? composeArgumentField(index, groupKey),
      [groupKey]: groupData[groupKey][index]
    }), {})
  }));

  return { series, dataSource };
};

export const prepareResource = resources => {
  const authUser = AuthService.getUser();
  const isSystemAdmin = isSystemUser(authUser);

  return resources.filter(({ Path }) =>
    !isSystemAdmin
     ? ![...EXCLUDE_DEFAULT_RESOURCES, ...EXCLUDE_ADMIN_RESOURCES].includes(Path)
     : !EXCLUDE_DEFAULT_RESOURCES.includes(Path)
  );
};

export const prepareTitle = ({ Label = '', Params = {},  config }) => {
  const variables = getVarsFromString(Label);
  const nextLabel = variables.reduce((result, { key, field }) => result.replace(key, getByDot(config, Params[field])), Label);

  return nextLabel;
};

export const formatTooltipValues = (unitOfMeasure = UOM_FORMATS.CURRENCY) => (value) => {
  const nextVal = Math.abs(Number(value.value)) > 1000 ? Number(value.value)/1000 : value.value;

  return ({
    text: `${uomFormat(unitOfMeasure, nextVal)}${Math.abs(Number(value.value)) > 1000  ? 'K' : ''}`
  });
};

export const preparePageProps = (systemFields) => ({
  Scenarios: [
    ...(systemFields?.systemFields?.Fields?.Scenarios || [])
      .filter((objVal) => (systemFields?.clientSystemFields?.Fields?.Scenarios || []).every(value => {

        return !value[Object.entries(objVal).shift().shift()]
      })),
    ...systemFields?.clientSystemFields?.Fields?.Scenarios || []
  ]
});

export const composeScenarioField = (field, scenario = BASE_SCENARIO, type) => {
  const { compose = () => field } = [
    {
      condition: scenario !== BASE_SCENARIO,
      compose: () => !type ? `${field}${scenario}` : `${field.split(type).shift()}${scenario}${type}`
    }
  ].find(({ condition }) => condition) || {};

  return compose();
}

export const composeLogDataByType = (data = {}, type = TYPE_PREV_VALUE) => {
  const getValues = (result, field) => {
    // console.log('[field]: ', field)
    // console.log('result[field]: ', result[field])
    // console.log('result: ', result)
    // console.log('type: ', type)
    // console.log('field === type: ', field === type)

    if (field === type) {

      return result[field]
    }

    // console.log('Reflect.has(result[field], type): ', Reflect.has(result[field], type))
    if (Reflect.has(result[field], type)) {
      return { ...result, [field]: result[field][type] }
    }

    const nextValue = Array.isArray(result[field])
      ? result[field].map(objField => {
          return Object.keys(objField).includes(type)
            ? objField[type]
            : Object.keys(objField).filter(key => key === type).reduce(getValues, objField)
      })
      : Object.keys(result[field]).reduce(getValues, result[field])
    ;

    return { ...result, [field]: nextValue };
  }

  return Object.keys(data).reduce(getValues, data)
}

export const getMapDocIcons = (theme) => ({
  [GDOC_TYPE]: {
    Icon: GdocIcon,
    backgroundColor: theme.palette.blue.mid,
    color: theme.palette.grey.lightLight,
    viewBox: '0 0 19 18'
  },
  [BDOC_TYPE]: {
    Icon: BdocIcon,
    backgroundColor: theme.palette.green.light,
    color: theme.palette.grey.lightLight,
  },
  [SDOC_TYPE]: {
    Icon: SdocIcon,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grey.lightLight,
  },
  [IDOC_TYPE]: {
    Icon: IdocIcon,
    backgroundColor: theme.palette.orange.mid,
    color: theme.palette.grey.lightLight,
  },
  [INDOC_TYPE]: {
    Icon: IndocIcon,
    backgroundColor: theme.palette.yellow.dark,
    color: theme.palette.grey.lightLight,
  },
  [OUTDOC_TYPE]: {
    Icon: OutdocIcon,
    backgroundColor: theme.palette.pink.dark,
    color: theme.palette.grey.lightLight,
  },
  [PDOC_TYPE]: {
    Icon: PdocIcon,
    backgroundColor: theme.palette.green.dark,
    color: theme.palette.grey.lightLight,
  },
  [PRDOC_TYPE]: {
    Icon: PrdocIcon,
    backgroundColor: theme.palette.violet.mid,
    color: theme.palette.grey.lightLight,
  },
  [QDOC_TYPE]: {
    Icon: QdocIcon,
    backgroundColor: theme.palette.grey.dark,
    color: theme.palette.grey.lightLight,
  },
  [RDOC_TYPE]: {
    Icon: RdocIcon,
    backgroundColor: theme.palette.green.mid,
    color: theme.palette.grey.lightLight,
  },
  [MDOC_TYPE]: {
    Icon: MdocIcon,
    backgroundColor: theme.palette.green.midLight,
    color: theme.palette.grey.lightLight,
  },
  [KDOC_TYPE]: {
    Icon: KdocIcon,
    backgroundColor: theme.palette.blue.midDark,
    color: theme.palette.grey.lightLight,
  },
  [TDOC_TYPE]: {
    Icon: TdocIcon,
    backgroundColor: theme.palette.pink.light,
    color: theme.palette.grey.lightLight,
  },
  [QSDOC_TYPE]: {
    Icon: QsdocIcon,
    backgroundColor: theme.palette.brown.mid,
    color: theme.palette.grey.lightLight,
  }
})

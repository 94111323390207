import React, { useEffect, useState, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Badge, Button, useTheme, Divider, Box, SvgIcon } from '@mui/material';
import { NotificationsActiveTwoTone as NotificationsActiveTwoToneIcon } from '@mui/icons-material';

import { ReactComponent as BellIcon } from '../../../assets/images/icons/bell.svg'
import { AuthService, Request } from './../../../services';
import { formatDate } from './../../../utils/date';
import { DEFAULT_DOC_VIEW_BOX } from "../../../constants";

const HeaderDots = () => {
  const history = useHistory();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const handleClick = useCallback((e) => {
    setAnchorEl(e.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleNoteClick = useCallback(({ event, NotificationId, xDoc, Viewed }) => {
    event.preventDefault();
    if (!Viewed) {
      Request.updateNotification(NotificationId, { Viewed: true }).then(() => {
        history.push({
          pathname: '/documenttree',
          notification: { NotificationId, xDoc }
        });
      });

      setNotifications(notifications.filter(({ NotificationId: notId }) => NotificationId !== notId ))
    } else {
      history.push({
        pathname: '/',
        notification: { NotificationId, xDoc }
      });
    }
    handleClose();
  }, [notifications]);

  const handleSeeAll = useCallback(() => {
    history.push('/notifications');
    handleClose();
  }, [])

  const open = Boolean(anchorEl);

  useEffect(() => {
    const authUser = AuthService.getUser();
    const getNotifications = () => Request.getNotifications({ UserId: authUser.UserId })
      .then((notifications) => {
        setNotifications(notifications.filter(({ Viewed }) => !Viewed))
      });
    getNotifications();
    const interval = setInterval(getNotifications, 30000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <Button
        variant="text"
        onClick={handleClick}
        className=""
        sx={{
          padding: '0px !important'
        }}
      >
        <SvgIcon
          // htmlColor={mapDocIcons[docType]?.color}
          component={BellIcon}
        />
      </Button>
      <div className="d-flex align-items-center popover-header-wrapper">
        <span>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            classes={{}}
            style={{ maxHeight: '500px', marginTop: '5px' }}
          >
            <div className={'notes-popup'}>
              <div className="text-center">
                <div className="px-1 py-2">
                  <p
                    className="mb-0"
                    style={{
                      color: theme.palette.primary.main
                    }}
                  >
                    You have <b>{notifications.length}</b> new message(s)
                  </p>
                </div>
                <Divider className='mb-2' />
              </div>
              <div>
                <PerfectScrollbar>
                  <div>
                    {
                      notifications.map(({ NotificationId, xDoc, Message, Viewed, CreatedAt }) => (
                        <React.Fragment key={NotificationId}>
                          <div className="d-flex justify-content-between">
                            <div className="p-3">
                              <div
                                className="font-weight-bold"
                              >
                                <a
                                  href="#/"
                                  onClick={event => handleNoteClick({ event, NotificationId, xDoc, Viewed })}
                                >
                                  { formatDate(CreatedAt) }
                                </a>
                              </div>
                              <span className="d-flex align-items-center">
                                <a href="#/" onClick={event => handleNoteClick({ event, NotificationId, xDoc, Viewed })}>
                                  { Message }: "{ xDoc.name }"
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="divider my-2" />
                        </React.Fragment>
                      ))
                    }
                  </div>
                </PerfectScrollbar>
              </div>
              <div className="text-center py-3">
                <Button
                  className="btn-gradient bbg-primary px-4"
                  variant="contained"
                  onClick={handleSeeAll}
                >
                  <span className="btn-wrapper--label">See more</span>
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                  </span>
                </Button>
              </div>
            </div>
          </Popover>
        </span>
      </div>
    </Box>
  );
};

export default HeaderDots;

const classes = {
  right: 'StyledFieldsBlock-right'
};

const createStyles = ({ theme }) => ({
  padding: '0 10px !important',
  // border: `10px solid ${theme.palette.headerBlockBackground}`,
  [`& .${classes.right}`]: {
    minWidth: '500px'
  }
});

export default { createStyles, classes };
export { createStyles, classes };

export const INNOMETRIX_CLIENT_DOMAIN_PROD = 'https://console.mmpathfinder.com/';
export const INNOMETRIX_CLIENT_DOMAIN_DEMO = 'https://demo.innometrix.net/';
export const INNOMETRIX_CLIENT_DOMAIN_DEV = 'https://dev.innometrix.net/';
export const INNOMETRIX_CLIENT_DOMAIN_LOCAL = 'http://localhost:3001/';

export const INNOMETRIX_API_DOMAIN_PROD = 'https://web-api.prod.innometrix.net/';
export const INNOMETRIX_API_DOMAIN_DEMO = 'https://web-api.demo.innometrix.net/';
export const INNOMETRIX_API_DOMAIN_DEV = 'https://web-api.dev.innometrix.net/';
export const INNOMETRIX_API_DOMAIN_LOCAL = 'http://localhost:3000/';

export const MENU_BLOCKS = {
  topSideBar: 'topSideBar',
  profileSideBar: 'profileSideBar',
};

export const MENU_ITEM_TYPES = {
  link: 'link',
  subMenu: 'subMenu',
  externalLink: 'externalLink'
};

export const TAB_NAME_EXPLANATIONS = 'RichTextFields';
export const FIELD_TITLES = {
  Overview: 'Overview',
  Fields: 'Document Info',
  StandardDocFields: 'Document Details',
  SystemClientFields: 'Company Details',
  DocClientFields: 'DocClient Fields',
  RichTextFields: 'Explanations',
  ClientFields: 'Additional Document Details',
  ClientDimFields: 'Dimensions',
  ClientIntegrationFields: 'Integration',
  ClientTags: 'Search Key Words',
  TimeFields: 'Additional Document Details',
  Aggregations: 'Values',
  Calculations: 'Financial Results',
  Resources: 'Resources',
  Approvers: 'Approvers',
  Status: 'Status',
  StartDate: 'Start Date',
  CompletionDate: 'Completion Date',
  Notes: 'Notes',
  Audit: 'Audit',
  GantChart: 'GantChart',
  ConsumedValue: 'Consumed Value',
};

export const FIELD_KEYS = Object.keys(FIELD_TITLES).reduce((result, key) => ({ ...result, [key]: key }), {});

export const CONFIG_TYPES = {
  xDocs: 'xDocs',
  ControlDocs: 'ControlDocs'
};

export const DEFAULT_CONFIG_FIELDS = {
  FieldName: 'FieldName',
  Label: 'Label',
  ToolTip: 'ToolTip',
  DisplayController: 'DisplayController',
  DisplayPosition: 'DisplayPosition',
  Required: 'Required',
  NotDisabledFields: 'NotDisabledFields'
};

export const CONFIG_FIELDS = {
  ...DEFAULT_CONFIG_FIELDS,
  TextHeader: 'TextHeader',
  TextHelp: 'TextHelp'
};

export const DROPDOWN_FIELDS = {
  Label: 'Label',
  Value: 'Value'
};

export const GDOC_TYPE = 'gDoc';
export const BDOC_TYPE = 'bDoc';
export const SDOC_TYPE = 'sDoc';
export const IDOC_TYPE = 'iDoc';
export const INDOC_TYPE = 'inDoc';
export const OUTDOC_TYPE = 'outDoc';
export const PDOC_TYPE = 'pDoc';
export const RDOC_TYPE = 'rDoc';
export const MDOC_TYPE = 'mDoc';
export const KDOC_TYPE = 'kDoc';
export const PRDOC_TYPE = 'prDoc';
export const QDOC_TYPE = 'qDoc';
export const TDOC_TYPE = 'tDoc';
export const QSDOC_TYPE = 'qsDoc';

export const CONTROL_DOC_CDOC = 'cDoc';
export const CONTROL_DOC_DDOC = 'dDoc';

export const DOC_LEVELS = [
  [GDOC_TYPE],
  [BDOC_TYPE, SDOC_TYPE],
  [IDOC_TYPE],
  [INDOC_TYPE, OUTDOC_TYPE, PDOC_TYPE, PRDOC_TYPE, QDOC_TYPE],
  [RDOC_TYPE, MDOC_TYPE, KDOC_TYPE, TDOC_TYPE, QSDOC_TYPE]
];

export const DOC_HIERARCHY = {
  [GDOC_TYPE]: [BDOC_TYPE, SDOC_TYPE],
  [SDOC_TYPE]: [IDOC_TYPE],
  [IDOC_TYPE]: [INDOC_TYPE, OUTDOC_TYPE, PDOC_TYPE, PRDOC_TYPE, QDOC_TYPE],
  [INDOC_TYPE]: [RDOC_TYPE],
  [OUTDOC_TYPE]: [MDOC_TYPE],
  [PDOC_TYPE]: [KDOC_TYPE],
  [PRDOC_TYPE]: [TDOC_TYPE],
  [QDOC_TYPE]: [QSDOC_TYPE]
};

export const CONTOL_DOC_RELATIONS = {
  RDOC_TYPE: [CONTROL_DOC_CDOC]
};

export const ACTIONS = {
  AddKey: 'AddKey',
  EditKey: 'EditKey',
  RemoveKey: 'RemoveKey',
  AddKeyVal: 'AddKeyVal',
  EditKeyVal: 'EditKeyVal',
  RemoveKeyVal: 'RemoveKeyVal',
  Add: 'Add',
  Edit: 'Edit',
  Remove: 'Remove',
  EditFieldName: 'EditFieldName'
};

export const FIELD_TYPES = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom'
};

export const OBJECT_FIELD_TYPES = {
  OBJECT: 'Object',
  VALUES: 'Values'
};

export const DISPLAY_CONTROLLERS = {
  Textbox: 'Textbox',
  NumericBox: 'NumericBox',
  TextboxDisabled: 'TextboxDisabled',
  Dropdown: 'Dropdown',
  Relation: 'Relation',
  Date: 'Date',
  Grid: 'Grid',
  GridScenario: 'GridScenario',
  // AutoCalc: 'AutoCalc',
  RecordSet: 'RecordSet',
  GridGroup: 'GridGroup',
  MonthlyGrid: 'MonthlyGrid',
  GroupedMonthlyGrid: 'GroupedMonthlyGrid',
  GroupedNumber: 'GroupedNumber',
  MultiLineTextbox: 'MultiLineTextbox',
  MultiSelect: 'MultiSelect',
  Password: 'Password',
  PasswordChange: 'PasswordChange',
  PasswordTextbox: 'PasswordTextbox',
  Checkbox: 'Checkbox',
  Steps: 'Steps',
  StepContent: 'StepContent',
  Approvers: 'Approvers',
  ListMessages: 'ListMessages',
  CheckBoxesList: 'CheckBoxesList',
  DocStatus: 'DocStatus',
  AbandonedReason: 'AbandonedReason',
  Help: 'Help',
  Wysiwyg: 'Wysiwyg',
  ApprovalScheduler: 'ApprovalScheduler',
  Notes: 'Notes',
  Logs: 'Logs',
  Tags: 'Tags',
  GantChart: 'GantChart',
  MfaType: 'MfaType',
};

export const INPUT_PROPS = {
  rDocNumberOfYears: {
    min: 0
  },
  mDocNumberOfYears: {
    min: 0
  },
  cDocNumberOfYears: {
    min: 0
  },
  bDocNumberOfYears: {
    min: 0
  },
  rDocTotal: {
    disabled: true
  },
  mDocTotal: {
    disabled: true
  },
  cDocTotal: {
    disabled: true
  },
  bDocTotal: {
    disabled: true
  },
  bDocValue: {
    disabled: true
  }
};

export const YEAR_MONTHS_COUNT = 12;

export const MONTH_ABBR_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const DOC_TYPES = {
  xDocs: 'xDocs',
  ControlDocs: 'ControlDocs'
};

export const UOM_FORMATS = {
  CURRENCY: 'Currency',
  TIME: 'Time',
  PERCENT: 'Percent',
  DECIMAL: 'decimal',
};

export const TITLES = {
  TOTAL: 'Total',
  YEAR: 'Year',
  PAYBACK: 'Payback',
  ASSIGNTO: 'Assign To',
  IDEALIBRARY: 'Idea Library',
  CREATEDOCUMENT: 'Create New Document',
  CUSTOMFIELDS: 'Custom Fields',
  DEBUGCONTROLS: 'Debug Controls',
  WANTTODELETE: 'Are you sure you want to cascade delete this document?',
  WANTTODELETECTRDOC: 'Are you sure you want to delete this document?',
  WANTTODELETEROLE: 'Are you sure you want to delete this role?',
  WANTTODELETEGROUP: 'Are you sure you want to delete this group?',
  WANTTODELETECLIENT: 'Are you sure you want to delete this client?',
  WANTTODELETEPOLICY: 'Are you sure you want to delete this policy?',
  AREYOUSURE: 'Are you sure?',
  CANNOTUNDO: 'You cannot undo this operation.',
  DELETE: 'DELETE',
  POLICIES: 'POLICIES',
  ROLES: 'ROLES',
  RESOURCES: 'RESOURCES',
  TEAM: 'TEAM',
  USERS: 'USERS',
  APPROVE: 'Approve',
  DECLINE: 'Decline',
  YES: 'Yes',
  NO: 'No',
  CONTINUE: 'Continue',
  RESET_GATE: 'Cancel Gate',
  NOT_APPROVED: 'Not Approved',
  APPROVED: 'Approved',
  IN_PROGRESS: 'In Progress',
  NOT_STARTED: 'Not Started',
  RELOGIN_MESSAGE: 'Your session is about to expire and you’ll be automatically logged out. Press continue to extend your session.',
  NO_ACCESS_DOCUMENT: 'No Access Document',
  CHANGE_PASSWORD: 'Change Password',
  RESSET_PASSWORD: 'Cancel Password',
  RECALCULATING_PROCESS: 'Start recalculating process?',
  REQUEST_ACCESS: 'Request access',
  REASON_REQUEST: 'Reason Request',
  CANCEL: 'Cancel',
  SUBMIT: 'Submit',
  INITIATE_GATE: 'Initiate Gate Approval',
};

export const ERRORS = {
  REQUIRED: 'This Field is required!',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  NOT_AUTHENTICATED: 'Authentication failed'
};

export const THEME_SIZES = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};

export const INPUT_TYPES = ['standard', 'outlined', 'filled'];

export const PASSWORD_TYPES = {
  Password: 'Password',
  password: 'password',
  confirdPassword: 'confirdPassword'
};

export const ICONS = {
  arrow: 'arrow',
  square: 'square'
};

export const PREFIX_ALL='all';
export const PATH_DELIMITER=':';

export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_PUT = 'put';
export const METHOD_DELETE = 'delete';

const PERMISSION_READ = 'Read';
const PERMISSION_CREATE = 'Create';
const PERMISSION_MODIFY = 'Modify';
const PERMISSION_DELETE = 'Delete';

export const PERMISSIONS = [PERMISSION_READ, PERMISSION_CREATE, PERMISSION_MODIFY, PERMISSION_DELETE];
export const MAP_METHODS = {
  [PERMISSION_READ]: [METHOD_GET],
  [PERMISSION_CREATE]: [METHOD_POST],
  [PERMISSION_MODIFY]: [METHOD_PUT],
  [PERMISSION_DELETE]: [METHOD_DELETE]
};

export const ENDPOINTS = {
  USER_SETTINGS: '/client/user/config/',
  CLIENTS: '/pf-admin/clients/',
  USERS: '/access-manager/users/',
  USER_GROUPS: '/access-manager/user-groups/',
  ROLES: '/access-manager/roles/',
  POLICIES: '/access-manager/policies/',
  CONTROL_DOCS: '/control-docs/',
  DOCS: '/documents/',
  CONFIGURATION: '/client/configuration/docs/',
};

export const ROLES_NAMES = {
  iUser: 'iUser Role',
  iTeamLeader: 'iTeamLeader Role',
  iLeader: 'iLeader Role'
};

export const COLOR_STATUS_APPROACHING = 'Approaching';
export const COLOR_STATUS_PAST_DEAD_LINE = 'PastDeadLine';

export const STATUS_OPEN = 'Open';
export const STATUS_CLOSED = 'Closed';
export const STATUS_APPROVED = 'Approved';
export const STATUS_PENDING = 'Pending';
export const STATUS_DECLINED = 'Declined';
export const STATUS_NOT_APPROVED = 'Not Approved';
export const STATUS_ACTIVE = 'Active';
export const STATUS_DEFERRED = 'Deferred';
export const STATUS_ABANDONED = 'Abandoned';
export const STATUS_COMPLETED = 'Completed';

export const FORBIDDEN_STATUSES = [
  STATUS_NOT_APPROVED,
  STATUS_DEFERRED,
  STATUS_ABANDONED
];

export const TYPE_APPROVVERS = 'Approvers';
export const TYPE_SPONSORS = 'Sponsors';
export const TYPE_LEADS = 'Leads';
export const USER_TYPES = [TYPE_APPROVVERS, TYPE_SPONSORS, TYPE_LEADS];
export const USER_TYPES_TITLES = {
  [TYPE_APPROVVERS]: TYPE_APPROVVERS,
  [TYPE_SPONSORS]: 'Sponsor',
  [TYPE_LEADS]: 'Leaders'
};
export const MESSAGE_TYPE_APPROVE = 'ApproveDocument';

export const ACTION_ALLOW = 'Allow';
export const ACTION_DENY = 'Deny';

export const USER_TYPE_USER = 'User';
export const USER_TYPE_OWNER = 'Owner';
export const USER_TYPE_SYSTEM_USER = 'SystemUser';
export const USER_TYPE_ADMIN = 'SysAdmin';

export const AUTH_STATUS_NEWPASSWORD = 'NewPasswordRequired';
export const AUTH_STATUS_MFA = 'MfaRequired';
export const FIELD_FUNCTIONALAREA = 'FunctionalArea';
export const FIELD_MANAGMENTAREA = 'ManagementArea';
export const FIELD_UNIT_OF_MEASURES = 'UnitOfMeasures';
export const FIELD_DISTRIBUTIONCURVES = 'DistributionCurves';
export const FIELD_CONSTANTS = 'Constants';
export const FIELD_CLIENT_LIST = 'ClientList';
export const FIELD_USER_TEAMS = 'Teams';
export const FIELD_CONVERSION_MAPS = 'ConversionMaps';
export const FIELD_SCHEDULE_TEMPLATES = 'ScheduleTemplates';
export const FIELD_TAGS = 'Tags';
export const FIELD_IDOC_GATES = 'iDocGates';
export const FIELD_IDOC_SCALINGS = 'iDocScalings';
export const FIELD_CURRENCY = 'Currency';
export const FIELD_SCENARIOS = 'Scenarios';
export const FIELD_MESSAGES = 'Messages';

export const TAB_VALUES = 'VALUES';
export const SYSTEM_SECTION_FIELDS = 'Fields';
export const SYSTEM_SECTION_SYSTEMCLIENTFIELDS = 'SystemClientFields';

export const CLIENT_CONFIG_FIELDS = [
  FIELD_MANAGMENTAREA,
  FIELD_UNIT_OF_MEASURES,
  FIELD_CONVERSION_MAPS,
  FIELD_CONSTANTS,
  FIELD_CLIENT_LIST,
  FIELD_DISTRIBUTIONCURVES,
  FIELD_SCHEDULE_TEMPLATES,
  FIELD_TAGS,
  FIELD_IDOC_GATES,
  FIELD_IDOC_SCALINGS,
  FIELD_CURRENCY,
  FIELD_SCENARIOS,
  FIELD_MESSAGES
];

export const CLIENT_CONFIG_FIELDS_TITLES = {
  [FIELD_UNIT_OF_MEASURES]: 'Unit Of Measures',
  [FIELD_DISTRIBUTIONCURVES]: 'Distribution Curves',
  [FIELD_CLIENT_LIST]: 'Client List',
  [FIELD_CONVERSION_MAPS]: 'Conversion Maps',
  [FIELD_SCHEDULE_TEMPLATES]: 'Schedule Templates',
  [FIELD_IDOC_GATES]: 'Gates',
  [FIELD_IDOC_SCALINGS]: 'Scalings',
};

export const DISTRIBUTION_CURVES_FIELD_MONTHNUM = 'MonthNum';
export const DISTRIBUTION_CURVES_FIELD_MONTHNAME = 'MonthName';

export const SETTINGS_RULES = {
  Disallow: {
    [FIELD_CONSTANTS]: {
      methods: [METHOD_POST, METHOD_DELETE]
    },
    [FIELD_DISTRIBUTIONCURVES]: {
      fields: [
        DISTRIBUTION_CURVES_FIELD_MONTHNUM,
        DISTRIBUTION_CURVES_FIELD_MONTHNAME
      ]
    }
  },
  Filter: {
    [FIELD_DISTRIBUTIONCURVES]: {
      fields: [
        DISTRIBUTION_CURVES_FIELD_MONTHNUM
      ]
    }
  },
  Validate: {
    [FIELD_DISTRIBUTIONCURVES]: (values) => {
      const { DistributionCurveName: errorField } =
        values.find(({ Periods }) => {
          const sum = Periods.reduce((result, { DistributionValue }) => (result + DistributionValue), 0);

          console.log('[Validate] sum: ', sum)
          return sum && Math.round(sum) !== 100;
        }) || {};

      return !!errorField ? [`Error: In DistributionCurve '${errorField}' values sum should be 100`] : [];
    }
  }
};

export const ALL_ENTITIES = '*';
export const SYSTEM_ENTITIES = 'System';

export const OBJECT_TYPE_OBJECT = 'OBJECT_TYPE_OBJECT';
export const OBJECT_TYPE_VALUE = 'OBJECT_TYPE_VALUE';

export const DEFAULT_FORMAT_DATE = 'MM/yyyy';

export const DROPDOWN_TYPES = {
  simple: 'simple',
  values: 'values',
  relationship: 'relationship'
};

export const TYPE_VALUE_TEXT = 'Text';
export const TYPE_VALUE_INTEGER = 'Integer';
export const TYPE_VALUE_DECIMAL = 'Decimal';
export const TYPES_VALUES = [TYPE_VALUE_TEXT, TYPE_VALUE_INTEGER, TYPE_VALUE_DECIMAL];
export const TYPE_FIELD_VALUE = 'typeValue';

export const ROLE_LEADERSHIP = 'Leadership';
export const ROLE_CLIENTADMIN = 'Client Admin Role';
export const ROLE_ILEADER = 'iLeader Role';
export const ROLE_ITEAMLEADER = 'iTeamLeader Role';
export const ROLE_SPONSOR = 'Sponsor Role';
export const ROLE_SME = 'SME Role';
export const ROLE_DATAENTRY = 'DataEntry Role';
export const ROLE_IUSER = 'iUser Role';

export const STEP_ACTION_INIT = 'initialized';
export const STEP_ACTION_APPROVE = 'approved';
export const STEP_ACTION_DECLINE = 'declined';
export const STEP_ACTION_RESET_GATE = 'reset gate';
export const STEP_ACTION_NOT_APPROVED = 'not approved';

export const PERCENT_ALL = 100;

export const ERROR_DOCUMENT_EDITED = 'Document being edited by "%s"';

export const CALCULATIONS_GROUP_ESTIMATED = 'Estimated';
export const CALCULATIONS_GROUP_EXPECTED = 'Expected';
export const CALCULATIONS_GROUP_ACTUALS = 'Actuals';
export const CALCULATIONS_GROUPS = [
  CALCULATIONS_GROUP_ESTIMATED,
  CALCULATIONS_GROUP_EXPECTED,
  CALCULATIONS_GROUP_ACTUALS
];

export const SECONDS_LEFT_TO_REFRESH_TOKEN = 60; // 1 minute
export const SECONDS_SESSION_TIMEOUT = 1440 * SECONDS_LEFT_TO_REFRESH_TOKEN; // 1 day

export const DUEDATE_MORE_10DAYS = 11;
export const DUEDATE_WITHIN_10DAYS = 10;
export const DUEDATE_WITHIN_7DAYS = 7;
export const DUEDATE_WITHIN_3DAYS = 3;
export const DUEDATE_TODAY = 0;
export const DUEDATE_TYPES = [DUEDATE_MORE_10DAYS, DUEDATE_WITHIN_10DAYS, DUEDATE_WITHIN_7DAYS, DUEDATE_WITHIN_3DAYS, DUEDATE_TODAY];
export const DUEDATE_TYPES_TITLES = {
  [DUEDATE_MORE_10DAYS]: 'More than 10 days',
  [DUEDATE_WITHIN_10DAYS]: 'Within 10 days',
  [DUEDATE_WITHIN_7DAYS]: 'Within 7 days',
  [DUEDATE_WITHIN_3DAYS]: 'Within 3 days',
  [DUEDATE_TODAY]: 'Today',
};
export const REPORT_APPROVALS_DUE = 'ReportApprovalsDue';
export const REPORT_APPROVALS_LATE = 'ReportApprovalsLate';

export const DISPLAY_CONTROLLER_DROPDOWN_SOURCE = [
  {
    sourceName: 'UserList',
    options: {
      SourceType: 'Collection',
      SourceAtributes: {
        Collection: 'Users',
        LabelFieldName: 'FirstName',
        ValueFieldName: 'UserId',
        Version: '1.0.0'
      }
    }
  }
];

export const SNAPSHOTS_TYPE_USERS_OVERTIME = 'USERS_OVERTIME';
export const SNAPSHOTS_TYPE_USERS_NOT_ACTIVE = 'USERS_OVERTIME_NOT_ACTIVE';

export const COMPONENT_TYPE_CHART = 'chart';
export const COMPONENT_TYPE_PIE = 'pie';
export const COMPONENT_TYPE_GANTT = 'gantt';
export const COMPONENT_TYPE_POLAR = 'polar';
export const COMPONENT_TYPE_FUNNEL = 'funnel';
export const DISTRIBUTION_CURVE_MANUAL = 'Manual';

export const EXCLUDE_DEFAULT_RESOURCES = [
  '/sign-in',
  '/recover-password',
  '/menus',
  '/notifications',
  '/snapshots',
  '/grid-view',
  '/grid-view/config',
  '/dashboard',
  '/dashboard/config',
  '/reports',
  '/reports/config',
  '/documents-config',
  '/profile/user/config',
  '/profile',
];

export const EXCLUDE_ADMIN_RESOURCES = [
  '/pf-admin',
  '/pf-admin/clients',
  '/pf-admin/clients/{id}',
  '/pf-admin/user',
  '/pf-admin/user/config',
  '/pf-admin/settings',
  '/pf-admin/settings/{version}',
  '/pf-admin/xdoc-config',
  '/pf-admin/xdoc-config/{version}'
];

export const MFA_TYPE_PHONE = 'Phone';
export const MFA_TYPE_EMAIL = 'Email';
export const MFA_TYPES = [
  MFA_TYPE_PHONE,
  MFA_TYPE_EMAIL
];

export const CURRENCY_USD = 'USD';
export const CURRENCY_GBP = 'GBP';
export const CURRENCY_EUR = 'EUR';

export const BASE_SCENARIO = 'base';

export const TYPE_PREV_VALUE = 'prevValue';
export const TYPE_NEW_VALUE = 'newValue';

export const DEFAULT_DOC_VIEW_BOX = '0 0 18 18';

export const AGG_METHOD_AVERAGE = 'Average';
export const AGG_METHOD_SUMMARIZED = 'Summarized';
export const AGG_METHOD_LAST_PERIOD = 'Last period';

import React from 'react';
import { Grid, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import { MFA_TYPES, MFA_TYPE_EMAIL, MFA_TYPE_PHONE } from '../../../constants';


const mapTitle = {
  [MFA_TYPE_PHONE]: 'Text'
}
const MfaType = (props) => {
  const { selectedFieldItems, access } = props;
  const {
    value = MFA_TYPE_EMAIL,
    handleChange
  } = props;

  return (
    <RadioGroup
      row
      value={value}
      onChange={handleChange}
    >
      {
        MFA_TYPES.map((type) => (
          <FormControlLabel key={type} label={mapTitle[type] || type} value={type} control={<Radio />}  />
        ))
      }
    </RadioGroup>
  );
};

export default MfaType;
